import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import TitleCard from "../../AddSafebox/TitleCard";
import { Ring } from "@uiball/loaders";
import {
  getUserInfo1,
  getAccessRequest,
  updateAccessRequest,
} from "../../../../utils/services/user.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, ButtonToolbar } from "@/shared/components/Button";
import "../table.css";

const OtcAccessRequestTable = () => {
  const itemsPerPage = 20;
  const [StoreData, setAccessRequestData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [TFA, setTFA] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;
  const [tfaUrl, setTfaUrl] = useState(false);
  const [tfaEmail, setTfaEmail] = useState("");
  const [showTfaModal, setShowTfaModal] = useState(false);
  const [storeStatus, setstoreStatus] = useState("");

  const [open, setOpen] = useState(false);
  const [approveOpen, setapproveOpen] = useState(false);
  const [rejectOpen, setrejectOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedoptionorder, setoptionSelectorder] = useState("all");

  const filteredStoreData =
    selectedoptionorder === "all"
      ? StoreData
      : StoreData.filter((store) => store.status === selectedoptionorder);

  const handleDataFromChild = (data) => {
    sessionStorage.setItem("data", data);
    if (data === true) {
      setShowTfaModal(false);
      if (storeStatus === "approved") {
        showApprovalModal();
      } else if (storeStatus === "rejected") {
        showRejectionModal();
      } else {
        showConfirmationModal();
      }
    } else {
      setTfaUrl(true);
      setTfaEmail(userData.data.user.email);
    }
  };

  useEffect(() => {
    const storedData = sessionStorage.getItem("data");
    if (storedData === "true") {
      handleDataFromChild(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        email: usermail,
      };

      try {
        const response = await getUserInfo1(requestData);

        if (response.status === 200) {
          setTFA(response.data.User.TFA);
        } else {
          console.error("getUserInfo1 failed:", response);
        }
      } catch (error) {
        console.error("Error getUserInfo1:", error);
      }
    };

    fetchData();
  }, [usermail, TFA]);

  function getStatusColorClass(status) {
    switch (status) {
      case "pending":
        return "text-yellow-500";
      case "approved":
        return "text-green-500";
      case "rejected":
        return "text-red-500";
      case "deactivated":
        return "text-gray-500";
      default:
        return "";
    }
  }

  const getStatusBadge = (status) => {
    switch (status) {
      case "pending":
        return (
          <div className="mt-1 bg-yellow-100 text-yellow-800 text-sm font-medium mr-2 px-2 py-2 rounded">
            Pending
          </div>
        );
      case "approved":
        return (
          <div className="mt-1 bg-green-100 text-green-800 text-sm font-medium mr-2 px-1 py-2 rounded">
            Approved
          </div>
        );
      case "rejected":
        return (
          <div className="mt-1 bg-red-100 text-red-800 text-sm font-medium mr-2 px-1 py-2 rounded ">
            Rejected
          </div>
        );
      case "deactivated":
        return (
          <div className="mt-1 bg-gray-100 text-gray-800 text-sm font-medium mr-2 px-1 py-2 rounded">
            Deactivated
          </div>
        );
      default:
        return <div className="badge">Unknown</div>;
    }
  };

  const dispatch = useDispatch();

  const otcRequests = async (selectedOption) => {
    try {
      setIsLoading(true);
      const payload = {
        limit: 100000,
        page_number: 1,
        status: selectedOption || "all",
      };
      const response = await getAccessRequest(payload);
      if (response.data.data) {
        setAccessRequestData(response.data.data);

        const totalUsers = response.data.count;

        const pages = Math.ceil(totalUsers / itemsPerPage);

        setPageCount(pages);
        setIsLoading(false);
      } else {
        console.error("Not Found");
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error fetching store data:");
      console.error("Error fetching store data:", error);
    }
  };
  useEffect(() => {
    otcRequests();
  }, [currentPage]);

  const showConfirmationModal = () => {
    setOpen(true);
  };

  const showApprovalModal = () => {
    setapproveOpen(true);
  };

  const showRejectionModal = () => {
    setrejectOpen(true);
  };

  const handleStatusChange = async (event, storeId) => {
    setIsLoading(true);
    const newStatus = event.target.value;
    const payload = {
      status: newStatus,
      request_id: storeId,
    };
    try {
      const res = await updateAccessRequest(payload);
      setIsLoading(false);
      await otcRequests();
      toast.success(res?.data?.Message);
    } catch (error) {
      console.error("An error occurred while updating status:", error);
      setIsLoading(false);
      toast.error(error?.response?.data.Message);
    }
  };

  const handleApiChange2 = async (event) => {
    const selectedOption = event.target.value;
    setoptionSelectorder(selectedOption);
    await otcRequests(selectedOption);
  };

  const customPaddingStyle = {
    padding: " 6px 4px",
  };
  const Ava = `/img/avat.png`;

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <TitleCard title="" topMargin="mt-2">
        <h3 className="mb-4">OTC Access Requests</h3>
        <div className="lg:flex lg:space-x-4  ">
          <div className="flex flex-col lg:w-1/4 mb-4 lg:mb-0">
            <select
              onChange={handleApiChange2}
              className=" rounded-sm mr-2"
              style={customPaddingStyle}
              id="filters"
            >
              <option selected={selectedoptionorder === "all"} value="all">
                Select Status
              </option>
              <option
                selected={selectedoptionorder === "approved"}
                value="approved"
              >
                Approved
              </option>
              <option
                selected={selectedoptionorder === "rejected"}
                value="rejected"
              >
                Rejected
              </option>
              <option
                selected={selectedoptionorder === "pending"}
                value="pending"
              >
                Pending
              </option>
            </select>
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <table className="tbody table w-full ">
            <thead>
              <tr>
                <th>Account Holder</th>
                <th>OTC Request id</th>
                <th>Name</th>
                <th>Referral Code</th>
                <th>Email</th>
                <th>Current Status</th>
                <th>Update Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredStoreData.length > 0 ? (
                filteredStoreData.map((store) => (
                  <tr key={store._id}>
                    <td>
                      <div className="flex flex-row pr-2  items-center gap-3 ">
                        <img
                          className="flex h-[44px]  w-[44px] rounded-3xl"
                          src={store?.user_details[0]?.image || Ava}
                        />
                        <span>{store?.user_details[0]?.name}</span>
                      </div>
                    </td>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <div className="">{store?._id}</div>
                        </div>
                      </div>
                    </td>

                    <td>{store?.market_place[0]?.market_place_name}</td>
                    <td>{store?.referral_code || "- -"}</td>
                    <td>{store?.user_details[0]?.email}</td>

                    <td className="text-center">
                      {getStatusBadge(store.status)}
                    </td>

                    <td>
                      <div className="relative inline-block text-black">
                        <select
                          id={`statusDropdown-${store._id}`}
                          onChange={(e) => handleStatusChange(e, store._id)}
                          value={store.status}
                          className="appearance-none w-full bg-white text-stone-950 border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                        >
                          {store.status === "pending" && (
                            <option>pending</option>
                          )}
                          <option
                            value="approved"
                            className={getStatusColorClass("approved")}
                          >
                            Approve
                          </option>
                          <option
                            value="rejected"
                            className={getStatusColorClass("rejected")}
                          >
                            Reject
                          </option>
                        </select>
                      </div>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No Request Available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageCount > 1 && (
          <div className="flex items-center justify-center mt-4">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className="flex items-center justify-center px-1 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-2.5 h-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </button>
            {/* Pagination links */}
            {pageCount <= 3 ? (
              Array.from({ length: pageCount }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                  className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                    currentPage === index + 1
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  }`}
                >
                  {index + 1}
                </button>
              ))
            ) : (
              <>
                {currentPage > 1 && (
                  <>
                    <button
                      onClick={() => setCurrentPage(1)}
                      className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${
                        currentPage === 1
                          ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                          : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                    >
                      1
                    </button>
                    {currentPage > 3 && <span className="dots">....</span>}
                  </>
                )}
                {currentPage > 2 && (
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${
                      currentPage === currentPage - 1
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                  >
                    {currentPage - 1}
                  </button>
                )}
                <button
                  className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${
                    currentPage === currentPage
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  }`}
                >
                  {currentPage}
                </button>
                {currentPage < pageCount - 1 && (
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${
                      currentPage === currentPage + 1
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                  >
                    {currentPage + 1}
                  </button>
                )}
                {currentPage < pageCount && (
                  <>
                    {currentPage < pageCount - 2 && (
                      <span className="dots">....</span>
                    )}
                    <button
                      onClick={() => setCurrentPage(pageCount)}
                      className={`mx-1 py-2 px-3 rounded-md cursor-pointer ${
                        currentPage === pageCount
                          ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                          : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                    >
                      {pageCount}
                    </button>
                  </>
                )}
              </>
            )}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === pageCount}
              className="flex items-center justify-center px-1 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
            >
              <span className="sr-only">Next</span>
              <svg
                class="w-1.5 h-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </button>
          </div>
        )}
      </TitleCard>
    </>
  );
};

export default OtcAccessRequestTable;

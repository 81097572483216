// src/RichTextEditor.js
import React, { useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import { Button } from '@/shared/components/Button';

const RichTextEditor = ({ onSave }) => {
    const [editorState, setEditorState] = useState('');

    const saveContent = () => {
        onSave(editorState);
    };

    return (
        <div className='d-flex flex-col text-white'>
            <ReactQuill value={editorState} onChange={setEditorState} />
            {/* <button onClick={saveContent}>Save Policy</button> */}
            <FormButton onClick={saveContent} variant="outline-primary" className="col-12 col-md-3 flex flex-row justify-center self-center" type="submit">
                Save Policy Notice
            </FormButton>
        </div>
    );
};

export default RichTextEditor;
const FormButton = styled(Button)`
 color:"white";
 margin-top: 1rem;
 `;

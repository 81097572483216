import React, { createContext, useContext, useState } from "react";

const ChainContext = createContext();

export const ChainProvider = ({ children }) => {
  const [reFethData, setReFetchData] = useState(false);
  const [socket, setSocket] = useState(null);

  return (
    <ChainContext.Provider
      value={{
        reFethData,
        setReFetchData,
        socket,
        setSocket,
      }}
    >
      {children}
    </ChainContext.Provider>
  );
};

export const useChainContext = () => {
  const context = useContext(ChainContext);
  if (!context) {
    throw new Error("useChainContext must be used within a ChainProvider");
  }
  return context;
};

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import { deleteNewOrderTableData } from '@/redux/actions/newOrderTableActions';
import TotalProducts from './components/TotalProducts';
import TotalProfit from './components/TotalProfit';
import OrdersToday from './components/OrdersToday';
import Subscriptions from './components/Subscriptions';
import TotalOrders2 from './components/TotalProducts2';
import TotalProfit2 from './components/TotalProfit2';
import OrdersToday2 from './components/OrdersToday2';
import Subscriptions2 from './components/Subscriptions2';
import TotalProducts3 from './components/TotalProducts3';
import TotalProfit3 from './components/TotalProfit3';
import OrdersToday3 from './components/OrdersToday3';
import Subscriptions3 from './components/Subscriptions3';
import TotalProducts4 from './components/TotalProducts4';
import TotalProfit4 from './components/TotalProfit4';
import OrdersToday4 from './components/OrdersToday4';
import Subscriptions4 from './components/Subscriptions';
import TopSellingProducts from './components/TopSellingProducts';
import BasicCard from './components/BasicCard';
import SalesStatistic from './components/SalesStatistic';
import RecentOrders from './components/RecentOrders';
import ProductSales from './components/ProductSales';
import StoreFee from './components/StoreFee';
import NewOrders from './components/NewOrders';
import SalesStatisticBar from './components/SalesStatisticBar';
import MyTodos from './components/MyTodos';
import Emails from './components/Emails';
import SalesReport from './components/SalesReport';
import ShortReminders from './components/ShortReminders';
import { editTodoElement, fetchTodoListData } from '../../Todo/redux/actions';
import { Ring } from '@uiball/loaders';
import { storeDetails, orderDetails, productDetails, getRecentOrder, getFeeGraph, getSaleReport, getStoreReport, getSaleGraph, getTopSellingProducts } from '../../../utils/services/user.service';
const onDeleteRow = (dispatch, newOrder) => (index) => {
  const arrayCopy = [...newOrder];
  arrayCopy.splice(index, 1);
  dispatch(deleteNewOrderTableData(arrayCopy));
};

const ECommerceDashboard = () => {
  const { t } = useTranslation('common');
  const [Data, setData] = useState([]);
  const [SaleReportData, setSaleReportData] = useState([]);
  const [StoreReportData, setStoreReportData] = useState([]);
  const [SaleGraphtData, setSaleGraphtData] = useState([]);
  const [TopSellingProductsData, setTopSellingProductsData] = useState([]);
  const [OrderData, setoOrderData] = useState([]);
  const [ProductData, setProductData] = useState([]);
  const [RecentOrder, setRecentOrder] = useState([]);
  const [StoreFeeGraph, setStoreFeeGraph] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //console.log("FlashProductData", Data)
  //console.log("StoreFeeGraph", StoreFeeGraph)
  //console.log("SaleReportData", SaleReportData)
  //console.log("StoreReportData", StoreReportData)
  //console.log("SaleGraphtData", SaleGraphtData)
  //console.log("TopSellingProductsData", TopSellingProductsData)
  //console.log("OrderData", OrderData)
  //console.log("ProductData", ProductData)
  //console.log("RecentOrder", RecentOrder)
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const storeResponse = await storeDetails();
        if (storeResponse.data.result) {
          const storeData = storeResponse.data.result;
          //console.log('Store Data:', storeData);
          setData(storeData);
        } else {
          console.error('Error fetching store details:', storeResponse.statusText);
        }

        const orderResponse = await orderDetails();
        if (orderResponse.data.result) {
          const orderData = orderResponse.data.result;
          //console.log('Order Data:', orderData);
          setoOrderData(orderData);
        } else {
          console.error('Error fetching order details:', orderResponse.statusText);
        }
        const StoreFeeResponce = await getFeeGraph();
        if (StoreFeeResponce.data.result) {
          const orderData = StoreFeeResponce.data.result;
          //console.log('Order Data:', orderData);
          setStoreFeeGraph(orderData);
        } else {
          console.error('Error fetching order details:', orderResponse.statusText);
        }
        const RecentOrders = await getRecentOrder();
        if (RecentOrders.data.recent_order) {
          const RecentOrder = RecentOrders.data.recent_order;
          //console.log('Order Data:', RecentOrder);
          setRecentOrder(RecentOrder);
        } else {
          console.error('Error fetching order details:', orderResponse.statusText);
        }
        const productResponse = await productDetails();
        if (productResponse.data.result) {
          const productData = productResponse.data.result;
          //console.log('Product Data:', productData);
          setProductData(productData);
        } else {
          console.error('Error fetching product details:', productResponse.statusText);
        }
        const SaleReportResponse = await getSaleReport();
        if (SaleReportResponse.data.result) {
          const SaleReportdata = SaleReportResponse.data.result;
          //console.log('Sale Report data:', SaleReportdata);
          setSaleReportData(SaleReportdata);
        } else {
          console.error('Error fetching store details:', SaleReportResponse.statusText);
        }
        const StoreReportResponse = await getStoreReport();
        if (StoreReportResponse.data.result) {
          const StoreReportdata = StoreReportResponse.data.result;
          //console.log('Sale Store data:', StoreReportResponse);
          setStoreReportData(StoreReportdata);
        } else {
          console.error('Error fetching store details:', SaleReportResponse.statusText);
        }
        const SaleGraphResponse = await getSaleGraph();
        if (SaleGraphResponse.data.result) {
          const SaleGraphdata = SaleGraphResponse.data.result;
          //console.log('Sale Graph data:', StoreReportResponse);
          setSaleGraphtData(SaleGraphdata);
        } else {
          console.error('Error fetching store details:', SaleReportResponse.statusText);
        }
        const TopSellingProductsResponse = await getTopSellingProducts();
        if (TopSellingProductsResponse.data.result) {
          const TopSellingdata = TopSellingProductsResponse.data.result;
          //console.log('Sale TopSelling data:', TopSellingdata);
          setTopSellingProductsData(TopSellingdata);
        } else {
          console.error('Error fetching store details:', SaleReportResponse.statusText);
        }
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false)
      }
    };

    fetchData();
  }, []);


  const { newOrder, todoElements, rtl } = useSelector(state => ({
    newOrder: state.newOrder.items,
    todoElements: state.todo && state.todo.data && state.todo.data.elements
      && state.todo.data.elements.length > 0 ? [...state.todo.data.elements] : [],
    rtl: state.rtl,
  }));

  const dispatch = useDispatch();

  const editTodoElementAction = () => {
    dispatch(editTodoElement());
  };

  useEffect(() => {
    if (todoElements.length === 0) { // You can delete it if you need
      dispatch(fetchTodoListData());
    }
  }, [todoElements.length, dispatch]);

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none',
          justifyContent: 'center',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Container className="dashboard">
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t('dashboard_commerce.page_title')}</h3>
          </Col>
        </Row>
        <Row>
          <TotalProducts Data={Data} />
          <TotalProfit Data={Data} />
          <OrdersToday Data={Data} />
          <Subscriptions Data={Data} />
        </Row>
        <Row>
          <TotalOrders2 OrderData={OrderData} />
          <TotalProfit2 OrderData={OrderData} />
          <OrdersToday2 OrderData={OrderData} />
          <Subscriptions2 OrderData={OrderData} />
        </Row>
        <Row>
          <TotalProducts3 ProductData={ProductData} />
          <TotalProfit3 ProductData={ProductData} />
          <OrdersToday3 ProductData={ProductData} />
          <Subscriptions3 ProductData={ProductData} />
        </Row>
        <Row>

          <ProductSales SaleGraphtData={SaleGraphtData} rtl={rtl.direction} />
          {/* <SalesStatisticBar /> */}
        </Row>
        <Row>

          <StoreFee StoreFeeGraph={StoreFeeGraph} rtl={rtl.direction} />
          {/* <SalesStatisticBar /> */}
        </Row>
        <Row>
          {/* <BasicCard /> */}
          {/* <SalesStatistic /> */}
          {/* <MyTodos
          todoElements={todoElements}
          editTodoElement={editTodoElementAction}
        /> */}
          {/* <Emails /> */}
          {/* <ShortReminders /> */}
          <TopSellingProducts TopSellingProductsData={TopSellingProductsData} dir={rtl.direction} />
          <SalesReport SaleReportData={SaleReportData} StoreReportData={StoreReportData} />
          {/* <NewOrders newOrder={newOrder} onDeleteRow={onDeleteRow(editTodoElementAction, newOrder)} /> */}
          <RecentOrders RecentOrder={RecentOrder} />
        </Row>
      </Container>
    </>
  );
};

export default ECommerceDashboard;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import ContractTable from './component/ContractTable';
import DataTable from './component/DataTable';
import  {listFee} from '../../../../utils/services/user.service'
 const ContractOperations = () => {
const [ListData, setListData] = useState()

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fees = await listFee(); 
        
        ////console.log('Fees:', fees); 
        const data = fees.data.data
        setListData(data)
      } catch (error) {
       
        console.error('Error fetching fees:', error);
      }
    };

    fetchData(); 
  }, [])

  const fetchData = async () => {
    try {
      const fees = await listFee(); 
      
      ////console.log('Fees:', fees); 
      const data = fees.data.data
      setListData(data)
    } catch (error) {
     
      console.error('Error fetching fees:', error);
    }
  };
  return(
  <Container className="dashboard">
    <Row>
      <Col md={12}>
        <h3 className="page-title">Fee</h3>
      </Col>
    </Row>
    <Row>
      {/* <ContractTable /> */}
      <DataTable ListData={ListData} fetchData={fetchData} />
    </Row>

  </Container>
  )
 };
export default ContractOperations;

import Modal from "react-bootstrap/Modal";
import { CustomSelect } from "@/shared/components/CustomSelect";
import styled from "styled-components";
import { useState } from "react";

import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import {
  transferGasBNB,
  transferGasEth,
  transferGasMatic,
  transferGasSol,
} from "../../../../utils/services/user.service";

const TransferModal = ({ show, hide, transferType }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [walletaddress, setWalletAddress] = useState("");
  const [amount, setAmount] = useState(null);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      if (!walletaddress || !amount) {
        toast.error("All fields are required");
        return;
      }
      let res;
      const payload = { address: walletaddress, amount: amount };
      if (transferType === "BNB") {
        res = await transferGasBNB(payload);
      } else if (transferType === "ETH") {
        res = await transferGasEth(payload);
      } else if (transferType === "MATIC") {
        res = await transferGasMatic(payload);
      } else if (transferType === "SOLANA") {
        res = await transferGasSol(payload);
      } else {
        throw new Error("Invalid transferType");
      }

      if (res?.data?.status === 200) {
        toast.success("Gas Transferred Successfully");
        hide();
      } else {
        throw new Error("Transfer failed");
      }
    } catch (error) {
      toast.error("Error occurred");
      console.error("Error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            <b>Transfer {transferType} GAS </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div
              className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div className="flex flex-col md:flex-row gap-2 justify-between w-full">
                <div className="flex flex-col w-full md:w-[50%] ">
                  <label>Wallet Address </label>
                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      value={walletaddress}
                      className="form-control "
                      placeholder={`Enter ${
                        transferType === "SOLANA"
                          ? "Enter Sol Address"
                          : "Wallet Address"
                      }`}
                      onChange={(e) => setWalletAddress(e.target.value)}
                    />
                  </InputDiv>
                </div>
                <div className="flex flex-col w-full md:w-[50%] ">
                  <label> Amount in {transferType}</label>
                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      value={amount}
                      className="form-control "
                      placeholder="Enter Amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </InputDiv>
                </div>
              </div>
              <div className=" flex w-full gap-4 justify-center items-center">
                <button
                  className="text-white p-1 px-4 py-2 rounded-md bg-success "
                  onClick={handleConfirm}
                >
                  Confirm
                </button>
                <button
                  className="text-white p-1 px-4 py-2 rounded-md bg-danger "
                  onClick={hide}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TransferModal;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

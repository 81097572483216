import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';
import { toast } from 'react-toastify';
import FilterVariantIcon from 'mdi-react/FilterVariantIcon'; 
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import Alert from '@/shared/components/Alert';
import ChatCard from './components/ChatCard';
// import {
//   createTicket,
// } from '../../../utils/services/user.service';

const Support = () => {
  const { t } = useTranslation('common');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [show1, setShow1] = useState(false);
  const handleClose1 = () => setShow1(false);
  const [SelectedMessege, setSelectedMessege] = useState('');
  const [SelectedSubject, setSelectedSubject] = useState('');
  const [showError1, setShowError1] = useState(false);
  const [showError2, setShowError2] = useState(false);
  const handleMessegeChange = (event) => {
    const selectedCondition = event.target.value;
    setSelectedMessege(selectedCondition);
  };
  const handleSubjectChange = (event) => {
    const selectedCondition = event.target.value;
    setSelectedSubject(selectedCondition);
  };
  const handleShow = () => {
    setShow(true);
  };
  // setShow1(true);
  // const createTicketUser = () => {
  //   //console.log('createTicket');
  //   if (SelectedSubject === '' || SelectedMessege === '') {
  //     if (SelectedSubject === '') {
  //       setShowError1(true);    
  //     }
  //     if (SelectedMessege === '') {
  //       setShowError2(true);
  //     }
  //   } else {
  //     setShow(false);
  //     const fetchTicket = async () => {
  //       try {
  //         const requestData = {
  //           subject: SelectedSubject,
  //           message: SelectedMessege,
  //         };
  //         const response = await createTicket(requestData);
  //         if (response.data) {
  //           if (response.data.status === 200) {
  //             toast.success('Ticket Created Successfully', {
  //               position: toast.POSITION.TOP_RIGHT,
  //             });
  //           }
  //           //console.log('createTicket Response Data : ', response.data); 
  //         } else {
  //           console.error('No createTicket');
  //         }
  //       } catch (error) {
  //         console.error('Error fetching createTicket:', error);
  //       }
  //     };
  //     fetchTicket();
  //   }
  // }; 
  // const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  //   <span
  //     ref={ref}
  //     onClick={(e) => {
  //       e.preventDefault();
  //       onClick(e);
  //     }}
  //   >
  //     {children}
  //   </span>
  // ));
  return (
    <Container>
      <Row className="mx-3">
        <Col xs={12} lg={2} className=" text-center" />
        <Col xs={12} md={6} lg={8} className=" text-center">
          <h2 className="mb-0 mt-0" style={{ fontSize: 'x-large' }}>Tickets</h2>
        </Col>
        {/* <Col xs={12} md={6} lg={2} className="py-1 text-center">
          <Button
            variant="dark"
            style={{ fontSize: '14px' }}
            className="purple-button mt-1 py-2 text-capitalize w-100 float-right"
            onClick={() => handleShow()}
          >
            Create Ticket
          </Button>
        </Col> */}
      </Row>
      <Row>
        {/* <Col md={2}>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
              <FilterVariantIcon style={{ color: 'white' }} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item>Action 1</Dropdown.Item>
              <Dropdown.Item>Action 2</Dropdown.Item>
              <Dropdown.Item>Action 3</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </Col> */}
      </Row>
      <Row>
        <ChatCard />
      </Row>
      
     
    </Container>
  );
};

export default Support;

const ErrorMsg = styled.div`
 
color: #e66e6e;
padding: 2px 0px;
font-size: 12px;
cursor:none;
`;

import React,{useState} from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuIcon from 'mdi-react/MenuIcon';
import MoreVertIcon from 'mdi-react/MoreVertIcon';
import VideoIcon from 'mdi-react/VideoIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import StarIcon from 'mdi-react/StarIcon';
import moment from 'moment';
import { ContactProps } from '@/shared/prop-types/ChatProps';
import {
  colorAccent,
  colorAdditional,
  colorBackground,
  colorFieldsBorder,
  colorIcon,
} from '@/utils/palette';
import io from 'socket.io-client';
import config from '../../../../config/config';
import {
  marginLeft,
  marginRight,
  left,
} from '@/utils/directions';
import Dropdown from 'react-bootstrap/Dropdown';

const ChatTopbar = ({ChatId, onClick, contact, setSelectedChat }) => {
  //////console.log('topbar contact>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',contact)
  const [status,setStatus] = useState()
  //////console.log('status contact>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',status)

  const socket = io(config.baseURL,{secure: true}); 
  const [dropdownValue, setDropdownValue] = useState(''); 
  // if (contact && contact.online_status && contact.online_status.length > 0) {
  //   setStatus(contact.online_status)
  // }
  const CloseChat = () => {
    //////console.log('Chat Closed', );
    socket.emit('close_chat', {
      chat_id: ChatId,
      user_id: JSON.parse(sessionStorage.getItem('user'))?.data?.user?.user_id,
      // message: Option,
    }, (response) => {
      //////console.log('Message sent, server response:', response);
    });
  };
  // Function to handle dropdown value changes
  const handleDropdownChange = (e) => {
    const status = e.currentTarget.getAttribute('data-status');
    //////console.log('event', status)
    setDropdownValue(status);

  };
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>  
  ));
  const getStatus = () => {
    if (contact && contact?.online_status && contact?.online_status.length > 0) {
      const latestStatus = contact?.online_status[contact?.online_status?.length - 1]; // Assuming the latest status is at the end of the array
      //////console.log('latestStatus contact>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>',latestStatus)
  
      if (latestStatus?.online_status) {
        return 'Online';
      } else {
        const lastSeenTime = moment(latestStatus?.updatedAt).format('h:mm A');
        return `Last seen ${lastSeenTime}`;
      }
    } else {
      return 'Offline'; 
    }
  };

  return (
    <ChatTopbarWrap>
    
      <ChatTopbarButtonMenu type="button" onClick={onClick}>
        <MenuIcon />
      </ChatTopbarButtonMenu>
   
    {contact && (
      <ChatTopbarContact>
        {contact?.type === 'room' ? (
          <ChatTopBbarContactName>{contact?.subject}</ChatTopBbarContactName>
        ) : (
          <ChatTopBbarContactName>{contact?.user_detail[0]?.name}</ChatTopBbarContactName>
        )}
        <ChatTopbarContactStatus>{getStatus()}</ChatTopbarContactStatus>
      </ChatTopbarContact>
    )}
    <ChatTopbarRight>
      {contact && (
        <ChatTopbarControls>
          <ChatTopbarButton type="button">
            <VideoIcon />
          </ChatTopbarButton>
          <ChatTopbarButton type="button">
            <PhoneIcon />
          </ChatTopbarButton>
          <ChatTopbarButton type="button">
            <StarIcon />
          </ChatTopbarButton>
        </ChatTopbarControls>
      )}
      {contact && contact?.status === 'open' && contact?.type === 'private' && (
        <ChatTopbarButton type="button">
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
              <MoreVertIcon style={{ color: 'white' }} />
            </Dropdown.Toggle>
            { contact && contact?.type === 'private' && contact?.type !== 'room' ? ( // Condition to check contact type
              <Dropdown.Menu className="py-1">
                <Dropdown.Item
                  className="hover:bg-gray-200 hover:text-gray-800"
                  data-status="completed"
                  type="button"
                  onClick={CloseChat}
                >
                  Close chat
                </Dropdown.Item>
              </Dropdown.Menu>
            ) : null}
          </Dropdown>
        </ChatTopbarButton>
      )}
    </ChatTopbarRight>
     
  </ChatTopbarWrap>
  );
};

ChatTopbar.propTypes = {
  onClick: PropTypes.func.isRequired,
  contact: ContactProps,
};

ChatTopbar.defaultProps = {
  contact: null,
};

export default ChatTopbar;

// region STYLES
// const Dropdown = styled.div`
//   position: relative;
//   display: inline-block;
// `;

const DropdownButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 50px; /* Adjust the width as needed */
  height: 50px; 
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DropdownContent = styled.div`
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 260px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  z-index: 1;

  ${Dropdown}:hover & {
    display: block;
  }
`;

const DropdownButtonOption = styled.button`
  background-color: #f9f9f9;
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  width: 200px;
  text-align: left;
  border: none;
  cursor: pointer;

  &:hover {
    background-color: #f1f1f1;
  }
`;
const ChatTopbarContactStatus = styled.p`
  margin: 0;
  color: ${colorAdditional};
  font-size: 11px;
  line-height: 1.45;
`;
const ChatTopbarWrap = styled.div`
  display: flex;
  height: 50px;
  align-items: center;
  border-bottom: 1px solid ${colorFieldsBorder};
  background-color: ${colorBackground};
`;

const ChatTopbarButton = styled.button`
  background-color: transparent;
  border: none;
  transition: all 0.3s;
  cursor: pointer;
  width: 50px;
  height: 50px;
  
  svg {
    height: 18px;
    width: 18px;
    transition: 0.3s;
    fill: ${colorIcon};
  }

  &:hover svg {
    fill: ${colorAccent};
  }
`;

const ChatTopbarButtonMenu = styled(ChatTopbarButton)`
  ${marginRight}: -20px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const ChatTopbarControls = styled.div`
  display: none;

  @media screen and (min-width: 698px) {
    display: flex;
  }
`;

const ChatTopbarRight = styled.div`
  ${marginLeft}: auto;
  display: flex;
`;

const ChatTopbarContact = styled.div`
  text-align: ${left};
  ${marginLeft}: 20px;
`;

const ChatTopBbarContactName = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
`;

const ChatTopbarContactPost = styled.p`
  margin: 0;
  color: ${colorAdditional};
  font-size: 11px;
  line-height: 1.45
`;

// endregion


function TitleCard({title, children, topMargin, TopSideButtons}){
    return(
        <div className={" w-full  bg-inherit shadow-xl " }>

          {/* Title for Card */}
            <subtitle styleClass={TopSideButtons ? "inline-block" : ""} >
              {title}

              {/* Top side button, show only if present */}
              {
                  TopSideButtons && <div className="inline-block float-right">{TopSideButtons}</div>
              }
            </subtitle>
            
            <div className="divider "></div>
        
            {/** Card Body */}
            <div className='h-full w-full pb-6 '>
                {children}
            </div>
        </div>
        
    )
}


export default TitleCard
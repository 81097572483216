import { useState } from "react";
import { Modal as BootstrapModal } from "react-bootstrap";
import { editWithdrawLimit, setWithdrawLimit } from "../../../utils/services/user.service";
import { toast } from "react-toastify";
import styled from "styled-components";
import { Button } from "@/shared/components/Button";



const WithdrawLimitModal = ({ hide, show, data, reFetch, limitType }) => {

    const [amount, setAmount] = useState("")
    const [recordId, setRecordId] = useState("")

    const setLimit = async () => {
        try {
            const payload = {
                'amount': amount
            }
            const res = await setWithdrawLimit(payload)
            toast.success(res?.data?.Message)
            hide()
            reFetch()

        } catch (error) {
            console.log("🚀 ~ setLimit ~ error:", error)

        }
    }
    const editLimit = async () => {
        try {
            const payload = {
                new_amount: amount,
                record_id: recordId
            }
            const res = await editWithdrawLimit(payload)

            toast.success(res?.data?.Message)
            hide()
            reFetch()

        } catch (error) {
            console.log("🚀 ~ setLimit ~ error:", error)

        }
    }
    return (
        <BootstrapModal
            show={show}
            onHide={hide}
            centered
            className="dark:bg-gray-800"
        >
            <BootstrapModal.Header
                closeButton
                className="text-black dark:border-gray-700 "
            >
                <BootstrapModal.Title>Withdraw Limit</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body className="dark:text-white ">
                <div className="flex flex-col gap-2">
                    {limitType == "setLimit" ? <div className="flex flex-col col-12 col-md-6">
                        <label className="text-black">Amount</label>
                        <input
                            className="p-1 rounded-lg text-black border bottom-1"
                            type="text"
                            placeholder="Enter Amount"
                            value={amount}
                            onChange={(e) => setAmount(e.target.value)}
                        />
                    </div>
                        :
                        <div className="flex flex-col col-12 col-md-6">
                            <label className="text-black">Amount</label>
                            <input
                                className="p-1 rounded-lg text-black border bottom-1"
                                type="text"
                                placeholder="Enter Amount"
                                // value={data}
                                onChange={(e) => setAmount(e.target.value)}
                            />
                        </div>
                    }


                    {limitType != "setLimit" &&
                        <div className="flex flex-col col-12 col-md-6">
                            <label className="text-black">Record ID</label>
                            <input
                                className="p-1 rounded-lg text-black border bottom-1"
                                type="text"
                                placeholder="Enter Record ID"
                                value={recordId}
                                onChange={(e) => setRecordId(e.target.value)}
                            />
                        </div>}
                </div>
            </BootstrapModal.Body>
            <BootstrapModal.Footer className="dark:bg-gray-800 dark:border-t dark:border-gray-700">

                <FormButton variant="outline-danger" onClick={hide} className="mt-4" type="submit">
                    Close
                </FormButton>
                <FormButton variant="outline-primary" onClick={limitType != "setLimit" ? editLimit : setLimit} className="mt-4" type="submit">
                    Confirm
                </FormButton>

                {/* <Button variant="primary" className="text-black" onClick={limitType != "setLimit" ? editLimit : setLimit}>
                    Confirm
                </Button> */}
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
};

export default WithdrawLimitModal;
const FormButton = styled(Button)`
  color: "white";
  margin-top: 1rem;
`;

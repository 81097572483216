import { useEffect, useState } from "react";
import { Ring } from "@uiball/loaders";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import { getAllTokens, getPendingMigration, getToken } from "../../utils/services/user.service";
import { chainIdToName, getStatusBadge } from "../../redux/constants/constans";
import { CustomSelect } from "../../shared/components/CustomSelect";
import styled from 'styled-components';
import { Button } from '@/shared/components/Button';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import numeral from "numeral";
import moment from "moment";
import MigrationConfirmModa from "./MigrationConfirmModal";


const MigrationTable = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [migrationReportData, setMigrationReportData] = useState([]);
    const [allSymbols, setAllSymbols] = useState([]);
    const [selectedSymbol, setSelectedSymbol] = useState('');
    const [userId, setUserId] = useState("");
    const [user_email, setUserEmail] = useState("");
    const [isPending, setIsPending] = useState(false);
    const [isPendingId, setIsPendingId] = useState("");
    const [showMigrationConfirmModal, setshowMigrationConfirmModal] = useState(false)
    const [migrationData, setMigrationData] = useState('')
    const [TransferType, setTransferType] = useState("")
    const [reFetch, setRefetch] = useState(true)
    const Ava = `/img/avat.png`;
    const modifiedResponse = allSymbols.map((item) => ({
        label: item.symbol,
        value: item.symbol,
      }));

    const fetchAllMigrationReport = async () => {
        setIsLoading(true);
        try {
            const payload = {
                limit: 10000, page_number: 1, user_id: userId,
                symbol: selectedSymbol,
                email: user_email
            };
            const res = await getPendingMigration(payload);
            setMigrationReportData(res.data?.data);
            setIsLoading(false);
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching news:", error);
        }
    };
    useEffect(() => {
        const fetchData = async () => {
          try {
            const fees = await getToken();
            ////console.log('Fees:', fees);
            const data = fees?.data?.user;
            setAllSymbols(data);
          } catch (error) {

            console.error("Error fetching fees:", error);
          }
        };
    
        fetchData();
      }, []);


    useEffect(() => {
        fetchAllMigrationReport();
        // fetchAllTokens();

    }, [selectedSymbol, userId,reFetch, user_email]);


    const header = [
        { accessor: "user_name", Header: "Name" },
        { accessor: "user_id", Header: "User Id" },
        { accessor: "createdAt", Header: "Date Created" },
        { accessor: "user_email", Header: "Email" },
        { accessor: "chain_id", Header: "Chain" },
        { accessor: "quantity", Header: "Quantity" },
        { accessor: "status", Header: "Status" },
        { accessor: "action", Header: "Action" },
    ];

    const tableConfi2 = {
        isEditable: false,
        isSortable: false,
        isResizable: false,
        isPagination: true,
        withSearchEngine: false,
        manualPageSize: [10, 20, 30, 40],
        placeholder: "Search...",
        withPagination: true,
    };

    const data = [];
    migrationReportData?.map((item) =>
        data.push({

            user_id: (
                <div className="flex gap-1 items-center">
                    <h6
                        className="text-capitalize"
                        style={{ color: "#999999", fontSize: "inherit" }}
                    >
                        {item.user_id}
                    </h6>
                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(item.user_id);
                            toast.success("user id copied to clipboard!");
                        }}
                        className="ml-2"
                        style={{ color: "lightblue" }}
                    >

                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </div>
            ),
            createdAt: (
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {moment(item.createdAt).format('ll') || "--"}
                </h6>
            ),
            chain_id: (
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    <span>{chainIdToName[item.chain_id] || item.chain_id}</span>
                </h6>
            ),
            user_name: (
                <div className="flex flex-row pr-5 items-center  gap-3 ">
                    <img className="flex h-[44px] w-[44px] rounded-3xl" src={item?.user_details[0]?.image || Ava} />
                    <h6 className="text-capitalize text-nowrap">
                        {item?.user_details[0]?.name}
                    </h6>
                </div>
            ),
            user_email: (

                <div className="flex gap-1 items-center">
                    <h6
                        className=""
                        style={{ color: "#999999", fontSize: "inherit" }}
                    >
                        {item?.user_details[0]?.email}
                    </h6>
                    <button
                        onClick={() => {
                            navigator.clipboard.writeText(item?.user_details[0]?.email);
                            toast.success("Email copied to clipboard!");
                        }}
                        className="ml-2"
                        style={{ color: "lightblue" }}
                    >

                        <FontAwesomeIcon icon={faCopy} />
                    </button>
                </div>
            ),


            status: (
                <h6
                    className="text-capitalize flex-col"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {getStatusBadge(isPending && item._id == isPendingId ? "in-progress" : item.status)}
                </h6>
            ),
            quantity: (
                <h6
                    className="text-capitalize"
                    style={{ color: "#999999", fontSize: "inherit" }}
                >
                    {`${numeral(Number(item.quantity) || 0).format("0,0.00000")} ${item.symbol}`}
                </h6>
            ),
            action: (
                <div className="flex flex-row gap-2">
                    <><FormButton onClick={() => {
                        setMigrationData(item);
                        setshowMigrationConfirmModal(true);
                        setIsPendingId(item?._id);

                    }} variant="outline-primary" type="submit" disabled={item?.status !== "pending" || isPending && item._id == isPendingId}>
                        Transfer to Admin
                    </FormButton></>
                </div>
            )

        })
    );
    return (
        <>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>


            <div className="overflow-x-auto p-3 w-full">
                <h3 className="flex-col mr-3">Migration Report </h3>

                <div className="flex flex-col md:flex-row mt-2 ">
                    <div className="flex flex-col col-12 col-md-3  gap-2">
                        <div>
                            <InputDiv>
                                <label
                                    htmlFor="email"
                                    className="text-white"
                                    style={{ fontSize: "small" }}
                                >
                                    User Id
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter User id "
                                    className=" py-2 px-2 rounded-sm text-white  bg-transparent border border-white  "
                                    value={userId}
                                    onChange={(e) => setUserId(e.target.value)}
                                />
                            </InputDiv>
                        </div>

                    </div>
                    <div className="flex flex-col col-12 col-md-3  gap-2">
                        <div>
                            <InputDiv>
                                <label
                                    htmlFor="email"
                                    className="text-white"
                                    style={{ fontSize: "small" }}
                                >
                                    Email
                                </label>
                                <input
                                    type="text"
                                    placeholder="Enter User Email "
                                    className=" py-2 px-2 rounded-sm text-white  bg-transparent border border-white  "
                                    value={user_email}
                                    onChange={(e) => setUserEmail(e.target.value)}
                                />
                            </InputDiv>
                        </div>

                    </div>
                    <div className="col-md-3 col-sm-12">
                        <InputDiv>
                            <label
                                htmlFor="email"
                                className="text-white bg-transparent"
                                style={{ fontSize: "small" }}
                            >
                                Symbol
                            </label>
                            <CustomSelect
                                value={selectedSymbol}
                                onChange={(value) => {
                                    setSelectedSymbol(value.label);
                                }}
                                placeholder={selectedSymbol || "Select Symbol"}
                                options={modifiedResponse}
                                className={'bg-transparent text-white'}
                            />
                        </InputDiv>
                    </div>
                    <div className="col-md-3 mt-2 col-sm-12">
                        <InputDiv>
                            {/* <label
                                htmlFor="email"
                                className="text-white bg-transparent"
                                style={{ fontSize: "small" }}
                            >
                                Symbol
                            </label> */}
                            <FormButton variant="outline-danger" onClick={() => {
                                setSelectedSymbol("");
                                setUserId("")
                            }} type="submit" >
                                Reset
                            </FormButton>
                        </InputDiv>
                    </div>
                </div>


                <ReactTableBase
                    columns={header}
                    data={data}
                    tableConfig={tableConfi2}
                />
                {
                    migrationReportData?.data?.length == 0 && (
                        <h3>No data found</h3>
                    )
                }
            </div>
            {showMigrationConfirmModal && (
                <MigrationConfirmModa
                    show={showMigrationConfirmModal}
                    hide={() => setshowMigrationConfirmModal(false)}
                    data={migrationData}
                    // isPending={() => setIsLoading(true)}
                    reFetch={()=>{setRefetch(!reFetch)}}

                />
            )}


        </>
    );
};
export default MigrationTable;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  input {
    background-color: transparent;
    border: none;
    color:white;
    border-bottom:1px solid #555555;
  }
  input.disabled {
    cursor: not-allowed !important;
    color: #d9d9d9;
  }
`;
const FormButton = styled(Button)`
 color:"white";
 margin-top: 1rem;
 `;
import React from 'react';
import { Modal, Button, Table } from "@mui/material";

const CategoryModal = ({ selectedCategory, isCategoryModalOpen, CloseModal }) => {
  // console.log("data>>>>>>>>>>>", selectedCategory)
  //   console.log("selectedStore>>>>>>>>>>>", selectedCategory)
  
    if (!selectedCategory) {
      return (
        <Modal open={isCategoryModalOpen} onClick={CloseModal}>
          <div>No data available</div>
        </Modal>
      );
    }

  




  const customWidthStyle = {
    width: '100%',
    marginleft:'20rem',
    };
   
    const customWidthStylet = {
      width: '42rem',
       marginleft:'4rem !important',
      };
      const customWidthStyleta = {
        width: '93%',
        
        };
    
      return (
        <>
       
     <Modal
  open={isCategoryModalOpen}
  onClose={CloseModal}
  className="fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center z-50 text-white"
  style={customWidthStyle}
>
  <div
    className="  rounded-b-sm rounded-t-md bg-[rgb(39,40,46)] "
    style={customWidthStylet}
  >
       <h2 className="text-xl font-semibold text-center  top-0   text-white p-2">Category Details</h2>
       <Table className='ml-12'  style={customWidthStyleta}>
        {/* <div className="container"> */}
  {/* <tbody> */}
  <tr className=' '>
      <th className="text-left px-4 py-2 ">Category ID</th>
      <td className="text-left px-4 py-2 ">{selectedCategory._id}</td>
    </tr>
    <tr className=' '>
      <th className="text-left px-4 py-2 ">Category Name</th>
      <td className="text-left px-4 py-2 ">{selectedCategory.category_name}</td>
    </tr>
    <tr className=' '>
      <th className="text-left px-4 py-2">Sub Category</th>
      <td className="text-left px-4 py-2">  
       {selectedCategory && selectedCategory.sub_categories ? (
    selectedCategory.sub_categories.map((subcategory) => (
      <span key={subcategory.id}>{subcategory.sub_category_name}, </span>
    ))
  ) : (
    <span>No subcategories available</span>
  )}
  </td>
    </tr>
    <tr className=' '>
      <th className="text-left px-4 py-2">Custom Detail</th>
      <td className="text-left px-4 py-2"> 
      {selectedCategory && selectedCategory.custom_detail ? (
    selectedCategory.custom_detail.map((customDetail, index) => (
      <td key={index} className="text-left  py-2" style={{ maxWidth: '200px', whiteSpace: 'pre-wrap'  }}>
        {customDetail[Object.keys(customDetail)[0]]},
      </td>
    ))
  ) : (
    <td colSpan="number_of_columns">No custom details available</td>
  )}
  </td>
    </tr>
    <tr className=' '>
      {/* <th className="text-left px-4 py-2">Store Address</th> */}
      {/* <td className="text-left px-4 py-2">{selectedStore.storeAddress}</td> */}
    </tr>
    <tr className=' '>
      {/* <th className="text-left px-4 py-2">Store Description</th> */}
      {/* <td className="text-left px-4 py-2">{selectedStore.storeDescription}</td> */}
    </tr>
    <tr className=' '>
      {/* <th className="text-left px-4 py-2">Store Fee</th> */}
      <td className="text-left px-4 py-2">
        {/* {selectedStore.amount} {selectedStore.symbol} */}
      </td>
    </tr>
    <tr className=' '>
      {/* <th className="text-left px-4 py-2">Chain ID</th> */}
      {/* <td className="text-left px-4 py-2">{selectedStore.chainId}</td> */}
    </tr>
   
  
  
  {/* </tbody> */}
  {/* </div> */}
</Table>

<div className="w-full mt-2 mb-2  flex justify-end ">
          <button
  variant="outlined"
  className="w-16 h-8 mr-4 text-sm border border-red-500 hover:bg-red-500 hover:text-white text-red-500 rounded-md"
  onClick={CloseModal}
>
  Close
</button>
</div>
      </div>
    </Modal>

        </>
  );
};

export default CategoryModal;

/* eslint-disable max-len */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from "react";
import { Ring } from "@uiball/loaders";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import styled from "styled-components";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getMarketPlaceDetailsById,
  getOtcMarketPlacePackage,
  getPoolBalance,
  pauseOtcMarketPlace,
  playOtcMarketPlace,
} from "../../../utils/services/user.service";
import UpdateOtcMarketplaceModal from "./UpdateOtcMarketplaceModal";
import { useLocation } from "react-router";
import CreateOtcMarketplacePackage from "./CreateOtcMarketplacePackage";
import PackagesCard from "./PackagesCard";
import { useChainContext } from "../../context/context";
import StatusComponent from "../../../shared/components/StatusComponent/StatusComponent";
import FeeComponent from "./FeeComponent";
import { Button } from "@/shared/components/Button";
import AllTransectionTable from "./AllTransectionTable";
import PoolInfoGraph from "./PoolInfoGraph.js/PoolInfoGraph";
import InfoItem from "./InfoItem";
import AllReferralCodes from "./AllReferralsCodes";

const OtcMarketplaceDetail = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const marketplaceId = searchParams.get("id");
  const [marketplaceData, setMarketplaceData] = useState([]);
  console.log("🚀 ~ OtcMarketplaceDetail ~ marketplaceData:", marketplaceData);
  const [marketplacePackagesData, setMarketplacePackagesData] = useState([]);
  const { setReFetchData, reFethData } = useChainContext();
  const [isLoading, setIsLoading] = useState(false);
  const [updateModalData, setUpdateModalData] = useState(null); // State to track the id of the item to delete
  const [updatePackagesModalData, setUpdatePackagesModalData] = useState(null); // State to track the id of the item to delete
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [reFetchPackages, setReFetchPackages] = useState(false);
  const [createPackage, setCreatePackage] = useState(false);
  const [data, setData] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const optionColors = [
    { value: "#f79413", label: "Red" },
    { value: "#939396", label: "Blue" },
  ];

  const fetchOtcMarketplacePackages = async () => {
    setIsLoading(true);
    try {
      const payload = {
        otc_marketPlace_id: marketplaceId,
      };
      const response = await getOtcMarketPlacePackage(payload);

      setMarketplacePackagesData(response.data.packages);
      setUpdatePackagesModalData(response.data.packages);
      setShowUpdateModal(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowUpdateModal(false);
      console.error("Error fetching store details:", error);
    }
  };
  const fetchOtcMarketplaceById = async () => {
    setIsLoading(true);
    try {
      const payload = {
        marketPlace_id: marketplaceId,
      };
      const response = await getMarketPlaceDetailsById(payload);
      setMarketplaceData(response.data.data);
      setUpdateModalData(response.data.data[0]);
      setShowUpdateModal(false);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setShowUpdateModal(false);
      console.error("Error fetching store details:", error);
    }
  };
  useEffect(() => {
    fetchOtcMarketplaceById();
  }, [reFetch]);
  useEffect(() => {
    fetchOtcMarketplacePackages();
  }, [reFetchPackages, reFethData]);

  const handlePausePlay = async (status) => {
    setIsLoading(true);
    const payload = {
      market_place_id: marketplaceId,
    };
    try {
      if (status === "active") {
        const res = await pauseOtcMarketPlace(payload);
        toast.success(res?.data?.Message);
      } else {
        const res = await playOtcMarketPlace(payload);
        toast.success(res?.data?.Message);
      }
      setReFetch(!reFetch);
    } catch (error) {
      console.error("Error occurred:", error);
      toast.error(error?.response?.data.Message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleUpdateClick = (item) => {
    setUpdateModalData(item);
    setShowUpdateModal(true);
  };

  const poolData = async () => {
    const payload = {
      market_place_id: marketplaceId,
    };
    const res = await getPoolBalance(payload);
    setFilterData(res?.data?.balance);
  };
  useEffect(() => {
    poolData();
  }, []);

  useEffect(() => {
    const res = filterData?.map((item, index) => {
      const graphVal = parseFloat(item?.quantity);
      const symbol = item?.symbol;
      const fill = optionColors[index]?.value || "#ffff";
      return { fill, graphVal, symbol };
    });
    console.log("🚀 ~ res ~ res:", res);
    setData(res);
  }, [filterData]);
  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="container py-3">
        <h2 hidden className="text-center mb-4">
          OTC Marketplace Details
        </h2>
        {marketplaceData ? (
          <div className="row px-4 mt-2">
            <div className="col-lg-12 pt-lg-0 order-2 order-lg-2 d-flex flex-column justify-content-center py-1 px-3">
              <div className="row">
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <h3
                    style={{ textTransform: "Capitalize" }}
                    id="h2"
                    data-aos="fade-up"
                  >
                    {marketplaceData[0]?.name}
                  </h3>
                </div>
                <div className="col-lg-7 col-md-7 col-sm-12">
                  {marketplaceData[0]?.otc_type == "admin" && (
                    <FormButton
                      type="submit"
                      variant="outline-success"
                      className="flex items-center  gap-2 mr-2 float-right shadow-none text-capitalize"
                      onClick={() => setCreatePackage(true)}
                    >
                      Create Package
                      <i class="fa fa-plus-circle" aria-hidden="true"></i>
                    </FormButton>
                  )}
                  <button
                    className="flex items-center py-2 btn btn-danger gap-2 mr-2 purple-button  float-right shadow-none text-capitalize"
                    onClick={() => handleUpdateClick(updateModalData)}
                  >
                    Edit
                    <i class="fa fa-pencil" aria-hidden="true"></i>
                  </button>

                  <button
                    onClick={() => handlePausePlay(marketplaceData[0]?.status)}
                    className="btn btn-primary mr-2 px-6 purple-button py-2 float-right shadow-none text-capitalize"
                  >
                    {marketplaceData[0]?.status === "active" ? (
                      <i class="fa fa-pause" aria-hidden="true"></i>
                    ) : (
                      <i class="fa fa-play" aria-hidden="true"></i>
                    )}
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <Tabs
                    defaultActiveKey="home"
                    transition={false}
                    id="noanim-tab-example"
                    className="mb-3 mt-4"
                  >
                    {/* Marketplace info tab  */}
                    <Tab
                      eventKey="home"
                      ClassName="text-white"
                      title=" OTC Marketplace Info"
                    >
                      <div className="row text-center">
                        <InfoItem
                          label="Pool Id"
                          value={marketplaceData[0]?._id}
                        />
                        <InfoItem
                          label="Marketplace Name"
                          value={marketplaceData[0]?.market_place_name}
                        />
                        <InfoItem
                          label="Pool"
                          value={marketplaceData[0]?.pool}
                        />
                        <StatusComponent
                          title="Buy Status"
                          status={marketplaceData[0]?.buy_status}
                        />
                        <StatusComponent
                          title="Swapping Status"
                          status={marketplaceData[0]?.swapping_status}
                        />
                        <StatusComponent
                          title="Transfer Status"
                          status={marketplaceData[0]?.transfer_status}
                        />

                        <FeeComponent
                          title="Buy Fee"
                          fee={marketplaceData[0]?.buy_fee}
                          type={marketplaceData[0]?.buy_fee_type}
                        />
                        <FeeComponent
                          title="Swap Fee"
                          fee={marketplaceData[0]?.swap_fee}
                          type={marketplaceData[0]?.swap_fee_type}
                        />
                        <FeeComponent
                          title="Transfer Fee"
                          fee={marketplaceData[0]?.transfer_fee}
                          type={marketplaceData[0]?.transfer_fee_type}
                        />
                      </div>
                      <hr
                        className="mt-3"
                        style={{ borderTop: "1px solid white", opacity: "1.1" }}
                      />
                      {/* Packages Card  */}
                      <div className=" w-100 py-3">
                        <div className="row row-cols-1 row-cols-lg-4">
                          {marketplacePackagesData.map((item) => {
                            return (
                              <div className=" col">
                                <PackagesCard
                                  data={item}
                                  reFetchPackages={reFetchPackages}
                                  otcId={marketplaceId}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Tab>

                    {/* Pool info Tab  */}
                    <Tab
                      eventKey="Profile"
                      ClassName="text-white"
                      title="Pool Info"
                    >
                      <div className="row text-center">
                        <InfoItem
                          label="Owner Id"
                          value={marketplaceData[0]?.owner_details[0]?._id}
                        />

                        <InfoItem
                          label="Owner Name"
                          value={marketplaceData[0]?.owner_details[0]?.name}
                        />

                        <InfoItem
                          label="Owner Email"
                          value={marketplaceData[0]?.owner_details[0]?.email}
                        />
                      </div>
                      <hr
                        className="mt-3"
                        style={{ borderTop: "1px solid white", opacity: "1.1" }}
                      />
                      <div className="row flex-column col-lg-8">
                        <PoolInfoGraph
                          newArray={data}
                          defaultPool={marketplaceData[0]?.pool}
                        />
                      </div>
                    </Tab>

                    {/* All transection Table  */}
                    <Tab
                      eventKey="transections"
                      ClassName="text-white"
                      title="All Transections"
                    >
                      <div>
                        <AllTransectionTable marketplaceID={marketplaceId} />
                      </div>
                    </Tab>
                    {marketplaceData[0]?.otc_type != "general" && (
                      // {/* Referral Codes  */}
                      <Tab
                        eventKey="referral"
                        ClassName="text-white"
                        title="Referral Codes"
                      >
                        <div>
                          <AllReferralCodes
                            marketPlace_id={marketplaceData[0]?._id}
                          />
                        </div>
                      </Tab>
                    )}
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="row text-center">
            <div className="col-lg-4 col-md-12 col-sm-12 py-1 justify-content-center" />
            <div className="col-lg-4 col-md-12 col-sm-12 py-1 justify-content-center">
              <p className="py-4">No Data available.</p>
            </div>
            <div className="col-lg-4 col-md-12 col-sm-12 py-1 justify-content-center" />
          </div>
        )}

        {updateModalData && ( // Render delete modal only if deleteItemId is set
          <UpdateOtcMarketplaceModal
            show={showUpdateModal}
            hide={() => setShowUpdateModal(false)}
            data={updateModalData}
            reFetch={() => setReFetch(!reFetch)}
          />
        )}
        {createPackage && (
          <CreateOtcMarketplacePackage
            show={createPackage}
            hide={() => setCreatePackage(false)}
            otcId={marketplaceId}
            reFetchPackages={() => setReFetchPackages(!reFetchPackages)}
          />
        )}
      </div>
    </>
  );
};

export default OtcMarketplaceDetail;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;

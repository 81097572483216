import React, { useEffect, useState } from 'react';
import { Col, Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import StarRating from './StarRating';
import { updateProductadmin } from '../../../../utils/services/user.service';
import { Ring } from '@uiball/loaders';
import { toast } from 'react-toastify';

const Card = ({ product,reFetch }) => {
    // State to track the selected status
    const [selectedStatus, setSelectedStatus] = useState(product.status);
    const [showStoreName, setShowStoreName] = useState(false);
    const [isLoading, setIsLoading] = useState(false);

    // Function to handle status change
    const handleStatusChange = async (status) => {
        setSelectedStatus(status);
        setIsLoading(true)
        try {
            const payload = {
                product_id: product?._id,
                status: status
            };
            const res = await updateProductadmin(payload);
            toast.success(res.data.Message)
            reFetch();
            setIsLoading(false)
        } catch (error) {
            setIsLoading(false)
            console.error("An error occurred while updating status:", error);
        }
    };

    // Function to truncate store name
    const truncateProductName = (name) => {
        if (name?.length > 12) {
            return name.slice(0, 12) + '...';
        }
        return name;
    }


    return (
        <Col style={{ textTransform: 'capitalize' }}>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 99999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <div>
                <div className="mt-2 card productCard" style={{ background: '#232329' }}>
                    <Dropdown className='absolute top-1 left-1'>
                        <Dropdown.Toggle className='text-xs' variant={selectedStatus === "active" ? "success" : "danger"} id="dropdown-basic">
                            {selectedStatus}
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item onClick={() => handleStatusChange('active')}>active</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleStatusChange('inactive')}>inactive</Dropdown.Item>
                            <Dropdown.Item onClick={() => handleStatusChange('deleted')}>deleted</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                    <div className="relative flex justify-center mt-10 square-img-wrapper">
                        <img
                            src={product.pictures[0]}
                            className="p-3 card-img-top square-img border-radius zoom-in-on-hover w-[90%] h-[80%]"
                            alt={product.brand}
                        />
                    </div>
                    <div className="flex flex-col gap-3 card-body">
                        <div className='flex items-start justify-between'>
                            <h5 onMouseEnter={() => setShowStoreName(true)} onMouseLeave={() => setShowStoreName(false)} className="w-[60%] cursor-pointer text-white card-title producttitle">{showStoreName ? product.brand : truncateProductName(product.brand)}</h5>
                            {/* Display product colors */}
                            <span className='flex justify-end w-[40%]'>
                                {product?.product_sizes_quantity?.slice(0, 4)?.map(item => (
                                    <ProductItemColor
                                        key={item._id}
                                        style={{ background: item.color }}
                                    />
                                ))}
                            </span>
                        </div>
                        {/* Display star rating */}
                        <p className='text-left'>{product.email}</p>
                        <div className='flex items-center justify-between'>
                            <div className="flex justify-start">
                                <StarRating rating={product.avgRatting.length} />
                            </div>
                            <p>{product.product_sizes_quantity[0].price}{" "}USDT</p>
                        </div>
                    </div>
                </div>
            </div>
        </Col>
    );
}

export default Card;

// Styled components
const ProductItemColor = styled.span`
    height: 10px;
    width: 10px;
    margin-right: 8px;
    display: inline-block;
    border-radius: 50%;
    margin-top: 10px;
`;

// src/App.js
import React, { useState } from 'react';

import axios from 'axios';
import RichTextEditor from './TextEditor';
import DisplayPolicy from './DisplayPrivacyPolicy';
import { addPrivacyOrCookies } from '../../../utils/services/user.service';
import { toast } from 'react-toastify';
import styled from "styled-components";
import { Ring } from '@uiball/loaders';

const PrivacyNotice = () => {
    const [reFetch, setRefetch] = useState(false)
    const [isLoading, setIsLoading] = useState(false);

    const handleSave = async (content) => {
        setIsLoading(true)
        try {
            const payload = {
                content: `${content}`,
                type: 'policy'
            }
            const response = await addPrivacyOrCookies(payload)
            setRefetch(!reFetch)
            toast.success(response?.data?.message)
            setIsLoading(false)

        } catch (error) {
            console.error('Error saving content:', error);
            setIsLoading(false)

        }
    };

    return (
        <>
            {isLoading && (
                <LoaderOverlay>
                    <Ring size={65} color="#00897B" />
                </LoaderOverlay>
            )}
            <div className='d-flex flex-col gap-3 p-3'>
                <h3>Add Privacy Notice</h3>
                <RichTextEditor onSave={handleSave} />
                <hr />
                <DisplayPolicy reFetch={reFetch} />
            </div>
        </>
    );
};

export default PrivacyNotice;
const LoaderOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
`;
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col } from 'react-bootstrap';
import ReactTableBase from '@/shared/components/table/ReactTableBase';
import {
  Card, CardBody,
} from '@/shared/components/Card';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { Modal as BootstrapModal } from 'react-bootstrap';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  colorText,
} from '@/utils/palette';
import {
  flexFlow,
} from '@/utils/directions';
import Tfaaa from "../../../../../Account/MyAccount/Tfaaa";
import { Button, ButtonToolbar } from '@/shared/components/Button';
import { editToken, addNewToken, deleteToken, getUserInfo1 } from '../../../../../../utils/services/user.service'
import { Ring } from "@uiball/loaders";
import { chainIdToName } from '../../../../../../redux/constants/constans';


const type = [
  { value: 1, name: "Ethereum" },
  { value: 56, name: "Binance Smart Chain" },
  { value: 11153, name: "Polygon" },
  { value: 900, name: "Solana" },
  { value: 1000, name: "Tron" },
];
const DataReactTable = ({ reactTableData, fetchData }) => {
  const [rows, setData] = useState(reactTableData);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNewModalOpen, setIsNewModalOpen] = useState(false);
  const [isConformationModalOpen, setisConformationModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [DelTokenId, setDelTokenId] = useState();
  const [editedFee, setEditedFee] = useState({
    contract_address: '',
    symbol: '',
    chain_id: '',
  });
  // console.log('editedFee',editedFee)

  const [newFee, setNewFee] = useState({
    contract_address: '',
    symbol: '',
    chain_id: '',
  });


  const [TFA, setTFA] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;
  const [tfaUrl, setTfaUrl] = useState(false);
  const [tfaEmail, setTfaEmail] = useState("");
  const [showTfaModal, setShowTfaModal] = useState(false);
  const [isNewMopdL, setisNewMopdL] = useState(false);
  const [isNewMopdL2, setisNewMopdL2] = useState(false);



  const renderTfaModal = () => {
    return (
      <BootstrapModal
        show={showTfaModal}
        onHide={() => setShowTfaModal(false)}
        centered
        className="dark:bg-gray-800"
      >
        <BootstrapModal.Header
          closeButton
          className="dark:border-gray-700 bg-dark text-white"
        >
          <BootstrapModal.Title>TFA Modal</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body className="dark:text-white bg-dark">
          <Tfaaa
            tfaEmail={tfaEmail}
            tfaUrl={tfaUrl}
            onDataFromChild={handleDataFromChild}
          />
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="dark:bg-gray-800 bg-dark dark:border-t dark:border-gray-700">
          <Button variant="secondary" onClick={() => setShowTfaModal(false)}>
            Close
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  };

  const handleDataFromChild = (data) => {
    sessionStorage.setItem("data", data);
    if (data === true) {
      setShowTfaModal(false);
      if (isNewMopdL === true) {
        setIsModalOpen(true);
        setisNewMopdL(false);
      } else if (isNewMopdL2 === true) {
        setIsNewModalOpen(true);
        setisNewMopdL2(false);
      } else {
        setisConformationModalOpen(true);
      }

    } else {

      setTfaUrl(true);
      setTfaEmail(userData.data.user.email);
    }
  };


  useEffect(() => {
    const storedData = sessionStorage.getItem("data");
    if (storedData === "true") {
      handleDataFromChild(false);
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        email: usermail,
      };

      try {
        const response = await getUserInfo1(requestData);

        if (response.status === 200) {
          setTFA(response.data.User.TFA);
        } else {
          console.error("getUserInfo1 failed:", response);
        }
      } catch (error) {
        console.error("Error getUserInfo1:", error);
      }
    };

    // Initial data fetch
    fetchData();

    // Set up interval to fetch data every second
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [usermail, TFA]);


  const openModal = (feeId) => {
    //console.log('feeId feeId feeId',feeId)
    const specificFee = reactTableData.find(fee => fee._id === feeId);
    // console.log('specificFee',specificFee)
    if (TFA) {
      setTfaEmail(userData.data.user.email);
      setTfaUrl(true);
      setShowTfaModal(true);
      setisNewMopdL(true);
      setEditedFee({
        contract_address: specificFee.contract_address,
        chain_id: specificFee.chain_id,
        symbol: specificFee.symbol,
        // type: specificFee.type,
      });
    } else {
      if (specificFee) {
        setEditedFee({
          contract_address: specificFee.contract_address,
          chain_id: specificFee.chain_id,
          symbol: specificFee.symbol,
          // type: specificFee.type,
        });
        setIsModalOpen(true);
      }
    }
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
    setIsNewModalOpen(false);

  };



  const handleSave = async () => {
    try {
      if (!editedFee.contract_address || !editedFee.symbol || !editedFee.chain_id) {
        toast.error('All fields are required');
        setIsLoading(false);
        return;
      }
      setIsLoading(true)
      await editToken(editedFee);

      //console.log('Edited Fee:', editedFee);
      fetchData()
      setIsModalOpen(false);
      setIsLoading(false)
    } catch (error) {
      // Handle error appropriately
      setIsLoading(false)
      console.error('Error editing fee:', error);
    }
  };


  const customColumns = [
    {
      Header: '#',
      accessor: '_id',
    },
    {
      Header: 'Updated At',
      accessor: 'updatedAt',
      Cell: ({ value }) => (
        <span>{moment(value).format('MMMM Do YYYY')}</span>
      ),
    },
    {
      Header: 'Type',
      accessor: 'type',
      Cell: ({ value }) => (
        <span className="flex capitalize">{value !== undefined ? value.toString() : 'N/A'}</span>
      ),
    },
    {
      Header: 'Contact Address',
      accessor: 'contract_address',
      Cell: ({ value }) => {
        const truncatedText = value ? value.substring(0, 10) + '...' : ''; // Adjust the length as needed

        return (
          <div className='flex gap-2'>
            <span title={value}>{truncatedText || "--"}</span>
            {value && (
              <button className="text-blue-500" onClick={() => copyToClipboard(value)}>
                <FontAwesomeIcon icon={faCopy} />
              </button>
            )}
          </div>
        );
      },
    },
    {
      Header: 'Symbol',
      accessor: 'symbol',
    },
    {
      Header: 'Chain#',
      accessor: 'chain_id',
      Cell: ({ value }) => (
        <span>{chainIdToName[value] || value}</span>
      ),
    },
    {
      Header: 'Edit',
      accessor: 'edit',
      Cell: ({ row }) => (
        <div>
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faEdit}
            onClick={() => openModal(row.original._id)}
          />
        </div>
      ),
    },
    {
      Header: 'Delete',
      accessor: 'delete',
      Cell: ({ row }) => (
        <div>
          <FontAwesomeIcon
            style={{ cursor: 'pointer' }}
            icon={faTrash}
            onClick={() => handleDelete(row.original._id)}
          />
        </div>
      ),
    },
  ];

  const handleDelete = (id) => {
    setDelTokenId(id);

    if (TFA) {
      setTfaEmail(userData.data.user.email);
      setTfaUrl(true);
      setShowTfaModal(true);
    } else {
      setisConformationModalOpen(true);
    }
  };

  const handleRemove = async () => {
    try {
      setIsLoading(true);

      const payload = {
        token_id: DelTokenId
      };

      const response = await deleteToken(payload);
      // const result = await response.json();
      // console.log('Token removed successfully:', response);
      if (response.data.status === 200) {

        toast.success(response.data.Message);

        fetchData();
      } else {
        // Handle non-200 status codes
        const errorMessage = await response.text();
        throw new Error(`Error: ${response.status} - ${errorMessage}`);
      }
    } catch (error) {
      toast.error('Something went wrong');
      console.error('Error removing token:', error.message);
    } finally {
      setIsLoading(false);
      setisConformationModalOpen(false);
    }
  };



  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        // console.log('Text copied to clipboard:', text);
        toast.success('Copied!')
        // Optionally provide user feedback or perform additional actions after copying
      })
      .catch((error) => {
        console.error('Unable to copy text to clipboard:', error);
        // Handle any errors that may occur during copying
      });
  };

  const handleNewFee = () => {
    setNewFee({ contract_address: '', symbol: '', chain_id: '', });

    if (TFA) {
      setTfaEmail(userData.data.user.email);
      setTfaUrl(true);
      setShowTfaModal(true);
      setisNewMopdL2(true);
    } else {
      setIsNewModalOpen(true);
    }
  };

  const handleNewFeeChange = (fieldName, value) => {
    setNewFee({ ...newFee, [fieldName]: value });
  };

  const handleSaveNewFee = async () => {
    try {
      if (!newFee.contract_address || !newFee.symbol || !newFee.chain_id) {
        toast.error('All fields are required');
        setIsLoading(false);
        return;
      }
      setIsLoading(true)
      await addNewToken(newFee);
      fetchData()
      setIsNewModalOpen(false);
      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      toast.error('fee is required')
      console.error('Error editing fee:', error);
    }
  };

  const handleSymbolChange = (e) => {
    handleNewFeeChange('symbol', e.target.value);
  };


  const handletypeChange = (e) => {
    const selectedType = e.target.value;
    // console.log('selectedType',selectedType)
    handleNewFeeChange('chain_id', selectedType);

  };
  const handlEditChange = (e) => {
    const selectedType = e.target.value;

    setEditedFee({ ...editedFee, chain_id: selectedType });
  };
  const secondDropdownOptions = ['Option1', 'Option2', 'Option3'];

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          // background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>


      <Col md={12} lg={12}>
        <Card>
          <CardBody>
            <HeaderWrap>
              <Button onClick={handleNewFee}>Add New Token</Button>
            </HeaderWrap>
            <ReactTableBase
              // key={withSearchEngine || isResizable || isEditable ? 'modified' : 'common'}
              // columns={reactTableData}
              columns={customColumns}
              data={reactTableData}


            />

          </CardBody>
        </Card>
      </Col>
      <StyledModal style={{ display: isModalOpen ? 'block' : 'none' }}>
        <ModalCloseButton onClick={handleModalClose}>X</ModalCloseButton>
        <h2 className='text-center mb-4'>Edit Token</h2>
        <div className='flex justify-between mt-4 w-full'>
          <input
            type="text"
            value={editedFee.contract_address}
            onChange={(e) => {
              const { value } = e.target;
              setEditedFee({ ...editedFee, contract_address: value });
            }}
            className="block w-full border border-gray-300 rounded-md p-1 my-2 text-dark"
          />
        </div>
        <div className='flex justify-between mt-4 w-full'>
          <input
            type="text"
            value={editedFee.symbol}
            onChange={(e) => {
              const { value } = e.target;
              setEditedFee({ ...editedFee, symbol: value });
            }}
            className="block w-full border border-gray-300 rounded-md p-1 my-2 text-dark "
            placeholder="New Symbol "
          />
        </div>

        <div className='flex justify-between mt-4 w-full'>
          <select
            value={editedFee.chain_id}
            onChange={handlEditChange}
            className="block w-full border border-gray-300 rounded-md p-1 my-2 text-dark "
          >
            <option value="">Select a chain</option>
            {type.map((chain, index) => (
              <option key={index} value={chain.value}>
                {chain.name}
              </option>
            ))}
          </select>
        </div>

        <div className='flex justify-between mt-4'>
          <button
            onClick={handleSave}
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded ml-2 mt-2"
          >
            Save
          </button>
        </div>
      </StyledModal>


      <StyledModal style={{ display: isNewModalOpen ? 'block' : 'none' }}>
        <ModalCloseButton onClick={handleModalClose}>X</ModalCloseButton>
        <h2 className='text-center mb-4'>New Token</h2>
        <div className='flex justify-between mt-4 w-full'>
          <input
            type="text"
            value={newFee.contract_address}
            onChange={(e) => {
              const { value } = e.target;
              setNewFee({ ...newFee, contract_address: value });
            }}
            className="block w-full border border-gray-300 rounded-md p-1 my-2 text-dark "
            placeholder="New Token "
          />
        </div>

        <div className='flex justify-between mt-4 w-full'>
          <input
            type="text"
            value={newFee.symbol}
            onChange={(e) => {
              const { value } = e.target;
              setNewFee({ ...newFee, symbol: value });
            }}
            className="block w-full border border-gray-300 rounded-md p-1 my-2 text-dark "
            placeholder="New Symbol "
          />
        </div>

        <div className='flex justify-between mt-4 w-full'>
          <select
            value={newFee.chain_id}
            onChange={handletypeChange}
            className="block w-full border border-gray-300 rounded-md p-1 my-2 text-dark "
          >
            <option value="">Select a chain</option>
            {type.map((chain, index) => (
              <option key={index} value={chain.value}>
                {chain.name}
              </option>
            ))}
          </select>
        </div>


        <div className='flex justify-between mt-4'>

          <button
            onClick={handleSaveNewFee}
            className="bg-blue-500 hover:bg-blue-700 text-white w-full font-bold py-2 px-4 rounded mr-2 mt-2"
          >
            Save
          </button>
        </div>
      </StyledModal>


      <StyledModal style={{ display: isConformationModalOpen ? 'block' : 'none' }}>
        <ModalCloseButton onClick={() => setisConformationModalOpen(false)}>X</ModalCloseButton>
        <h2 className='text-center mb-4'>Confirm Token Delete</h2>

        <p>Are you sure you want to Delete this token?</p>

        <div className='flex justify-between mt-4'>
          <div className='flex justify-between w-full'>

            <button
              onClick={() => setisConformationModalOpen(false)}
              className="bg-gray-500 hover:bg-gray-700 text-white font-bold py-2 px-4 w-full rounded ml-2 mt-2"
            >
              Cancel
            </button>

            <button
              onClick={handleRemove}
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 w-full rounded ml-2 mt-2"
            >
              Delete
            </button>
          </div>
        </div>
      </StyledModal>
      {showTfaModal && renderTfaModal()}

    </>
  );
};

DataReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default DataReactTable;

// region STYLES

const HeaderWrap = styled.div`
  display: flex;
  
  & > div:first-child {
    margin-right: auto;
  }
`;
// const ModalCloseButton = styled.button`
//   position: absolute;
//   top: 15px;
//   right: 15px;
//   font-size: 14px;
//   width: 14px;
//   height: 14px;
//   cursor: pointer;
//   padding: 0;
//   border: none;
//   background: transparent;
//   color: #000; /* Replace with your desired color */
// `;

const StyledModal = styled.div`
  /* Centered modal */
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  width: 50%; /* Adjusted to fit content */
  height: auto; /* Adjusted to fit content */
  max-width: 90%; /* Set a maximum width if needed */
  max-height: 90%; /* Set a maximum height if needed */
  overflow: auto; /* Enable scrolling if the content exceeds the modal size */
  background-color: #1f1f1f;
  color: ${colorText};
  padding: 20px;
  border: 1px solid #000;
  border-radius: 10px;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: red; /* Set the color to red */
`;

// const StyledModal = styled(BootstrapModal)`
//   .modal-dialog {
//     max-width: 50%;
//     top: 50%;
//     transform: translateY(-50%) !important;

//     @media(max-width: 767px) {
//       max-width: 100%;
//       height: 100%;
//       top: 0;
//       transform: translateY(0) !important;
//       margin: 0;
//     }
//   }

//   .modal-content {
//     border-radius: 0;
//     border: none;
//     box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
//     position: relative;
//     padding: 50px 40px 60px 40px;
//     text-align: center;
//     background-color: #1f1f1f;
//     color: ${colorText};

//     @media(max-width: 767px) {
//       height: 100%;
//       align-items: center;
//       justify-content: center;
//     }

//     @media(max-width: 600px) {
//       padding: 16px;

//       .slick-slide {
//         padding: 0;
//       }
//     }
//   }
// `;

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }
  
  flex-flow: ${flexFlow} !important;
`;

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;

  &:first-child {
    margin-top: 0;
  }
`;

// const ModalCloseButton = styled.button`
//   position: absolute;
//   top: 15px;
//   ${right}: 15px;
//   font-size: 14px;
//   width: 14px;
//   height: 14px;
//   cursor: pointer;
//   padding: 0;
//   border: none;
//   background: transparent;
//   color: ${colorIcon};
// `;

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;
// endregion

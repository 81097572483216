/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-named-as-default */
import getTickets from '../../../../utils/services/user.service';

async function fetchAndExportTickets() {
  try {
    const payload = {
      status: 'all',
      limit: 10,
      page_number: 1,
    };
    const response = await getTickets(payload);
    // You should have the API response data in the 'response' variable here.
    //console.log('response', response);

    // Map and process the response data as needed.
    const mappedResponse = response.map(ticket => ({
      id: ticket._id,
      title: ticket.title,
      support_read_status: ticket.support_read_status,
      user_read_status: ticket.user_read_status,
      subject: ticket.subject,
      status: ticket.status,
      createdAt: ticket.createdAt,
      user_detail: ticket.user_detail,
      // Add more properties as needed.
    }));
    //console.log('mappedResponse', mappedResponse);

    // Export the mappedResponse directly.
    // You can export it as a module or perform any other actions you need.
    return mappedResponse;
  } catch (error) {
    // Handle any errors that may occur during the API call.
    console.error('Error fetching tickets:', error);
    throw error; // Optionally rethrow the error for further handling.
  }
}

export default fetchAndExportTickets;

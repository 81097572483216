import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useState } from "react";

import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import {
  transferCoinBalanceIntoMasterWallet,
  transferSolBalanceIntoMasterWallet,
  transferSolTokenToMasterWallet,
  transferTokenBNB,
  transferTokenEth,
  transferTokenMatic,
} from "../../../../utils/services/user.service";

const TransferBalanceModal = ({ show, hide, symbol, userObject,reFetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [amount, setAmount] = useState(null);

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      if (!amount) {
        toast.error("All fields are required");
        return;
      }
      let res;
      const payload = {
        symbol:symbol,
        amount: amount,
        user_id: userObject.user[0]?._id,
      };
      const transferFunctions = {
        BNB: ["BUSD", "USDT (BEP20)", "CTY", "MET$", "OPY", "FTY"],
        ETH: ["USDC", "EUROC", "USDT (ERC20)", "GBPT", "PAX"],
        MATIC: ["JPYC"],
        SOL: [,"LTX","WEN"],
        SOLANA: ["SOLANA"],
      };

      const transferCoinBalanceIntoMasterWalletList = ["BNB", "ETH", "MATIC"];

      if (transferCoinBalanceIntoMasterWalletList.includes(symbol)) {
        res = await transferCoinBalanceIntoMasterWallet(payload);
      } else if (transferFunctions.ETH.includes(symbol)) {
        res = await transferTokenEth(payload);
      } else if (transferFunctions.MATIC.includes(symbol)) {
        res = await transferTokenMatic(payload);
      }
       else if (transferFunctions.BNB.includes(symbol)) {
        res = await transferTokenBNB(payload);
      } 
       else if (transferFunctions.SOL.includes(symbol)) {
        res = await transferSolTokenToMasterWallet(payload);
      } 
       else if (transferFunctions.SOLANA.includes(symbol)) {
        res = await transferSolBalanceIntoMasterWallet(payload);
      } 
      else {
        throw new Error("Invalid symbol or transferType");
      }

      if (res?.data?.status === 200) {
        toast.success(res.data.Message);
        reFetch()
        hide();
      } else {
        throw new Error("Transfer failed");
      }
    } catch (error) {
      toast.error(error?.response?.data.Message);
      console.error("Error occurred:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Modal
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            <b>Transfer Balance {symbol} </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div
              className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div className="flex gap-2">
                <div className="flex justify-start w-full flex-col ">
                  <label> Amount in {symbol}</label>
                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      value={amount}
                      className="form-control "
                      placeholder="Enter Amount"
                      onChange={(e) => setAmount(e.target.value)}
                    />
                  </InputDiv>
                </div>
              </div>
              <div className=" flex w-full gap-4 justify-center items-center">
                <button
                  className="text-white p-1 px-4 py-2 rounded-md bg-success "
                  onClick={handleConfirm}
                >
                  Confirm
                </button>
                <button
                  className="text-white p-1 px-4 py-2 rounded-md bg-danger "
                  onClick={hide}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default TransferBalanceModal;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

import React, { useState,useEffect } from 'react';
import { Collapse, Badge  } from 'react-bootstrap';
import NotificationsIcon from 'mdi-react/NotificationsIcon';
import { TopbarBack, TopbarButton } from './BasicTopbarComponents';
import moment from 'moment';
import {
  TopbarCollapse,
  TopbarCollapseButton,
  TopbarCollapseContent,
  TopbarCollapseDate,
  TopbarCollapseImageWrap,
  TopbarCollapseItem,
  TopbarCollapseLink,
  TopbarCollapseMessage,
  TopbarCollapseName,
  TopbarCollapseTitle,
  TopbarCollapseTitleWrap,
} from './CollapseTopbarComponents';
import {getNotification,markAllAsRead} from '../../../../utils/services/user.service'
import './topbar.css'
const notifications = [
  {
    id: 0,
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava.png`,
    name: 'Cristopher Changer',
    message: ' has started a new project',
    date: '09:02',
  },
  {
    id: 1,
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava2.png`,
    name: 'Sveta Narry',
    message: ' has closed a project',
    date: '09:00',
  },
  {
    id: 2,
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava3.png`,
    name: 'Lory McQueen',
    message: ' has started a new project as a Project Managert',
    date: '08:43',
  },
  {
    id: 3,
    ava: `${process.env.PUBLIC_URL}/img/topbar/ava2.png`,
    name: 'Cristopher Changer',
    message: ' has closed a project',
    date: '08:43',
  },
];

const TopbarNotification = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [Notifications, setNotifications] = useState([]);
  const [unreadNotifications, setUnreadNotifications] = useState([...Notifications]);


//console.log("unreadNotifications",unreadNotifications)
//console.log("Notifications",Notifications)

useEffect(() => {
  const notifications = async () => {
    try {
      const payload = {
        type: "admin",
        limit: 10,
        page_number: 1,
      };
      const response = await getNotification(payload); // Assuming getNotification is an asynchronous function
      //console.log("Notifications  response", response);
      if (response.data.Notifications) {
        setNotifications(response.data.Notifications);
        //console.log("setNotifications", response.data.Notifications);
      } else {
        // Handle the case when there are no notifications
      }
    } catch (error) {
      // Handle any errors that occur during the API request
    }
  };

  notifications();
}, []);
const notifications = async () => {
  try {
    const payload = {
      type: "admin",
      limit: 10,
      page_number: 1,
    };
    const response = await getNotification(payload); // Assuming getNotification is an asynchronous function
    //console.log("Notifications  response", response);
    if (response.data.Notifications) {
      setNotifications(response.data.Notifications);
      //console.log("setNotifications", response.data.Notifications);
    } else {
      // Handle the case when there are no notifications
    }
  } catch (error) {
    // Handle any errors that occur during the API request
  }
};
  const collapseNotification = () => {
    setIsOpen(!isOpen);
  };
  const markAsRead = (id) => {
    // Find the notification by its ID and mark it as read
    const updatedNotifications = unreadNotifications.map((notification) =>
      notification.id === id ? { ...notification, isRead: true } : notification
    );

    setUnreadNotifications(updatedNotifications);
  };

  const markAllRead = async () => {
    try {
      const payload = {
        type: "admin",
      }
      const response = await markAllAsRead(payload); // Add 'await' here
      if (response.data.success === true) { // Replace '====' with '==='
        notifications();
      } else {
        //console.log("response", response);
      }
      //console.log("response", response);
    } catch (error) {
      // Handle errors here
      console.error(error);
    }
  }
  const unreadCount = Notifications.filter((notification) => !notification.is_read).length;

  //console.log("unreadCount",unreadCount)
  return (
    <TopbarCollapse>
    <TopbarButton type="button"  aria-expanded={isOpen}  onClick={() => setIsOpen(!isOpen)}>
      <NotificationsIcon />
      {unreadCount > 0 && (
        <h5 className="leading-9">
          <Badge bg="danger rounded-md">{unreadCount}</Badge>
          </h5>
        )}
    </TopbarButton>
    {isOpen && (
      <TopbarBack
        aria-label="topbar__back"
        type="button"
        onClick={collapseNotification}
      />
    )}
    <Collapse in={isOpen}>
      <TopbarCollapseContent className="overflow-y-auto max-h-32">
        <TopbarCollapseTitleWrap>
          <TopbarCollapseTitle>Notifications</TopbarCollapseTitle>
          <TopbarCollapseButton type="button" onClick={markAllRead}>Mark all as read</TopbarCollapseButton>
        </TopbarCollapseTitleWrap>
        <div className="overflow-y-auto max-h-64">
        {Notifications.map((notification) => (
          <TopbarCollapseItem key={notification.id} className={`flex items-center py-2 ${notification.is_read ? 'bg-gray-600' : 'bg-gray-700'}`}>
            <TopbarCollapseImageWrap>
              <img src={`${process.env.PUBLIC_URL}/img/11.png`} alt="" />
            </TopbarCollapseImageWrap>
            <TopbarCollapseMessage>
              <TopbarCollapseName>{notification?.user_detail[0]?.name?.length > 0 ? notification?.user_detail[0]?.name : null}</TopbarCollapseName>
              <span className="text-white">{notification?.notification_message}</span>
              {/* {notification.is_read.toString()} */}

            </TopbarCollapseMessage>
            <TopbarCollapseDate>
            {!notification?.is_read && (
      <span className="unread-tag text-white align-right">New</span>
    )}
    {/* {moment(notification.createdAt).format(' h:mm:ss a')} */}
    </TopbarCollapseDate>
          </TopbarCollapseItem>
        ))}
        </div>
        {/* <TopbarCollapseLink
          to="/online_marketing_dashboard"
          onClick={collapseNotification}
        >
          See all notifications
        </TopbarCollapseLink> */}
      </TopbarCollapseContent>
    </Collapse>
  </TopbarCollapse>
  );
};

export default TopbarNotification;

import Modal from "react-bootstrap/Modal";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import styled from "styled-components";
import { useEffect, useState } from "react";
import {
    createOtcMarketPlace,
    create_otcMarketPlace_general,
    getAllTokens,
    getApprovers,
} from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import DefaultControls from "../../Form/ReactFinalForm/CheckFormControls/components/DefaultControls";
import MultiTokenCheck from "../../Form/ReactFinalForm/CheckFormControls/components/MultiTokenCheck";
import { TaxSymbol } from "../../../redux/constants/constans";

const CreateOtcMarketplaceGeneralModal = ({ show, hide, reFetch }) => {
    const [searchUserId, setSearchUserId] = useState([]);
    const [inputId, setInputId] = useState("");
    const [userName, setUserName] = useState("");
    const [searchLoad, setsearchLoad] = useState(false);
    const [marketplaceName, setMarketplaceName] = useState("");
    const [buyPackageLimit, setBuyPackageLimit] = useState("");
    const [pool1, setPool1] = useState("");
    const [pool2, setPool2] = useState("");
    const [fromContractAddress, setFromContractAddress] = useState("");
    const [toContractAddress, setToContractAddress] = useState("");
    const [swapingStatus, setSwapingStatus] = useState("active");
    const [buySellStatus, setBuySellStatus] = useState("active");
    const [isLoading, setIsLoading] = useState(false);
    const [chain_id, setChain_id] = useState(null);
    const [swapFee, setSwapFee] = useState(null);
    const [buyFee, setBuyFee] = useState(null);
    const [transferFee, setTransfer] = useState(null);
    const [swapFeeType, setSwapFeeType] = useState("");
    const [transferFeeType, setTransferFeeType] = useState("");
    const [buyfeeType, setBuyFeeType] = useState("");
    const [buyStatus, setBuyStatus] = useState("active");
    const [transferStatus, setTransferStatus] = useState("active");
    const [packageChoice, setPackageChoice] = useState("active");
    const [refferenceCode, setRefferenceCode] = useState("");
    const [taxSymbol, setTaxSymbol] = useState(null);
    const [allTokens, setAllTokens] = useState([]);
    const [allTokensPool2, setAllTokensPool2] = useState([]);

    const initialTokenData = allTokens.map((item) => ({
        symbol: null,
        price: null,
    }));

    const [tokenData, setTokenData] = useState(initialTokenData);

    const handleSymbolChange = (idx, event) => {
        const { checked, value } = event.target;
        setTokenData((prevState) => {
            if (checked) {
                const newData = [...prevState];
                newData[idx] = { symbol: value, price: newData[idx]?.price || "" };
                return newData;
            } else {
                const newData = [...prevState];
                newData[idx] = null; // Mark the object as null
                return newData.filter((item) => item !== null); // Filter out null objects
            }
        });
    };

    const handlePriceChange = (idx, event) => {
        const { value } = event.target;
        setTokenData((prevState) => {
            const newData = [...prevState];
            newData[idx] = { ...newData[idx], price: value };
            return newData;
        });
    };

    const handleInputChange = (name) => {
        setsearchLoad(true);
        if (name.length >= 2) {
            getApprovers({ name })
                .then((res) => {
                    setsearchLoad(false);
                    const arr = res.data.user.map((i) => ({
                        id: i._id,
                        address: i.address,
                        value: i.name,
                        label: i.name,
                        sol_address: i?.sol_address,
                    }));

                    setsearchLoad(false);

                    // Case-insensitive comparison
                    const matchingOptions = arr.filter(
                        (option) => option.value.toLowerCase() === name.toLowerCase()
                    );

                    setSearchUserId(matchingOptions);
                })
                .catch((error) => {
                    setsearchLoad(false);
                    // setstatusCode(400);

                    if (error.message === "Network Error") {
                        // setMsg(`Please Check Your Internet Connection \n Something went Wrong!!${error?.message}`);
                    } else {
                    }
                });
        } else {
            setSearchUserId([]); // Clear options when input length is less than 2
        }

        setsearchLoad(false);
    };
    const chainIds = [
        { value: 1, label: "ETHEREUM" },
        { value: 56, label: "BSC" },
        { value: 137, label: "MATIC" },
    ];
    const types = [
        { value: "percentage", label: "Percentage" },
        { value: "dollar", label: "USDT" },
    ];
    const packageChoiceOptions = [
        { value: "virtual_transfer", label: "Virtual Transfer" },
        { value: "open_contract", label: "Open Contract" },
    ];

    const newArray = tokenData.filter(
        (item) => item !== null && item !== undefined
    );
    console.log("🚀 ~ CreateOtcMarketplaceGeneralModal ~ newArray:", newArray);

    const CreateNewOtcMarketplace = async () => {
        try {
            setIsLoading(true);
            // if (
            //     !marketplaceName ||
            //     // !inputId ||
            //     !buySellStatus ||
            //     !swapFeeType ||
            //     !transferFeeType ||
            //     !buyfeeType ||
            //     !buyStatus ||
            //     !taxSymbol ||
            //     !buyPackageLimit

            // ) {
            //     // If any required field is missing,  show error toast
            //     toast.error("Please fill in all required fields.");
            //     setIsLoading(false);
            //     return;
            // }
            const poolVal = [pool1, pool2].join("-");
            const payload = {
                market_place_name: marketplaceName,
                // swapping_status: swapingStatus,
                buy_fee: buyFee,
                transfer_fee: transferFee,
                // swap_fee: swapFee,
                // swap_fee_type: swapFeeType,
                transfer_fee_type: transferFeeType,
                buy_fee_type: buyfeeType,
                transfer_status: transferStatus,
                buy_status: buyStatus,
                tax_token_symbol: taxSymbol,
                buy_package_user_limit: buyPackageLimit,

                "otc_type": "general",
                buy_package_user_limit: buyPackageLimit,
            };
            const res = await create_otcMarketPlace_general(payload);
            toast.success(res.data.Message);
            setIsLoading(false);
            reFetch();
            hide();
        } catch (error) {
            console.log("🚀 ~ CreateNewOtcMarketplace ~ error:", error);
            // Handle error
            toast.error(error?.response?.data.Message || error);
            setIsLoading(false);
        }
    };

    const fetchAllTokens = async () => {
        const payload = {
            chain_id: chain_id,
        };
        const res = await getAllTokens();
        setAllTokens(res.data.data);
        const filteredData = res.data.data.filter((item) => item.label !== "BTC");
        setAllTokensPool2(filteredData);
    };
    useEffect(() => {
        fetchAllTokens();
    }, [chain_id]);

    return (
        <>
            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={hide}
            >
                <div
                    className="loader-overlay"
                    style={{
                        display: isLoading ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        zIndex: 999,
                    }}
                >
                    <Ring size={65} color="#00897B" />
                </div>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                        <b>Create New General OTC Marketplace </b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container padding-bottom-3x p-0">
                        <div
                            className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
                            style={{ boxShadow: "none", fontFamily: "sans-serif" }}
                        >
                            <div className=" flex flex-wrap justify-start gap-4 items-start">
                                <div className="flex flex-col col-12 col-md-3 ">
                                    <label>Marketplace Name</label>
                                    <InputDiv>
                                        <ChatSearchInput
                                            type="text"
                                            value={marketplaceName}
                                            className="form-control "
                                            placeholder="Marketplace Name"
                                            onChange={(e) => setMarketplaceName(e.target.value)}
                                        />
                                    </InputDiv>
                                </div>

                                <div className="flex flex-col col-12 col-md-3 ">
                                    <label>Buy Fee</label>

                                    <InputDiv>
                                        <ChatSearchInput
                                            type="text"
                                            value={buyFee}
                                            className="form-control "
                                            placeholder="Buy Fee"
                                            onChange={(e) => setBuyFee(e.target.value)}
                                        />
                                    </InputDiv>
                                </div>
                                <div className="flex flex-col col-12 col-md-3 ">
                                    <label>Transfer Fee</label>

                                    <InputDiv>
                                        <ChatSearchInput
                                            type="text"
                                            value={transferFee}
                                            className="form-control "
                                            placeholder="Transfer Fee"
                                            onChange={(e) => setTransfer(e.target.value)}
                                        />
                                    </InputDiv>
                                </div>
                                {/* <div className="flex flex-col col-12 col-md-3 ">
                                    <label>Swap Fee</label>

                                    <InputDiv>
                                        <ChatSearchInput
                                            type="text"
                                            value={swapFee}
                                            className="form-control "
                                            placeholder="Swap Fee"
                                            onChange={(e) => setSwapFee(e.target.value)}
                                        />
                                    </InputDiv>
                                </div> */}

                                <div className="flex flex-col col-12 col-md-3 ">
                                    <label>Buy Package Limit</label>

                                    <InputDiv>
                                        <ChatSearchInput
                                            type="number"
                                            value={buyPackageLimit}
                                            className="form-control "
                                            placeholder="Enter Buy package limit"
                                            onChange={(e) => setBuyPackageLimit(e.target.value)}
                                        />
                                    </InputDiv>
                                </div>
{/* 
                                <div className="col-12 col-md-3 ">
                                    <label>Swap Fee Type</label>
                                    <InputDiv>
                                        <DropdownDiv className="mb-0">
                                            <CustomSelect
                                                isLoading={searchLoad}
                                                name="receiver"
                                                placeholder={`Swap Fee Type`}
                                                onChange={(value) => setSwapFeeType(value.value)}
                                                value={swapFeeType}
                                                options={types}
                                                isSearchable
                                            />
                                        </DropdownDiv>
                                    </InputDiv>
                                </div> */}

                                <div className="col-12 col-md-3 ">
                                    <label>Buy Fee Type</label>
                                    <InputDiv>
                                        <DropdownDiv className="mb-0">
                                            <CustomSelect
                                                isLoading={searchLoad}
                                                name="receiver"
                                                placeholder={`Buy Fee Type`}
                                                onChange={(value) => setBuyFeeType(value.value)}
                                                value={buyfeeType}
                                                options={types}
                                                isSearchable
                                            />
                                        </DropdownDiv>
                                    </InputDiv>
                                </div>
                                <div className="col-12 col-md-3 ">
                                    <label>Transfer Fee Type</label>

                                    <InputDiv>
                                        <DropdownDiv className="mb-0">
                                            <CustomSelect
                                                isLoading={searchLoad}
                                                name="receiver"
                                                placeholder={`Transfer Fee Type`}
                                                onChange={(value) => setTransferFeeType(value.value)}
                                                value={transferFeeType}
                                                options={types}
                                                isSearchable
                                            />
                                        </DropdownDiv>
                                    </InputDiv>
                                </div>

                                <div className="col-12 col-md-3 ">
                                    <label>Select tax symbol</label>

                                    <InputDiv>
                                        <DropdownDiv className="mb-0">
                                            <CustomSelect
                                                isLoading={searchLoad}
                                                name="receiver"
                                                placeholder={`${taxSymbol || "Select Tax Symbol"}`}
                                                onChange={(value) => setTaxSymbol(value.label)}
                                                value={taxSymbol}
                                                options={allTokens}
                                                isSearchable
                                            />
                                        </DropdownDiv>
                                    </InputDiv>
                                </div>

                                <div className=" md:flex gap-4">
                                    {/* <div className=" inline-block text-black col-12 col-md-4 ">
                                        <label>Swaping Status</label>
                                        <select
                                            value={swapingStatus}
                                            onChange={(event) => setSwapingStatus(event.target.value)}
                                            className="appearance-none w-full bg-white text-stone-950 border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                                        >
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div> */}
                                    <div className=" inline-block text-black col-12 col-md-4 ">
                                        <label>Buy Status</label>
                                        <select
                                            value={buyStatus}
                                            onChange={(event) => setBuyStatus(event.target.value)}
                                            className="appearance-none w-full bg-white text-stone-950 border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                                        >
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                    <div className=" inline-block text-black col-12 col-md-4 ">
                                        <label>Transfer Status</label>
                                        <select
                                            value={transferStatus}
                                            onChange={(event) =>
                                                setTransferStatus(event.target.value)
                                            }
                                            className="appearance-none w-full bg-white text-stone-950 border border-gray-300 hover:border-gray-400 px-4 py-2 pr-8 rounded leading-tight focus:outline-none focus:border-blue-500"
                                        >
                                            <option value="active">Active</option>
                                            <option value="inactive">Inactive</option>
                                        </select>
                                    </div>
                                </div>


                            </div>
                            <div className=" flex justify-center items-center">
                                <button
                                    className="text-white p-1 px-2 py-2 rounded-md bg-primary "
                                    onClick={CreateNewOtcMarketplace}
                                >
                                    Create General OTC Marketplace
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default CreateOtcMarketplaceGeneralModal;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import RecoveryPhraseModal from "./WalletModal";
import EditPhraseModal from "./EditWalletModal";
import {
  getWallet,
  editWallet,
  addNewWallet,
  sendOtp,
  varifyOtp_wallet,
  getUserInfo1,
} from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import Tfaaa from "../../Account/MyAccount/Tfaaa";
import { Modal } from "react-bootstrap";
import NewEditModal from "./NewEditModal";
import styled from "styled-components";
import { Button } from "@/shared/components/Button";
import AddNewWallet from "./AddNewWallet";


const CustodiyWallet = () => {
  const { t } = useTranslation("common");
  const [walletExists, setWalletExists] = useState(false);
  const [walletAddress, setwalletAddress] = useState("");
  const [PrivateKey, setPrivateKey] = useState("");
  const [walletAddressSol, setwalletAddressSol] = useState("");
  const [PrivateKeySol, setPrivateKeySol] = useState("");
  const [ID, setID] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [otp, setOtp] = useState("");
  const [isOtpRequired, setIsOtpRequired] = useState(false);
  const [countdown, setCountdown] = useState(120); // 120 seconds = 2 minutes
  const [intervalId, setIntervalId] = useState(null);
  const [tfaUrl, setTfaUrl] = useState(false);
  const [tfaEmail, setTfaEmail] = useState("");
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;
  const [showTfaModal, setShowTfaModal] = useState(false);
  const [showNewEditModal, setShowNewEditModal] = useState(false);
  const [showAddNewWallet, setShowAddNewWallet] = useState(false);
  const [walletData, setwalletData] = useState([]);

  useEffect(() => {
    checkWalletExistence();
  }, []);

  const checkWalletExistence = async () => {
    try {
      setIsLoading(true);
      const wallet = await getWallet();
      setwalletData(wallet.data.wallets)
      let ethData = {};
      let solData = {};
      wallet.data.wallets.forEach(walletItem => {
        if (walletItem.type === "ETH") {
          ethData = {
            id: walletItem._id,
            privateKey: walletItem.privateKey,
            address: walletItem.address
          };
        } else if (walletItem.type === "SOLANA") {
          solData = {
            privateKey: walletItem.privateKey,
            address: walletItem.address
          };
        }
      });
      setID(ethData.id);
      setwalletAddress(ethData.address);
      setwalletAddressSol(solData.address);
      setWalletExists(!!wallet);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error checking wallet existence:", error);
    }
  };

  const startCountdownTimer = () => {
    setCountdown(120);

    const id = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown > 0) {
          return prevCountdown - 1;
        } else {
          clearInterval(id);
          setIsOtpRequired(false);
          return 0;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalId);
    };
  }, [intervalId]);

  const renderTfaModal = () => {
    return (
      <Modal
        show={showTfaModal}
        onHide={() => setShowTfaModal(false)}
        centered
        className="dark:bg-gray-800 "
      >
        <Modal.Header
          closeButton
          className="dark:border-gray-700 bg-dark text-white"
        >
          <Modal.Title>TFA Modal</Modal.Title>
        </Modal.Header>
        <Modal.Body className="dark:text-white bg-dark">
          <Tfaaa
            tfaEmail={tfaEmail}
            tfaUrl={tfaUrl}
            onDataFromChild={handleDataFromChild}
          />
        </Modal.Body>
        <Modal.Footer className="dark:bg-gray-800 bg-dark dark:border-t dark:border-gray-700">
          <Button variant="secondary" onClick={() => setShowTfaModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const handleDataFromChild = (data) => {
    sessionStorage.setItem("data", data);
    if (data === true) {
      handleCreateWallet();
      setShowTfaModal(false);
      // setOtpbtn(false);
      setTfaUrl(false);
      // setIsModalOpen(false);
      // setTFA(false);
    } else {
      // setOtpbtn(false);
      setTfaUrl(true);
      setTfaEmail(userData.data.user.email);
      // setIsModalOpen(true);
    }
  };

  useEffect(() => {
    const storedData = sessionStorage.getItem("data");
    if (storedData === "true") {
      handleDataFromChild(false);
    }
  }, []);

  const handleCreateWallet = async () => {
    try {
      // await checkWalletExistence();

      if (walletExists) {
        setIsLoading(true);

        if (otp === "" && !isOtpRequired) {
          const verifyResponse = await sendOtp();

          if (verifyResponse.data.status === 200) {
            toast.success(verifyResponse.data.message);
            setIsOtpRequired(true);
            setIsLoading(false);
            if (!intervalId) {
              startCountdownTimer();
            }
            setTimeout(() => {
              setIsOtpRequired(false);
              toast.error("Otp expired");
            }, 120000);
          }
        } else {
          const otpPayload = {
            email: usermail,
            otp: otp,
          };

          const verifyResponse = await varifyOtp_wallet(otpPayload);

          if (verifyResponse.data.status === "success") {
            const editPayload = {
              wallet_address: walletAddress,
              wallet_id: ID,
              private_key: PrivateKey,
            };

            await editWallet(editPayload);
            setOtp("");
            setIsOtpRequired(false);
            checkWalletExistence();
            setIsLoading(false);
            toast.success("Wallet added Successfully");
          } else {
            setIsOtpRequired(false);
            setOtp("");
            toast.error("Expired Code");
            toast.error(verifyResponse.data.message);
            console.error(
              "Error verifyResponse wallet:",
              verifyResponse.data.message
            );
          }
        }
      } else {
        setIsLoading(true);

        if (otp === "" && !isOtpRequired) {
          await sendOtp();
        } else {
          const otpPayload = {
            email: usermail,
            otp: otp,
          };

          const verifyResponse = await varifyOtp_wallet(otpPayload);

          if (verifyResponse.data.status === 200) {
            const addWalletPayload = {
              wallet_address: walletAddress,
              private_key: PrivateKey,
            };

            await addNewWallet(addWalletPayload);
            setIsOtpRequired(false);
            setOtp("");
            checkWalletExistence();
            setIsLoading(false);
            toast.success("Wallet added Successfully");
          } else {
            setIsOtpRequired(false);
            setOtp("");
            toast.error(verifyResponse.data.message);
            toast.error("Something Is Wrong");
          }
        }
      }
    } catch (error) {
      setIsOtpRequired(false);
      setIsLoading(false);
      setOtp("");
      toast.error("Something Is Wrong");
      console.error("Error checking wallet existence:", error);
    }
  };

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Container>
        <Row>
          <Col md={12}>
            <h3 className="page-title">{t("CustodiyWallet")}</h3>
          </Col>
        </Row>

        <div className="flex flex-col md:flex-row justify-end ">
          {!walletAddress && (
            <FormButton
              type="submit"
              variant="contain-success"
              className="flex items-center gap-2 mr-2 float-right shadow-none text-capitalize"
              onClick={() => setShowAddNewWallet(true)}
            >
              Add ETH Wallet
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </FormButton>
          )}
          {!walletAddressSol && (
            <FormButton
              type="submit"
              variant="outline-success"
              className="flex items-center gap-2 mr-2 float-right shadow-none text-capitalize"
              onClick={() => setShowAddNewWallet(true)}
            >
              Add SOLANA Wallet
              <i className="fa fa-plus-circle" aria-hidden="true"></i>
            </FormButton>
          )}
        </div>

        <Row>
          <Col
            
            className="d-flex justify-content-center align-items-center"
            style={{ minHeight: "30vh" }}
          >
           {
            walletData.length > 0 ? (
              <table className="tbody table w-full">
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Wallet Address</th>
                  <th>Private Key</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {
                  walletData[0] && <tr>
                    <td>
                      {walletData[0]?.type}
                    </td>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <div className="">
                            {walletData[0]?.address?.slice(0, 27) +
                              "..." +
                              walletData[0]?.address?.slice(-7)}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      {walletData[0]?.privateKey?.slice(0, 27) + "..." + walletData[0]?.privateKey?.slice(-7)}
                    </td>
                    <td>
                      <div className="flex gap-2">
                        <button
                          variant="primary"
                          className="bg-warning text-[black]  w-[100%] whitespace-nowrap hover:-gray-400 px-4 py-2 rounded leading-tight focus:outline-none focus:border-blue-500"
                          block
                          onClick={() => setShowNewEditModal(true)}
                        >
                          {"Edit Wallet"}
                        </button>
                      </div>
                    </td>
                  </tr>
                }
                {
                  walletData[1] && <tr>
                    <td>
                      {walletData[1]?.type}
                    </td>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <div className="">
                            {walletData[1]?.address?.slice(0, 27) +
                              "..." +
                              walletData[1]?.address?.slice(-7)}
                          </div>
                        </div>
                      </div>
                    </td>

                    <td>
                      {walletData[1]?.privateKey?.slice(0, 27) + "..." + walletData[1]?.privateKey?.slice(-7)}
                    </td>
                    <td>
                      <div className="flex gap-2">
                        <button
                          variant="primary"
                          className="bg-warning text-[black]  w-[100%] whitespace-nowrap hover:-gray-400 px-4 py-2 rounded leading-tight focus:outline-none focus:border-blue-500"
                          block
                          onClick={() => setShowNewEditModal(true)}
                        >
                          {"Edit Wallet"}
                        </button>
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
            )
            : 
            <h3>No wallet found!</h3>
           }
          </Col>
        </Row>
      </Container>

      {showTfaModal && renderTfaModal()}
      {showNewEditModal && (
        <NewEditModal
          show={showNewEditModal}
          isOtpRequired={isOtpRequired}
          hide={() => setShowNewEditModal(false)}
          walletAddress={walletAddress}
          privateKey={PrivateKey}
        />
      )}
      {showAddNewWallet && (
        <AddNewWallet
          show={showAddNewWallet}
          isOtpRequired={isOtpRequired}
          hide={() => setShowAddNewWallet(false)}
        />
      )}
    </>
  );
};

export default CustodiyWallet;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
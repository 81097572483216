/* eslint-disable max-len */
/* eslint-disable no-undef */
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import ResetPasswordForm from '@/shared/components/account/reset_password/ResetPasswordForm';
import {
  AccountCard,
  AccountContent,
  AccountHaveAccount,
  AccountWrap,
 AccountButton, 
} from '@/shared/components/account/AccountElements';

import {
  colorAccent,
 
} from '@/utils/palette';
import {
 clearMsg, forgotPasword, resetPassword, varifyPasswordotp, 
} from '../../../redux/actions/authActions';
import SimpleModal from '../../../shared/components/Modal/SimpleModal';

const ResetPassword = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isOpen, setisOpen] = useState(false);
  const [modalOpen, setmodalOpen] = useState(false);

  const [newPass, setnewPass] = useState(false);
  const [newOtp, setnewOtp] = useState(false);

  const history = useHistory();
  const dispatch = useDispatch();
  const { message } = useSelector(state => state.message);
  const { isRegister } = useSelector(state => state.user);

  const SendEmail = (user) => {
    setIsLoading(true);
    if (!user.otp) {
      dispatch(forgotPasword(user.email));
      setIsLoading(false);
      setnewOtp(true);
      setTimeout(() => {
        dispatch(clearMsg());
      }, 3000);
    } else if (!user.password && !user.confirmPassword) {
      setIsLoading(true);
      dispatch(varifyPasswordotp(user.email, user.otp)).then(() => {
        setIsLoading(false);
        setnewPass(true);
        setisOpen(true);
        setTimeout(() => {
          dispatch(clearMsg());
        }, 3000);
      })
      .catch(() => {
        setIsLoading(false);
        setnewPass(false);
        setisOpen(false);
        setTimeout(() => {
          dispatch(clearMsg());
        }, 3000);
      });
    } else {
      setIsLoading(true);
      dispatch(resetPassword(user.email, user.otp, user.password, user.confirmPassword)).then(() => {
        setIsLoading(false);
        setnewPass(false);
        setnewOtp(false);
        setmodalOpen(true);
        setTimeout(() => {
          dispatch(clearMsg());
        }, 3000);
      }).catch(() => {
        setIsLoading(false);
        setnewPass(false);
        setnewOtp(false);
        setmodalOpen(false);
        setTimeout(() => {
          dispatch(clearMsg());
        }, 3000);
      });
    }
  };
  return (
    <>
      <AccountWrap>
        <AccountContent>
          <AccountCard>
            <h4
              className="subhead"
              style={{ fontSize: '18px', fontWeight: '700', textAlign: 'center' }}
            >Reset Password
            </h4>
            <ResetPasswordForm
              {...props}
              onSubmit={SendEmail}
              name="email"
              errorMsg={message}
              isRegister={isRegister}
              isLoading={isLoading}
              newPass={newPass}
              newOtp={newOtp}
            />
            <AccountHaveAccount>
              <p>Remember your password?  <NavLink to="/login">Login</NavLink></p>
            </AccountHaveAccount>
          </AccountCard>
        </AccountContent>
      </AccountWrap>
      <SimpleModal
        isActive={isOpen || modalOpen}
        onClose={() => {
 setisOpen(false);
      setmodalOpen(false); 
}}
      >
        <ModalDiv>
          <span className="lnr lnr-checkmark-circle" style={{ color: colorAccent, fontSize: '48px', marginBottom: '1rem' }} />
          <h4
            className="subhead"
            style={{
 padding: '5px', fontSize: '18px', fontWeight: '700', textAlign: 'center', marginBottom: '1rem', 
}}
          >{message}
          </h4>
          <AccountButton
            variant="outline-success"
            style={{
              padding: '5px',
             }}
            onClick={() => {
              if (isOpen) {
        setisOpen(false); 
        dispatch(clearMsg());
      }
        if (modalOpen) {
          setmodalOpen(false);
          history.push('/login');
          dispatch(clearMsg());
        }
        }}  
          >    
            ok
          </AccountButton>
        </ModalDiv>
      </SimpleModal>
    </>
   
  );
};

export default ResetPassword;
const ModalDiv = styled.div`
height: 12rem;
justify-content: space-between;
display: flex;
align-items: center;
flex-direction: column;
`;

import React, { useEffect, useState } from 'react';
import { addNewCategory_admin,getCategory_admin,deactivaCategory,getUserInfo1, } from '../../../utils/services/user.service'; 
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Ring } from '@uiball/loaders';
import Modal from 'react-modal'
import { Row, Col, Button, Container, Form } from "react-bootstrap";
import NewCategories from "./NewCategories";
import { Modal as BootstrapModal } from "react-bootstrap";
import EditCategories from "./EditCategories";
import TitleCard from "../AddSafebox/TitleCard";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import Switch from "@mui/material/Switch";
// import Modal from 'react-modal'
import CategoryModal from './CategoryModal'
import moment from "moment";
import Tfaaa from "../../Account/MyAccount/Tfaaa";
import './i.css';
const Create = () => {
  const itemsPerPage = 10;
    const [isModalOpen, setIsModalOpen] = useState(false);
  
    const [isLoading, setIsLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(1);
    const [categories, setCategories] = useState([]);
    const [Subcategorie, setSubCategorie] = useState([]);
    // const [isLoading, setIsLoading] = useState(false);
    const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
    const [categoryIdToUpdate, setCategoryIdToUpdate] = useState(null);
    const [newStatusToUpdate, setNewStatusToUpdate] = useState();
    const [selectedCategory, setselectedCategory] = useState(null);
    const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false);
    const [isEditModalOpen, setIsEditModalOpen] = useState(false);
    const [editingCategory, setEditingCategory] = useState(null);
    const [TFA, setTFA] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;
  const [tfaUrl, setTfaUrl] = useState(false);
  const [tfaEmail, setTfaEmail] = useState("");
  const [showTfaModal, setShowTfaModal] = useState(false);
  const [isEditMopdL, setisEditMopdL] = useState(false);
  const [isActiveMopdL, setisActiveMopdL] = useState(false);
  // console.log("categoryName",categoryName)
  // console.log("categories",categories)
  // console.log("editingCategory",editingCategory)
  // console.log("subCategories",subCategories)
  // console.log("newSubCategory",newSubCategory)
  // console.log("newCustomDetail",newCustomDetail)
  // console.log("customDetails",customDetails)
  // console.log("selectedFile",selectedFile)
  
  const renderTfaModal = () => {
    return (
      <BootstrapModal
        show={showTfaModal}
        onHide={() => setShowTfaModal(false)}
        centered
        className="dark:bg-gray-800"
      >
        <BootstrapModal.Header
          closeButton
          className="dark:border-gray-700 bg-dark text-white"
        >
          <BootstrapModal.Title>TFA Modal</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body className="dark:text-white bg-dark">
          <Tfaaa
            tfaEmail={tfaEmail}
            tfaUrl={tfaUrl}
            onDataFromChild={handleDataFromChild}
          />
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="dark:bg-gray-800 bg-dark dark:border-t dark:border-gray-700">
          <Button variant="secondary" onClick={() => setShowTfaModal(false)}>
            Close
          </Button>
        </BootstrapModal.Footer>
      </BootstrapModal>
    );
  };

  const handleDataFromChild = (data) => {
    sessionStorage.setItem("data", data);
    if (data === true) {
      setShowTfaModal(false);
      if(isEditMopdL === true){
        setIsEditModalOpen(true);
      }else if(isActiveMopdL === true){
        setIsConfirmModalOpen(true);
      }else{
        setIsModalOpen(true);
      }
      // Additional lines, if needed
      // setIsModalOpen(false);
      // setTFA(false);
    } else {
      // setOtpbtn(false);
      setTfaUrl(true);
      setTfaEmail(userData.data.user.email);
      // setIsModalOpen(true);
    }
  };


  useEffect(() => {
    const storedData = sessionStorage.getItem("data");
    if (storedData === "true") {
      handleDataFromChild(false);
    }
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        email: usermail,
      };
  
      try {
        const response = await getUserInfo1(requestData);
  
        if (response.status === 200) {
          setTFA(response.data.User.TFA);
        } else {
          console.error("getUserInfo1 failed:", response);
        }
      } catch (error) {
        console.error("Error getUserInfo1:", error);
      }
    };
  
    // Initial data fetch
    fetchData();
  
    // Set up interval to fetch data every second
    const intervalId = setInterval(() => {
      fetchData();
    }, 1000);
  
    // Cleanup function to clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, [usermail,TFA]);





  const handleEditButtonClick = (category) => {
    setEditingCategory(category);
    if(TFA){
      setTfaEmail(userData.data.user.email);
      setTfaUrl(true);
      setShowTfaModal(true);
      setisEditMopdL(true)
    }else{
      setIsEditModalOpen(true);
    }
   
  };
  
 
      const getCategorys= async () => {
        CloseModal()
        try {
          const payload ={
            status : "all",
            limit : itemsPerPage,
            page_number : currentPage
            }
          const response = await getCategory_admin(payload); 
          if (response.data.category) {
            setCategories(response.data.category);
            setSubCategorie(response.data.category.sub_categories)
            setIsLoading(false)
          } else {
            // console.log("category error");
          }
        } catch (error) {
          // console.error('Error fetching categories:', error);
        }
      };
    
    
   
    const openModal = () => {
      if(TFA){
        setTfaEmail(userData.data.user.email);
        setTfaUrl(true);
        setShowTfaModal(true);
      }else{
        setIsModalOpen(true);
      }

    };
  
  
    const closeModal = () => {
      setIsModalOpen(false);
    };



   // <------------------------------------------------------------------->
   useEffect(() => {
    const getCategorys= async () => {
      try {
        setIsLoading(true)
        const payload ={
          status : "all",
          limit : itemsPerPage,
          page_number : currentPage
          }
        const response = await getCategory_admin(payload); 
        // console.log('totalresponse',response)

        if (response.data.category) {
          setCategories(response.data.category);
          const totalCategories = response.data.count;
          // console.log('totalCategories',totalCategories)
          // console.log('totalresponse.data',response.data)
          
                const pages = Math.ceil(totalCategories / itemsPerPage);
          // console.log('pages',pages)
                
                setPageCount(pages);
                setIsLoading(false);
          setIsLoading(false)
        } else {
          setIsLoading(false)
          // console.log("category error");
        }
      } catch (error) {
        setIsLoading(false)
        // console.error('Error fetching categories:', error);
      }
    };
  
    getCategorys()
  }, [currentPage]);

 

  const handleToggleCategoryStatus = (categoryId, newStatus) => {
   
    setCategoryIdToUpdate(categoryId);
    setNewStatusToUpdate(newStatus);
    if (TFA) {
      setTfaEmail(userData.data.user.email);
      setTfaUrl(true);
      setShowTfaModal(true);
      setisActiveMopdL(true);
    } else {
      setIsConfirmModalOpen(true);
    }
    
  };
  const handleConfirmApiCall  = () => {
  // console.log('categoryId',categoryIdToUpdate)
  // console.log('newStatus',categoryIdToUpdate)
  
  setIsConfirmModalOpen(false);
  setCategoryIdToUpdate(categoryIdToUpdate);
  setNewStatusToUpdate(newStatusToUpdate);
    const updatedCategories = categories.map((category) => {
      if (category._id === categoryIdToUpdate) {
        return {
          ...category,
          category_status: newStatusToUpdate,
        };
      }
      return category;
    });
  
    setCategories(updatedCategories);
  
    
    const payload = {
      category_id: categoryIdToUpdate,
      status: newStatusToUpdate, 
    };
  
    
    setIsLoading(true)
    deactivaCategory(payload)
      .then((response) => {
  // console.log('response',response)
  toast.success(response.data.Message)
  setIsLoading(false)
      
      })
      .catch((error) => {
        setIsLoading(false)
       
      });
  };
  
  const handleClose = () => {
    setIsModalOpen(false);
  };
  const handleOpen = () => {
    setIsCategoryModalOpen(true);
  }
  
  // In your closeModal function
  const CloseModal = () => {
    setIsCategoryModalOpen(false);
    setIsModalOpen(false);
    setIsEditModalOpen(false)
  }
  
  const handleEyeButtonClick = (category) => {
    setselectedCategory(category)
    handleOpen()
  }
  return (
    <>

<div
        className="loader-overlay"
        style={{
    display: isLoading ? 'flex' : 'none', 
    justifyContent: 'center', 
    alignItems: 'center', 
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: 999,
  }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      
      <TitleCard title="" topMargin="">
  <h2 className='mb-2'>Existing Categories</h2>
      <div className='lg:flex lg:space-x-4 sm:block'>


  <div className='lg:flex lg:space-x-4 sm:w-full'>
    <div className="flex justify-end sm:w-full mb-4 lg:mb-0">
      <button
        onClick={openModal}
        className="w-full lg:w-auto bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
      >
        Create New Category
      </button>
    </div>
  </div>

</div>
      
      


 
<div className="overflow-x-auto w-full">
      {isLoading ? (
        <p>Loading...</p>
      ) : categories.length > 0 ? (
        <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow-md sm:rounded-lg">
          <table className="min-w-full table-auto text-white table-dark table-bordered">
            <thead>
              <tr>
                <th className="py-2 px-4">Category</th>
                <th className="py-2 px-4">Category Id</th>
                <th className="py-2 px-4">Created At</th>
                <th className="py-2 px-4">Action</th>
                <th className="py-2 px-4">Details</th>
                <th className="py-2 px-4">Edit</th>
              </tr>
            </thead>
            <tbody>
              {categories.map((category) => (
                <tr key={category._id}>
                  <td>
                    <div className="flex items-center">
                      <div className="avatar w-16 h-20 rounded-md mb-auto">
                        <img
                          src={category.image}
                          alt="Avatar"
                          className="rounded-md w-16 h-10"
                        />
                        <div className="w-16 h-20 ml-2">{category.category_name}</div>
                      </div>
                    </div>
                  </td>
                  <td>{category._id}</td>
                  <td>{moment(category.createdAt).format("DD MMMM YYYY")}</td>
                  <td>
                    <Switch
                      checked={category.category_status}
                      onChange={(event) => handleToggleCategoryStatus(category._id, event.target.checked)}
                      name={`toggle_category_status_${category._id}`}
                      color="success"
                      className='mb-3'
                    />
                  </td>
                  <td>
                    <button
                      className="text-lime-500 hover:underline mt-0.5 ml-2"
                      onClick={() => handleEyeButtonClick(category)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                        />
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                        />
                      </svg>
                    </button>
                  </td>
                  <td>
                    <button
                      className="text-blue-500 hover:underline mt-0.5 ml-2"
                      onClick={() => handleEditButtonClick(category)}
                    >
                      <FontAwesomeIcon icon={faPenToSquare} style={{ color: 'white' }} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <p>No data available.</p>
      )}
    </div>
  {/* );
}; */}
{pageCount > 1 && (
          <div className="flex items-center justify-center mt-4">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className={`flex items-center justify-center px-1 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-2.5 h-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </button>
            {/* Pagination links */}
            {pageCount <= 3 ? (
              Array.from({ length: pageCount }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                  className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                    currentPage === index + 1
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  }`}
                >
                  {index + 1}
                </button>
              ))
            ) : (
              <>
                {currentPage > 1 && (
                  <>
                    <button
                      onClick={() => setCurrentPage(1)}
                      className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                        currentPage === 1
                          ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                          : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                    >
                      1
                    </button>
                    {currentPage > 3 && <span className=" dots ">....</span>}
                  </>
                )}
                {currentPage > 2 && (
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                      currentPage === currentPage - 1
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                  >
                    {currentPage - 1}
                  </button>
                )}
                <button
                  className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                    currentPage === currentPage
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  }`}
                >
                  {currentPage}
                </button>
                {currentPage < pageCount - 1 && (
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                      currentPage === currentPage + 1
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                  >
                    {currentPage + 1}
                  </button>
                )}
                {currentPage < pageCount && (
                  <>
                    {currentPage < pageCount - 2 && (
                      <span className="dots">....</span>
                    )}
                    <button
                      onClick={() => setCurrentPage(pageCount)}
                      className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                        currentPage === pageCount
                          ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                          : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                    >
                      {pageCount}
                    </button>
                  </>
                )}
              </>
            )}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === pageCount}
              className={`flex items-center justify-center px-1 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-2.5 h-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </button>
          </div>
        )}

              

      </TitleCard>

      



      <Modal
  isOpen={isConfirmModalOpen}
  onRequestClose={handleClose}
  aria-labelledby="modal-title"
  aria-describedby="modal-description"
  className="custom-dialog ml-6 mt-52 rounded-lg p-4 flex justify-center"
  // className="w-fit"
  style={{
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    maxHeight: '80%',
    '@media (max-width: 768px)': {
      width: '90%',
    },
  }}
  
>
  <div className='bg-dark p-4 w-fit rounded-md mr-12'>
    <p className="font-semibold text-lg mb-4">
      Are you sure you want to change the Category Status?
    </p>
    <div className="flex justify-end">
      <button
        onClick={() => {
          setIsConfirmModalOpen(false);
          setCategoryIdToUpdate(null);
          setNewStatusToUpdate(false);
        }}
        className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
      >
        No
      </button>
      <button
        onClick={handleConfirmApiCall}
        className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
      >
        Yes
      </button>
    </div>
  </div>
</Modal>
      {selectedCategory && (
  <CategoryModal
  selectedCategory={selectedCategory}
  CloseModal={CloseModal}
    isCategoryModalOpen={isCategoryModalOpen} 
  />
)}
{isModalOpen && (
  <NewCategories
  getCategorys={getCategorys}
  CloseModal={CloseModal}
  isModalOpen={isModalOpen} 
  />
)}

{isEditModalOpen && (
  <EditCategories
  getCategorys={getCategorys}
  CloseModal={CloseModal}
  isEditModalOpen={isEditModalOpen} 
  editingCategory={editingCategory}
  />
)}
  {showTfaModal && renderTfaModal()}
    </>
  );
};

export default Create;

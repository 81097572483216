/* eslint-disable camelcase */
/* eslint-disable no-undef */
/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable react/jsx-indent */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { RxCross2 } from 'react-icons/rx';

import styled from 'styled-components';
import EyeIcon from 'mdi-react/EyeIcon';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import { Spinner, Modal as BootstrapModal } from 'react-bootstrap';
import Panel from '@/shared/components/Panel';
import { ButtonToolbar } from '@/shared/components/Button';
import {
  flexFlow, paddingLeft, paddingRight,
} from '@/utils/directions';


import ReactTableBase from '@/shared/components/table/ReactTableBase';
import {
  API_URL, approveContractOtp, getContractDetail, getContracts,
} from '../../../../../utils/services/user.service';
import {
  colorAccent,
  colorBackground,
  colorIcon,
  colorText,
  colorWhite,
} from '../../../../../utils/palette';
import ApproveOtpForm from './ApproveOtpForm';
import authHeader from '../../../../../utils/services/auth-header';


const header = [
  { accessor: 'name', Header: 'Name' },
  { accessor: 'hash', Header: 'Hash', disableGlobalFilter: true },
  { accessor: 'date', Header: 'Date' },
  { accessor: 'approver', Header: 'Approver' },
  { accessor: 'type', Header: 'Type' },
  { accessor: 'role', Header: 'Role' },
  { accessor: 'amount', Header: 'Amount' },
  { accessor: 'status', Header: 'Status' },
  { accessor: 'action', Header: 'Action', disableGlobalFilter: true },

];

const ContractTable = ({ title, viewAll, detailShow }) => {
  const [contract, setcontract] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getContractDetails = () => {
    setIsLoading(true);
    getContracts().then((res) => {
      setcontract(res.data.History.result);
      setIsLoading(false);
    }).catch(() => {
      setcontract([]);
      setIsLoading(false);
    });
  };
  useEffect(() => {
    getContractDetails();
  }, []);
  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noreferrer');
  };
  const [modal, setModal] = useState(false);
  const [detail, setDetail] = useState([]);
  const [isLoad, setIsLoad] = useState(false);
  const [otpModal, setotpModal] = useState(false);
  const [otpLoad, setOtpLoad] = useState(false);
  const [Msg, setMsg] = useState(undefined);
  const [approveId, setApproveId] = useState('');
  const [approveLoad, setApproveLoad] = useState(false);
  const [status, setstatus] = useState(400);
  const [req, setReq] = useState('');
  const [rejectExternal, setRejectExternal] = useState([]);

  const toggle = () => {
    setModal(prevState => !prevState);
  };
  const toggleOtp = () => {
    setotpModal(prevState => !prevState);
  };
  const getDetail = (id) => {
    setIsLoad(true);
    toggle();
    getContractDetail(id).then((res) => {
      setIsLoad(false);
      setDetail(res.data.result[0]);
    }).catch((error) => {
      setstatus(400);
      setIsLoad(false);
      setDetail(error.response.data.Message);
    });
  };
  const getOtp = (id, item) => {
    //console.log('==item', item);
    setRejectExternal(item);
    setOtpLoad(true);
    toggleOtp();
    setApproveId(id);
    approveContractOtp(id)
      .then(
        (res) => {
          setstatus(200);
          setOtpLoad(false);
          setMsg(res.data.message);
        },
        (error) => {
          const message = (error.response
            && error.response.data
            && error.response.data.message)
            || error.message
            || error.toString();
          setstatus(400);
          if (error.response.data.status === 400) {
            setOtpLoad(false);
            // //console.log('45sdd45====', error);
            setMsg(error.response.data.Error);
          } else {
            setOtpLoad(false);
            setMsg(message);
          }
        },
      )
      .catch((err) => {
        setstatus(400);
        setOtpLoad(false);
        setMsg(err.data.message);
      });
  };
  const verifyOtp = (values) => {
    setMsg(undefined);
    setApproveLoad(true);
    const id = approveId;
    const { otp } = values;
    const trasactionId = id;
    const approverWalletAddress = rejectExternal?.Approver?.address;
    axios.post(
      req === 'Approval' ? `${API_URL}approve_third_party_transaction` : req === 'External Approval' 
      ? `${API_URL}approve_third_party_transaction_external`
      : req === 'External Reject' 
      ? `${API_URL}reject_third_party_transaction_external`
      : `${API_URL}reject_third_party_transaction`,
      ['External Reject', 'External Approval'].includes(req) ? { trasactionId, approverWalletAddress, otp } : { id, otp },

      { headers: authHeader() },
    )
      .then(
        (res) => {
          setstatus(200);
          setApproveLoad(false);
          req === 'Approval' ? setMsg(res.data.response.Message) : setMsg(res.data.Message);
          getContractDetails();
          setTimeout(() => {
            setMsg(undefined);
            toggleOtp();
          }, 2000);
        },
        (error) => {
          const message = (error.response
            && error.response.data
            && error.response.data.message)
            || error.message
            || error.toString();
          setstatus(400);
          if (error.response.data.status === 400) {
            setApproveLoad(false);
            //console.log('=0t', error.response.data.Message, error.response.data.Message.length);
            setMsg(error.response.data.Message.length === undefined ? 'Undefined Message' : error.response.data.Message);
          } else {
            setApproveLoad(false);
            setMsg(message);
          }
        },
      );
  };
  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: true,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    isPagination: true,
  };
  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    isPagination: false,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: 'Search...',
    withPagination: true,
  };
  const data = [];
  contract.map(item => data.push({
    name: item.type === 'contract' ? `Contract@ ${item.Name?.substring(0, 12)}` : item.Name,
    hash:
      (<HashLink
        role="link"
        onClick={() => {
          item.type === 'transaction' || item.type === 'external_wallet' || item.type === 'contract'
            ? openInNewTab(`https://bscscan.com/tx/${item.hash}`)
            : item.type === 'contract_test' || item.type === 'external_wallet_test'
              ? openInNewTab(`https://testnet.bscscan.com/tx/${item.hash}`)
              : null;
        }}
      >
        {item.type === 'transaction' || item.type === 'external_wallet' || item.type === 'contract'
          ? 'https://bscscan.co...'
          : item.type === 'contract_test' || item.type === 'external_wallet_test'
            ? 'https://testnet.bscscan.co...' : '-'}
       </HashLink>),
    date: item.Date,
    approver: item.Approver ? item.Approver?.name : '-',
    type: item.type,
    role: item.role,
    amount: `${item.Amount} ${item.Symbol}`,
    status: item.status,
    action: (item.type === 'contract'
      ? (
        item.role === 'approver'
          && (['pending', 'Pending', 'waiting', 'Waiting'].includes(item.status)) && !item.transaction_approved
          ? (
            <>
              {!['external_wallet', 'external_wallet_test'].includes(item.type) ? (
                <HashLink
                  onClick={() => getDetail(item._id)}
                ><EyeIcon style={{ padding: '2px' }} />
                </HashLink>
              ) : (
                ''
                )}
              <HashLink onClick={() => { getOtp(item._id); setReq('Approval'); }}><AiOutlineCheckCircle style={{ padding: '2px', fontSize: '20px' }} />
              </HashLink>
              <HashLink onClick={() => { getOtp(item._id); setReq('Reject'); }}>
                <RxCross2 style={{ padding: '2px', fontSize: '15px', color: 'red' }} />
              </HashLink>
            </>
          )
          : (
            !['external_wallet', 'external_wallet_test'].includes(item.type) ? (
              <HashLink
                onClick={() => getDetail(item._id)}
              ><EyeIcon style={{ padding: '2px' }} />
              </HashLink>
            ) : ('')
          )
      )
      : (
        item.type === 'transaction' ? (
          <HashLink
            onClick={() => getDetail(item._id)}
          ><EyeIcon style={{ padding: '2px' }} />
          </HashLink>
        ) : (
          ['external_wallet', 'external_wallet_test'].includes(item.type) ? (
            (
              item.role === 'approver'
                && (['pending', 'Pending', 'waiting', 'Waiting'].includes(item.status)) && !item.third_party_approver
                ? (
                  <>
                  <HashLink onClick={() => { getOtp(item._id, item); setReq('External Approval'); }}><AiOutlineCheckCircle style={{ padding: '2px', fontSize: '20px' }} />
                  </HashLink>
                 <HashLink onClick={() => { getOtp(item._id, item); setReq('External Reject'); }}>
                     <RxCross2 style={{ padding: '2px', fontSize: '15px', color: 'red' }} />
                 </HashLink>
                  </>
                )
                : ('')
            )
          ) : ('')
         
      )
      )),
  }));
  return (
    <><Panel lg={12} title={title} closeBtn minusBtn viewAll={viewAll} refreshRequest={() => getContractDetails()} refresh={isLoading}>
      <>
        <ReactTableBase
          columns={header}
          data={detailShow ? data.slice(0, 3) : data}
          tableConfig={detailShow ? tableConfi2 : tableConfig}
        />
        {data.length === 0 && (
          <NoResult>(0) Results! Data Not Found
          </NoResult>
        )}
      </>


      </Panel>
      <StyledModal
        show={modal}
        onHide={toggle}
      >
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={toggle}
          />
          <ModalTitle>Contract Hold</ModalTitle>
        </ModalHeader>
        {
          isLoad ? (
            <ModalBody>
              <SpinnerDiv>
                <Spinner style={{ color: colorAccent }} />
              </SpinnerDiv>
            </ModalBody>
          ) : (
            typeof detail === 'string' ? (
              <NoResult>{detail}
              </NoResult>
            )
              : (
                <ModalBody>
                  <ModalMainDiv>
                    <ModalSubTitle>From:</ModalSubTitle>
                    <ModalDetail>{typeof detail.Beneficiary === 'object' ? detail.Beneficiary?.name : detail.Beneficiary}</ModalDetail>
                  </ModalMainDiv>
                  <ModalMainDiv>
                    <ModalSubTitle>To:</ModalSubTitle>
                    <ModalDetail>{detail.Transfer?.name}</ModalDetail>
                  </ModalMainDiv>
                  <ModalMainDiv>
                    <ModalSubTitle>Approver:</ModalSubTitle>
                    <ModalDetail>{typeof detail.Approver === 'object' ? detail.Approver?.name : '-'}</ModalDetail>
                  </ModalMainDiv>
                  <ModalMainDiv>
                    <ModalSubTitle>Date:</ModalSubTitle>
                    <ModalDetail>{detail.Date}</ModalDetail>
                  </ModalMainDiv>
                  <ModalMainDiv>
                    <ModalSubTitle>Amount:</ModalSubTitle>
                    <ModalDetail>{detail.Amount} {detail.Symbol}</ModalDetail>
                  </ModalMainDiv>
                  <ModalMainDiv>
                    <ModalSubTitle>Coin:</ModalSubTitle>
                    <ModalDetail>{detail.Symbol}</ModalDetail>
                  </ModalMainDiv>
                  <ModalMainDiv>
                    <ModalSubTitle>Status:</ModalSubTitle>
                    <ModalDetail>{detail.status}</ModalDetail>
                  </ModalMainDiv>
                  <ModalMainDiv>
                    <ModalSubTitle>Hash:</ModalSubTitle>
                    <div>
                      <HashLink
                        role="link"
                        onClick={() => {
                          (!['BNB', 'CTY', 'BUSD'].includes(detail.Symbol) && detail.type === 'transaction')
                            ? openInNewTab(`https://etherscan.io/tx/${detail.hash}`)
                            : openInNewTab(`https://bscscan.com/tx/${detail.hash}`);
                        }}
                      >
                        <div style={{
                          wordWrap: 'break-word',
                          width: '309px',
                          fontSize: '13px',
                        }}
                        >
                          {(!['BNB', 'CTY', 'BUSD'].includes(detail.Symbol) && detail.type === 'transaction')
                            ? `https://etherscan.io/tx/${detail.hash}`
                            : `https://bscscan.com/tx/${detail.hash}`}
                        </div>

                      </HashLink>
                    </div>
                  </ModalMainDiv>
                  <ModalMainDiv>
                    <ModalSubTitle>Type:</ModalSubTitle>
                    <ModalDetail>{detail.type}</ModalDetail>
                  </ModalMainDiv>
                  {
                    detail?.FileName && (
                      <ModalMainDiv>
                        <ModalSubTitle>File:</ModalSubTitle>
                        <HashLink
                          role="link"
                          onClick={() => {
                            openInNewTab(detail?.FileName[0]);
                          }}
                        >
                          Click here to  View File
                        </HashLink>
                      </ModalMainDiv>
                    )
                  }




                </ModalBody>
              )
          )
        }


      </StyledModal>
      <StyledModal
        show={otpModal}
        onHide={toggleOtp}
        backdrop="static"
      >
        <ModalHeader>
          <ModalCloseButton
            className="lnr lnr-cross"
            aria-label="close-btn"
            type="button"
            onClick={() => { toggleOtp(); setMsg(undefined); }}
          />
          <ModalTitle>Contract {req}</ModalTitle>
        </ModalHeader>
        {
          otpLoad ? (
            <ModalBody>
              <SpinnerDiv>
                <Spinner style={{ color: colorAccent }} />
              </SpinnerDiv>
            </ModalBody>
          )
            : (
              <ModalBody>
                <ApproveOtpForm onClose={() => setMsg(undefined)} onSubmit={verifyOtp} errorMsg={Msg} loading={approveLoad} status={status} />
              </ModalBody>
            )
        }


      </StyledModal>
    </>
  );
};
export default ContractTable;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
  `;
const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;
  button {
    min - width: 100px;
  padding: 4px 25px;
  margin-bottom: 0;
  display: inline-block;
}

  flex-flow: ${flexFlow} !important;
  `;
export const ModalMainDiv = styled.div`
  display: flex;
  justify-content: left;
  width: 100%;
  overflow-wrap: break-word;
  align-items: baseline;
  `;
export const ModalTitle = styled.h4`
  background: #4ce1b6;
  padding: 5px;
  color: #232329;
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size:20px;
  &:first-child {
    margin - top: 0;
}
  `;
export const ModalDetail = styled.h5`
  width:300px;
  margin-bottom: 10px;
  font-size:16px;
  font-weight: 700;
  text-align:left;
  word-wrap: break-word;
  &:first-child {
    margin - top: 0;
}
  `;
export const ModalSubTitle = styled.h4`
  width: 100px;
  margin-top: 10px;
  font-weight: 600;
  font-size:16px;
  text-align:left;
  word-wrap: break-word;
  color:${colorAccent};
  &:first-child {
    margin - top: 0;
}
  `;
export const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  right: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
  `;
const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
  `;
export const NoResult = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px ;
  margin-top: 1rem;
  margin-left: 0rem;
  color: white;
  font-weight: 700;
  `;
export const SpinnerDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  marginTop: .5rem;
  height: 9rem;
  `;
export const ModalHeader = styled.div``;
export const ModalBody = styled.div``;
const getColor = (color) => {
  switch (color) {
    case 'success':
      return '#22bb33';
    case 'danger':
      return '#ff4861';
    default:
      return '#f88e7d';
  }
};
export const StyledModal = styled(BootstrapModal).withConfig({
  shouldForwardProp: prop => !['colored', 'header'].includes(prop),
})`

                                .modal-dialog {
                                  max - width: 450px;
}

                                .modal-content {
                                  border - radius: 0;
                                border: none;
                                box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
                                position: relative;
                                padding: 50px 40px 60px 40px;
                                text-align: center;
                                background-color: ${colorBackground};
                                color: ${colorText};
}

                                ${ModalTitleIcon} {
                                  color: ${props => getColor(props.color)};
}

                                ${props => props.colored && `
color: ${colorWhite};

${ModalTitle},
${ModalTitleIcon},
${ModalCloseButton} {
 color: ${colorWhite};
}

${ModalFooter} {

 button:first-child {
   color: ${colorWhite};
   background-color: rgba(255, 255, 255, 0.3);
   border-color: ${colorWhite};

   &:before {
     background-color: rgba(255, 255, 255, 0.2);
   }
 }

 button:last-child {
   border-color: white;
   color: ${colorWhite};
 }
}

.modal-content {
 color: ${colorWhite};
 background-color: ${getColor(props.color)};
}
`}

                                ${props => props.header && `

.modal-dialog {
 max-width: 520px;
}

.modal-content {
 padding: 0;
 text-align: left};
}

${ModalTitle} {
 color: ${colorWhite};
}

${ModalHeader} {
 color: ${colorWhite};
 padding: 15px 20px;
 position: relative;
}

${ModalTitle} {
 margin: 0;
 font-weight: 300;
}

${ModalCloseButton} {
 color: ${colorWhite};
 top: calc(50% - 8px);
}

${ModalBody} {
 padding-top: 25px;
 padding-bottom: 20px;
 ${paddingRight(props)}: 40px;
 ${paddingLeft(props)}: 20px;
}

${ModalFooter} {
 margin-bottom: 40px;
 justify-content: flex-end;
 ${paddingRight(props)}: 20px;
}

${ModalHeader} {
 background-color: ${getColor(props.color)};
}
`}
                                `;

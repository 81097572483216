import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import EditableReactTable from './components/EditableReactTable';
import CreateTableData from '../CreateData';

const EditableTable = ({Email,Wallet,Symbol,symbolSearch,emailSearch,walletSearch,ListData, fetchData}) => {
  const { t } = useTranslation('common');
  const reactTableData = CreateTableData();

  return (
    <Container>
      <Row>
        <Col md={12}>
        </Col>
      </Row>
      <Row>
        <EditableReactTable emailSearch={emailSearch} symbolSearch={symbolSearch} walletSearch={walletSearch} Email={Email} Wallet={Wallet} Symbol={Symbol} reactTableData={ListData} fetchData={fetchData} />
      </Row>
    </Container>
  );
};

export default EditableTable;

import React, { useState, useEffect } from "react";
import { Table } from "@/shared/components/TableElements";
import Badge from "@/shared/components/Badge";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Panel from "@/shared/components/Panel";
import styled from "styled-components";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getTransactionDetails } from "../../../../utils/services/user.service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';

import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import Tooltip from '@/shared/components/Tooltip';
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from "@/shared/components/Dropdown";
import LinkAsIcon from "@/shared/components/wallet/components/LinkAsIcon";
import TransactionSideText from "@/shared/components/wallet/components/TransactionSideText";
import { StyledNoWrapCell, TableRow } from "./styled";

const headerData = [
  { id: 1, title: "Date" },
  { id: 2, title: "Hash" },
  { id: 3, title: "Beneficiary" },
  { id: 4, title: "Transfer" },
  { id: 5, title: "Name" },
  { id: 6, title: "Type" },
  { id: 7, title: "Amount" },
  // { id: 8, title: "Symbol" },
  { id: 9, title: "Status" },
];

const TransactionsTable = ({ Data }) => {
  const { t } = useTranslation("common");

  const truncateText = (text, maxLength) => {
    if (text?.length <= maxLength) return text;
    const startPart = text?.slice(0, maxLength / 2);
    const endPart = text?.slice(-maxLength / 2);
    return `${startPart}...${endPart}`;
  };
  const truncateNumber = (number, numDigits) => {

    // console.log('if is working');

    const truncatedNumber = (Number(number)).toFixed(6);
    return truncatedNumber;

  };
  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      toast.success("Text Copied");
    });
  };

  const getStatusBackgroundColor = (status) => {
    if (status === 'Completed') {
      return 'bg-success !important';
    } else if (status === 'Declined') {
      return 'bg-danger !important';
    } else if (status === 'Rejected') {
      return 'bg-danger !important';
    } else if (status === 'pending') {
      return 'bg-warning !important';
    } else if (status === 'Failed') {
      return 'bg-danger !important';
    } else {
      return 'bg-info !important';
    }
  };

  const generateFeeHashUrl = (hash, chainId) => {
    const REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL = "https://testnet.bscscan.com/tx/";
    const REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL = "https://sepolia.etherscan.io/tx/";
    const REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL = "https://mumbai.polygonscan.com/tx/";
    const {
      REACT_APP_POLYGON_MATIC,
      REACT_APP_SMART_CHAIN,
      REACT_APP_ETHERIUM_CHAIN,
    } = process.env;

    if (chainId === REACT_APP_SMART_CHAIN) {
      return REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL + hash;
    } else if (chainId === REACT_APP_POLYGON_MATIC) {
      return REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL + hash;
    } else {
      return REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL + hash;
    }
  };

  return (
    <Panel md={12} title={t("wallet.transactions")}>
      <div dir="ltr" className="flex">
        <Table bordered responsive>
          <thead>
            <tr>
              {headerData.map((item) => (
                <th key={item.id}>{item.title}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Data.length > 0 ? (
              Data.map((item) => (
                <TableRow key={item.id}>
                  <StyledNoWrapCell>{item.Date}</StyledNoWrapCell>
                  <td >
                    <button
                      onClick={() => copyTextToClipboard(item.hash)}
                      style={{ cursor: "pointer" }}
                      className="flex justify-between items-center"
                    >
                      {item?.hash ? (
                        <>
                          <span>{truncateText(item.hash, 10)}</span>
                          <FontAwesomeIcon icon={faCopy} />
                        </>
                      ) : (
                        <p>N/A</p>
                      )}
                    </button>

                  </td>

                  <td>

                    <button
                      onClick={() => copyTextToClipboard(item.Beneficiary)}
                      style={{ cursor: "pointer" }}
                      className="flex justify-between"
                    >
                      {truncateText(item.Beneficiary, 10)}
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                  </td>
                  <td

                    className="flex justify-between"
                  >
                    <button
                      onClick={() => copyTextToClipboard(item.Transfer)}
                      style={{ cursor: "pointer" }}
                      className="flex justify-between"
                    >

                      {truncateText(item.Transfer, 10)}
                      <FontAwesomeIcon icon={faCopy} />
                    </button>
                  </td>
                  <td>{item.Name}</td>
                  <td className="flex capitalize">{item.type}</td>
                  <td>{truncateNumber(item.Amount, 6) + ` ${item.Symbol}`}</td>
                  {/* <td>{item.Symbol}</td> */}
                  <td>
                    <Badge bg={getStatusBackgroundColor(item.status)}>{item.status}</Badge>
                  </td>
                  <td>
                   {item.hash ? <Tooltip text={t('wallet.transactions_link')} placement="top">
                      <a
                        href={generateFeeHashUrl(item.hash, item.chain_id)}
                        target="_blank"
                        rel="noopener noreferrer"
                       
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '4px' }} />
                      </a>
                    </Tooltip> : "--"}
                  </td>
                </TableRow>
              ))
            ) : (
              <tr>
                <td colSpan="9">No data available</td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </Panel>
  );
};

TransactionsTable.propTypes = {
  dir: PropTypes.string.isRequired,
};

export default TransactionsTable;

import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { addNewCategory_admin, getCategory_admin, deactivaCategory } from '../../../utils/services/user.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal as BootstrapModal } from "react-bootstrap";







const NewCategories = ({ getCategorys, CloseModal, isModalOpen }) => {
  const [categoryName, setCategoryName] = useState('');

  const [subCategories, setSubCategories] = useState([]);
  const [newSubCategory, setNewSubCategory] = useState('');
  const [newCustomDetail, setNewCustomDetail] = useState('');
  const [customDetails, setCustomDetails] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [displayImage, setDisplayImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const handleCategoryNameChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.trim().replace(/\s+/g, '_').toLowerCase();
    setCategoryName(formattedValue);
  };

  const handleNewSubCategoryChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.trim().replace(/\s+/g, '_').toLowerCase();
    setNewSubCategory(formattedValue);
  };

  const handleAddSubCategory = () => {
    if (newSubCategory.trim() !== '') {
      // Check if the new subCategory already exists in subCategories
      if (!subCategories.includes(newSubCategory)) {
        setSubCategories([...subCategories, newSubCategory]);
        setNewSubCategory('');
      } else {
        toast.error('SubCategory already exists')
        // console.log('SubCategory already exists');
      }
    }
  };
  const handleRemoveSubCategory = (index) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories.splice(index, 1);
    setSubCategories(updatedSubCategories);
  };

  const handleNewCustomDetailChange = (e) => {
    const inputValue = e.target.value;
    const formattedValue = inputValue.trim().replace(/\s+/g, '_').toLowerCase();
    setNewCustomDetail(formattedValue);
  };


  const handleAddCustomDetail = () => {
    if (newCustomDetail.trim() !== '') {
      // Check if the new customDetail already exists in customDetails
      if (!customDetails.includes(newCustomDetail)) {
        setCustomDetails([...customDetails, newCustomDetail]);
        setNewCustomDetail('');
      } else {
        toast.error('SubCategory already exists')
        // console.log('CustomDetail already exists');
      }
    }
  };

  const handleRemoveCustomDetail = (index) => {
    const updatedCustomDetails = customDetails.filter((_, i) => i !== index);
    setCustomDetails(updatedCustomDetails);
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleImageInputChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file)
    if (file) {
      setSelectedImage(URL.createObjectURL(file));
      setDisplayImage(true);
    }
  };


  const resetImage = () => {

    setSelectedImage(null);
    setDisplayImage(false);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (categoryName.trim() === '') {
      toast.error('Category name is empty')
      console.error("Category name is empty");
    } else if (subCategories.length === 0) {
      toast.error('Sub-categories are empty')
      console.error("Sub-categories are empty");
    } else if (customDetails.length === 0) {
      toast.error('Custom details are empty')
      console.error("Custom details are empty");
    } else if (!selectedFile) {
      toast.error(' image Must Be selected')
      console.error("No image selected");
    } else {
      // All fields are non-empty, proceed with appending data to formData and making the API call
      formData.append('category_name', categoryName);
      formData.append('sub_categories', JSON.stringify(subCategories));
      formData.append('custom_detail', JSON.stringify(customDetails));
      formData.append('File', selectedFile);

      // console.log("formData", formData);

      // Make your API call here using formData


      try {
        setIsLoading(true)
        const response = await addNewCategory_admin(formData)
        // console.log("response",response)

        if (response.data.status === 200) {
          toast.success(response.data.Message)
          setCategoryName('')
          setSubCategories([])
          setCustomDetails([])
          setSelectedFile(null)
          setSelectedImage(null)
          setDisplayImage(false)

          getCategorys()
          setIsLoading(false)
        } else {
          toast.error(response.data.Message)
        }
      } catch (error) {
        toast.error("Category is already exists")

        setIsLoading(false)
      }
    }
  };

  return (
    <>
      <BootstrapModal show={isModalOpen} onHide={CloseModal} centered >


        <BootstrapModal.Header
          closeButton
          className="dark:border-gray-700 bg-dark text-white"
        >
          <BootstrapModal.Title>Create New Category</BootstrapModal.Title>
        </BootstrapModal.Header>


        <BootstrapModal.Body className="dark:text-white bg-dark text-white">
          <form onSubmit={handleSubmit} className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4 px-6">
            <div className="mb-1 w-full">
              <label htmlFor="categoryName" className="block text-white">
                Category Name:
              </label>
              <input
                type="text"
                id="categoryName"
                value={categoryName}
                onChange={handleCategoryNameChange}
                className="w-full border border-gray-300 p-1 text-dark rounded-md"
                required
              />
            </div>
            <div className="flex flex-col">
              {displayImage ? (
                <div className="w-full">
                  <button onClick={resetImage} className="text-red-600 hover:text-red-700 mb-2">
                    Change
                  </button>
                  <img src={selectedImage} alt="Selected Image" className="h-20 w-20 mb-2" />
                  <label htmlFor="fileInput" className="block text-white">
                    Selected Image:
                  </label>
                </div>
              ) : (
                <div className="mb-4">
                  <label htmlFor="fileInput" className="block text-white">
                    Upload Image:
                  </label>
                  <input
                    type="file"
                    id="fileInput"
                    onChange={handleImageInputChange}
                    className=" w-60 h-8  p-2 rounded-md text-white"
                  />
                </div>
              )}
            </div>

            <div className="col-span-2 mb-2">
              <label htmlFor="newSubCategory" className="block text-white">
                Sub Category:
              </label>
              <div className="flex">
                <input
                  type="text"
                  id="newSubCategory"
                  value={newSubCategory}
                  onChange={handleNewSubCategoryChange}
                  className="w-full border text-dark border-gray-300 p-1 rounded-l-md"
                />
                <div onClick={handleAddSubCategory} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r-md">
                  Add
                </div>
              </div>
            </div>

            <div className="col-span-2 mb-4">
              <label htmlFor="newCustomDetail" className="block text-white">
                Custom Detail:
              </label>
              <div className="flex">
                <input
                  type="text"
                  id="newCustomDetail"
                  value={newCustomDetail}
                  onChange={handleNewCustomDetailChange}
                  className="w-full text-dark border border-gray-300 p-1 rounded-l-md"
                />
                <div onClick={handleAddCustomDetail} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r-md">
                  Add
                </div>
              </div>
            </div>

            <div className="col-span-2">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">Sub Categories list:{subCategories.length}</h3>
                  <div className="max-h-10 border border-white overflow-y-auto rounded-md">
                    <ul>
                      {subCategories.map((subCategory, index) => (
                        <li key={index} className="flex justify-between items-center  rounded-lg shadow-md text-white">
                          {subCategory}
                          <button type="button" onClick={() => handleRemoveSubCategory(index)} className="mr-1 rounded-full">
                            <FontAwesomeIcon icon={faTimes} size="sm" style={{ color: "white" }} />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>

                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">Custom Details List:{customDetails.length}</h3>
                  <div className="max-h-10 border border-white overflow-y-auto rounded-md">
                    <ul>
                      {customDetails.map((customDetail, index) => (
                        <li key={index} className="flex justify-between items-center  rounded-lg shadow-lg text-white">
                          {customDetail}
                          <button type="button" onClick={() => handleRemoveCustomDetail(index)} className="rounded-full mr-1">
                            <FontAwesomeIcon icon={faTimes} size="sm" style={{ color: "white" }} />
                          </button>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </BootstrapModal.Body>


        <BootstrapModal.Footer className="dark:bg-gray-800 bg-dark dark:border-t dark:border-gray-700">
          <div className="w-full mt-1 mb-2 flex justify-between">

            <button
              variant="outlined"
              className="w-16 h-8 text-sm border border-red-500 hover:bg-red-500 hover:text-white text-red-500 rounded-md"
              onClick={CloseModal}
            >
              Close
            </button>

            <button
              type="submit"
              onClick={handleSubmit}
              // className="bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600"
              className="w-32 h-10 text-sm border border-red-500 hover:bg-blue-500 hover:text-white text-blue-500 rounded-md"
            >
              Create Category
            </button>
          </div>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  )
}

export default NewCategories
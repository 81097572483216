/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import QRCode from 'qrcode.react';
import Badge from 'react-bootstrap/Badge';
import { Ring } from '@uiball/loaders';
import styled from 'styled-components';
import Modal from 'react-bootstrap/Modal';
import { toast } from 'react-toastify';
import { Switch, Stack } from '@mui/material';
import { Modal as BootstrapModal, Spinner } from 'react-bootstrap';
import { ButtonToolbar, Button } from '@/shared/components/Button';

import {
  colorAccent,
  colorBackground,
  colorBlue,
  colorIcon,
  colorRed,
  colorText,
  colorWhite,
  colorYellow,
 colorLightAccent, 
} from '@/utils/palette';
import {
  flexFlow, left, paddingLeft, paddingRight, right,
} from '@/utils/directions';
import {
  AccountButton,
} from '@/shared/components/account/AccountElements';
import TopbarMenuLink from '../../Layout/components/topbar/TopbarMenuLink';
import SimpleModal from '../../../shared/components/Modal/SimpleModal';
import {
 SendTwoFactor, getUserInfo, getBecomeApprover, create, verifyTFA, tfaEnable, tfaDisable, verifyTFAEmail,
} from '../../../utils/services/user.service';
import { SpinnerDiv } from '../../Dashboards/Operations/Contract/component/ContractTable';


const label = { inputProps: { 'aria-label': 'Switch demo' } };
const Tfaaa = ({ tfaUrl, tfaEmail, onDataFromChild }) => {
  const [twoFactorModal, settwoFactorModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [qrCode, setQrCode] = useState(false);
  const [userInfo, setuserInfo] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loading, setloading] = useState(false);
  // const [tfaUrl, setTfaUrl] = useState('');  
  const [tfaUrl1, setTfaUrl1] = useState('');  
  const [rejectionReason, setRejectionReason] = useState('');
  const [rejectionReason1, setRejectionReason1] = useState('');
  const { isLoggedIn } = useSelector(state => state.user);
  const [acceptReason, setAcceptReason] = useState('');
  const [approveFile, setFile1] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [isModalOpen1, setIsModalOpen1] = useState(false); // State to control modal visibility
  const [load, setload] = useState(false);
  const [twoFactors, setTwoFactor] = React.useState(false);
  const [twoFactors2, setTwoFactor2] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);  
  const [Msg, setMsg] = useState('');

  const getUsers = () => {
    setload(true);
    getUserInfo().then((res) => {
      // console.log('getUsers >>>>>', res);
      if (res.status === 200) {
        // console.log('res', res);
        setload(false);
        setuserInfo(res.data.User);
        setTwoFactor(res.data.User?.status_2fa);
        setTwoFactor2(res.data.User?.TFA === true);
      } else { 
        setload(false);
        setuserInfo(JSON.parse(sessionStorage.getItem('user'))); 
        setTwoFactor(userInfo?.data?.user?.status_2fa);
}
    }).catch(() => {
      setuserInfo(JSON.parse(sessionStorage.getItem('user'))); 
      setload(false);
    });
  };
  React.useEffect(() => {
    if (isLoggedIn) {
      getUsers();
    }
  }, []);  

  
  const handleRejectOrder = async () => {
    const requestData = {
      token: rejectionReason,
      email: tfaEmail,
    };
    try {
      setload(true);
      setIsLoading(true);
      const responseReject = await verifyTFAEmail(requestData);
      // console.log('responseReject', responseReject);
      if (responseReject.data) {   
        setIsLoading(false);
        // console.log('verifyTFA Update', responseReject.data);
        if (responseReject.data.verified === true) { 
          // console.log('onDataFromChild true');
          onDataFromChild(true);
          toast.success('Verify TFA Successfully', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setIsLoading(false);
          onDataFromChild(false);
          setErrorMessage(true);
          setRejectionReason('');
          setload(false);
          toast.error('Verify TFA failed', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } else {
        setload(false);
        setIsLoading(false);
        console.error('verifyTFA failed:', responseReject);
      }
    } catch (error) {
      setload(false);
      setIsLoading(false);
      console.error('Error verifyTFA:', error);
      console.error('verifyTFA Response data:', error.response.data);
      console.error('verifyTFA Response status:', error.response.status);
      console.error('verifyTFA Response headers:', error.response.headers);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleRejectOrder1 = async () => {
    const requestData = {
      token: rejectionReason1,
    };
    try {
      setload(true);
      const responseReject = await verifyTFA(requestData);
      // console.log('responseReject', responseReject);
      if (responseReject.data) {   
        // console.log('verifyTFA Update', responseReject.data);
        if (responseReject.data.verified === true) { 
          const tfaEnableResponse = await tfaDisable();
          // console.log('tfaDisable', tfaEnableResponse);
          if (tfaEnableResponse.data) {
            setTwoFactor2(false);
            setTfaUrl1(false);
            toast.success(tfaEnableResponse.data.Message, {
              position: toast.POSITION.TOP_RIGHT,
            });
            setload(false);
          } else {
            setload(false);
            toast.error('TFA Disable failed', {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          setload(false);
            toast.error('Verify TFA failed', {
              position: toast.POSITION.TOP_RIGHT,
            });
        }
      } else {
        setload(false);
        console.error('verifyTFA failed:', responseReject);
      }
    } catch (error) {
      setload(false);
      console.error('Error verifyTFA:', error);
      console.error('verifyTFA Response data:', error.response.data);
      console.error('verifyTFA Response status:', error.response.status);
      console.error('verifyTFA Response headers:', error.response.headers);
      toast.error(error.response.data.Message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // console.log('uset', userInfo);
  if (isLoggedIn) {
    sessionStorage.setItem('userInfo', userInfo.user_id);
  }
  return (
    <>
      <div
        className="loader-overlay"
        style={{
    display: isLoading ? 'flex' : 'none', // Use flex display when loading
    justifyContent: 'center', // Center horizontally
    alignItems: 'center', // Center vertically
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(255, 255, 255, 0.8)',
    zIndex: 999,
  }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <div className="row">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {tfaUrl && (
          <div className="col-lg-12 col-md-12 col-sm-12 my-2 text-center mx-auto">
            <p
              style={{ color: '#dddddd', fontSize: 'medium' }}
              className="py-1"
            ><b>Open your Authenticator App for code</b>
            </p>
          </div>
      )}
          {tfaUrl1 && (
          <div className="col-lg-12 col-md-12 col-sm-12 my-2 text-center mx-auto">
            <span className="d-flex">
              <span className="ms-auto">
                <p
                  style={{ color: '#dddddd', fontSize: 'medium' }}
                  className="py-1"
                ><b>Open your Authenticator App for code</b>
                </p>
              </span>
              <span className="ms-auto">
                <i
                  onClick={() => setTfaUrl1('')}
                  style={{
              marginLeft: '6px', marginRight: '5px', color: '#dc3545', marginTop: '6px', fontSize: '20px', cursor: 'pointer',
            }}
                  className="fa fa-close float-right"
                />
              </span>
            </span>
          </div>
      )}
          {tfaUrl && (
          <div className="col-lg-12 col-md-12 col-sm-12 mb-2 text-center mx-auto px-0">     
            <InputDiv>
              <div style={{ width: '100%' }}>
                <input
                  style={{ width: '100%' }}
                  name="name"
                  type="text"
                  placeholder="Enter Code"
                  value={rejectionReason}
                      // onChange={handleChange}
                  onChange={(e) => {
                        setRejectionReason(e.target.value);
                        setErrorMessage(false);
                      }}
                />
              </div>             
            </InputDiv>     

            <Button onClick={handleRejectOrder} size="md" className="btn w-50 mb-2 px-2 mt-2 w-100" variant="outline-success">Verify</Button>
            {errorMessage && (
            <Error className="input feedback">Verify TFA failed. Try again</Error>
          )}
          </div>
      )}
          {tfaUrl1 && (
          <div className="col-lg-12 col-md-12 col-sm-12 mb-2 text-center mx-auto">
            <input
              type="text"
              name="title"
              id="title"
              placeholder="Enter Code" 
              value={rejectionReason1}
              onChange={e => setRejectionReason1(e.target.value)}
              className="form-control mt-2"
            />
            <Button onClick={handleRejectOrder1} size="sm" className="btn w-50 mb-2 px-2 mt-2 w-100" variant="outline-success">Verify</Button>
          </div>
      )}
        </div>
      </div>
    </>
  );
};

export default Tfaaa;

// region STYLES

const ModalFooter = styled(ButtonToolbar)`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 0;

  button {
    min-width: 100px;
    padding: 4px 25px;
    margin-bottom: 0;
    display: inline-block;
  }
  
  flex-flow: ${flexFlow} !important;
`;

const Error = styled.div`
  color:  #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
  cursor:none;
`;

const ModalTitle = styled.h4`
  margin-top: 10px;
  margin-bottom: 15px;
  font-weight: 700;
  font-size:20px;
  &:first-child {
    margin-top: 0;
  }
`;
const ModalDetail = styled.h5`
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 5700;
text-align:left;
  &:first-child {
    margin-top: 0;
  }
`;
const ModalSubTitle = styled.h4`
  margin-top: 10px;
  font-weight: 600;
font-size:16px;
text-align:left;
color:${colorAccent};
  &:first-child {
    margin-top: 0;
  }
`;
const ModalCloseButton = styled.button`
  position: absolute;
  top: 15px;
  ${right}: 15px;
  font-size: 14px;
  width: 14px;
  height: 14px;
  cursor: pointer;
  padding: 0;
  border: none;
  background: transparent;
  color: ${colorIcon};
`;

const ModalTitleIcon = styled.span`
  width: 24px;
  height: 24px;
  font-size: 24px;
`;

const ModalHeader = styled.div``;
const ModalBody = styled.div``;

const getColor = (color) => {
  switch (color) {
    case 'primary':
      return colorBlue;
    case 'success':
      return colorAccent;
    case 'warning':
      return colorYellow;
    case 'danger':
      return colorRed;

    default:
      return colorText;
  }
};

const StyledModal = styled(BootstrapModal).withConfig({
  shouldForwardProp: prop => !['colored', 'header'].includes(prop),
})`
  
  .modal-dialog {
    max-width: 450px;
  }

  .modal-content {
    border-radius: 0;
    border: none;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    position: relative;
    padding: 50px 40px 60px 40px;
    text-align: center;
    background-color: ${colorBackground};
    color: ${colorText};
  }
  
  ${ModalTitleIcon} {
    color: ${props => getColor(props.color)};
  }

  ${props => props.colored && `
    color: ${colorWhite};

    ${ModalTitle},
    ${ModalTitleIcon},
    ${ModalCloseButton} {
      color: ${colorWhite};
    }

    ${ModalFooter} {

      button:first-child {
        color: ${colorWhite};
        background-color: rgba(255, 255, 255, 0.3);
        border-color: ${colorWhite};

        &:before {
          background-color: rgba(255, 255, 255, 0.2);
        }
      }

      button:last-child {
        border-color: white;
        color: ${colorWhite};
      }
    }

    .modal-content {
      color: ${colorWhite};
      background-color: ${getColor(props.color)};
    }
  `}

  ${props => props.header && `
    
    .modal-dialog {
      max-width: 520px;
    }

    .modal-content {
      padding: 0;
      text-align: ${left(props)};
    }
    
    ${ModalTitle} {
      color: ${colorWhite};
    }

    ${ModalHeader} {
      color: ${colorWhite};
      padding: 15px 20px;
      position: relative;
    }

    ${ModalTitle} {
      margin: 0;
      font-weight: 300;
    }

    ${ModalCloseButton} {
      color: ${colorWhite};
      top: calc(50% - 8px);
    }

    ${ModalBody} {
      padding-top: 25px;
      padding-bottom: 20px;
      ${paddingRight(props)}: 40px;
      ${paddingLeft(props)}: 20px;
    }

    ${ModalFooter} {
      margin-bottom: 40px;
      justify-content: flex-end;
      ${paddingRight(props)}: 20px;
    }

    ${ModalHeader} {
      background-color: ${getColor(props.color)};
    }
  `}
`;
export const ModalDiv = styled.div`
height: 16rem;
justify-content: space-between;
display: flex;
align-items: center;
flex-direction: column;
`;
export const TwoFactorTitle = styled.h4`
   padding: 5px; 
   font-size: 18px;
    font-weight: 700;
     text-align: center;
      margin-bottom: 1rem;
       margin-top: 1rem;
       color:${colorAccent};
`;

const LabelDiv = styled.div`
  width: 20%;
  @media screen and (max-width: 876px) {
    width: 100%;
    }
`;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  input {
    background-color: transparent;
    border: none;
    color:white;
    border-bottom:1px solid #555555;
  }
  input.disabled {
    cursor: not-allowed !important;
    color: #d9d9d9;
  }
`;

export const TwoFactorSubTitle = styled.h6`
   padding: 5px; 
   font-size: 16px;
    font-weight: 500;
     text-align: center;
      margin-bottom: 1rem;
`;
// endregion

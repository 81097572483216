import React, { useState, useEffect } from "react";
import { Modal, Button, Table } from "@mui/material";
import { Modal as BootstrapModal } from "react-bootstrap";
import { toast } from "react-toastify";
const StoreModal = ({ selectedStore, isModalOpen, closeModal }) => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyWalletAddress = (address) => {
    // console.log("onCopyWalletAddress ", address);
    setIsCopied(true);
    toast.success("Copied!");
    navigator.clipboard.writeText(address);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  if (!selectedStore) {
    return (
      <Modal open={isModalOpen} onClick={closeModal}>
        <div>No data available</div>
      </Modal>
    );
  }

  return (
    <>
      <BootstrapModal show={isModalOpen} onHide={closeModal} centered>
        <BootstrapModal.Header
          closeButton
          className="dark:border-gray-700 bg-dark text-white"
        >
          <BootstrapModal.Title>Approver Details</BootstrapModal.Title>
        </BootstrapModal.Header>
        <BootstrapModal.Body className="dark:text-white bg-dark text-white">
          <tr className=" ">
            <th className="text-left px-4 py-2 ">Approver Address</th>
            <td className="text-left px-4 py-2">
              {selectedStore.address.slice(0, 4)}
              {"..."}
              {selectedStore.address.slice(7)}
              <button
                onClick={() => onCopyWalletAddress(selectedStore.address)}
                className="ml-2 p-1"
              >
                {isCopied ? "✅" : " 📋"}
              </button>
            </td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">Approver Email</th>
            <td className="text-left px-4 py-2">{selectedStore.email}</td>
          </tr>

          <tr className=" ">
            <th className="text-left px-4 py-2">Admin Comment</th>
            <td className="text-left px-4 py-2">
              {selectedStore?.document_detail[0]?.admin_comment}
            </td>
          </tr>

          <tr className=" ">
            <th className="text-left px-4 py-2">Admin Document</th>
            <td className="text-left px-4 py-2">
              {selectedStore.document_detail[0] ? (
                <div>
                  <a
                    href={selectedStore.document_detail[0]?.admin_document}
                    download="admin_document"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View
                  </a>
                </div>
              ) : (
                "Not available"
              )}
            </td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">User Comment</th>
            <td className="text-left px-4 py-2">
              {selectedStore?.document_detail[0]?.user_comment}
            </td>
          </tr>
          <tr className=" ">
            <th className="text-left px-4 py-2">User Document</th>
            <td className="text-left px-4 py-2">
              {selectedStore.document_detail[0] ? (
                <div>
                  <a
                    href={selectedStore.document_detail[0]?.user_document}
                    download="user_document"
                    target="_blank"
                    rel="noreferrer"
                  >
                    View
                  </a>
                </div>
              ) : (
                "Not available"
              )}
            </td>
          </tr>
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="dark:bg-gray-800 bg-dark dark:border-t dark:border-gray-700">
          <div className="w-full mt-2 mb-2  flex justify-end ">
            <button
              variant="outlined"
              className="w-16 h-8 mr-4 text-sm border border-red-500 hover:bg-red-500 hover:text-white text-red-500 rounded-md"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </BootstrapModal.Footer>
      </BootstrapModal>
    </>
  );
};

export default StoreModal;

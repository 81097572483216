import { combineReducers } from 'redux';
import {
  themeReducer,
  rtlReducer,
  customizerReducer,
  newOrderTableReducer,
  sidebarReducer,
  authReducer,
  roundBordersReducer,
  blocksShadowsReducer,
  pokemonReducer,
  cryptoTrendsReducer,
  globalQuotesReducer,
  quotesBySymbolReducer,
  topTenReducer,
  coinListReducer,
  cryptoHistoryReducer,
 message,
 wallets,
} from '@/redux/reducers/index';
import appConfigReducer from '@/redux/reducers/appConfigReducer';

const rootReducer = combineReducers({
  theme: themeReducer,
  rtl: rtlReducer,
  border: roundBordersReducer,
  shadow: blocksShadowsReducer,
  appConfig: appConfigReducer,
  customizer: customizerReducer,
  newOrder: newOrderTableReducer,
  sidebar: sidebarReducer,
  user: authReducer,
  message,
  pokemon: pokemonReducer,
  cryptoTrends: cryptoTrendsReducer,
  globalQuotes: globalQuotesReducer,
  quotesBySymbol: quotesBySymbolReducer,
  topTen: topTenReducer,
  cryptoHistory: cryptoHistoryReducer,
  coinList: coinListReducer,
  wallets,
});

export default rootReducer;

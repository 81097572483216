import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useState } from "react";
import { transferBSC, transferEth, transferMatic, transferSolana, transferTron } from "../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";

const MigrationConfirmModa = ({ show, hide, reFetch, data, type }) => {
    const [isLoading, setIsLoading] = useState(false);
    const handleTransfer = async () => {

        setIsLoading(true)
        try {

            const payload = {
                symbol: data.symbol,
                user_id: data?.user_id
            }
            let res;

            if (data?.chain_id == 111) {
                res = await transferTron(payload)
            }
            if (data?.chain_id == 97 || data?.chain_id == 56) {
                res = await transferBSC(payload)
            }
            if (data?.chain_id == 1 || data?.chain_id == 11155111) {
                res = await transferEth(payload)
            }
            if (data?.chain_id == 123) {
                res = await transferSolana(payload)
            }
            if (data?.chain_id == 137 || data?.chain_id == 8001) {
                res = await transferMatic(payload)
            }
            toast.success(res?.data?.Message)
            if (res.status == 200) {
                reFetch()
            }


        } catch (error) {
            console.log("🚀 ~ handleTransfer ~ error:", error)

        }
        finally {

            setIsLoading(false)
            hide()
        }
    }
    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={hide}
            >
                <div
                    className="loader-overlay"
                    style={{
                        display: isLoading ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        zIndex: 999,
                    }}
                >
                    <Ring size={65} color="#00897B" />
                </div>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                        <b>Delete Partner </b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="container padding-bottom-3x p-0">
                        <div
                            className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
                            style={{ boxShadow: "none", fontFamily: "sans-serif" }}
                        >
                            <div>
                                <h4 className="text-black">
                                    Are You sure you want to Force Transfer
                                </h4>
                            </div>
                            <div className=" flex w-full gap-4 justify-center items-center">
                                <button
                                    className="text-white p-1 px-4 py-2 rounded-md bg-success "
                                    onClick={handleTransfer}
                                >
                                    Yes
                                </button>
                                <button
                                    className="text-white p-1 px-4 py-2 rounded-md bg-danger "
                                    onClick={hide}
                                >
                                    No
                                </button>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default MigrationConfirmModa;
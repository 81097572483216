import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend,
} from 'recharts';
import Panel from '@/shared/components/Panel';
import getTooltipStyles from '@/shared/helpers';
import { DashboardAreaChartContainer } from '../../BasicDashboardComponents';

const data = [{
  name: 'Jan', plan: 590, pv: 800, current: 1400,
},
{
  name: 'Feb', plan: 868, pv: 967, current: 1506,
},
{
  name: 'Mar', plan: 1397, pv: 1098, current: 989,
},
{
  name: 'Apr', plan: 1480, pv: 1200, current: 1228,
},
{
  name: 'May', plan: 1520, pv: 1108, current: 1100,
},
{
  name: 'Jun', plan: 1520, pv: 1108, current: 1100,
},
{
  name: 'Jul', plan: 1400, pv: 680, current: 1700,
},
{
  name: 'Aug', plan: 1400, pv: 680, current: 1700,
},
{
  name: 'Oct', plan: 1400, pv: 680, current: 1700,
},
{
  name: 'Sep', plan: 1400, pv: 680, current: 1700,
},
{
  name: 'Nov', plan: 1400, pv: 680, current: 1700,
},
{
  name: 'Dec', plan: 1400, pv: 680, current: 1700,
},
];
const allMonthsData = Array.from({ length: 12 }, (_, monthIndex) => ({
  month: monthIndex + 1,
  store_fee: 0, 
}));
const monthNames = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec',
];

const StoreFee = ({ rtl, StoreFeeGraph }) => {
  const { t } = useTranslation('common');
// console.log('Store Fee innner', StoreFeeGraph)
  const themeName = useSelector(state => state.theme.className);
  const mergedData = allMonthsData.map(monthData => {
    const saleData = StoreFeeGraph.find(data => data.month === monthData.month);
    return saleData || monthData;
  });
  // const mergedData = StoreFeeGraph;
  const formatMonthName = (month) => {
    return monthNames[month - 1]; 
  };
  return (
    <Panel lg={12} title={t('Stores Fees')}>
      <div dir="ltr">
        <DashboardAreaChartContainer height={250}>
          <AreaChart data={mergedData} margin={{ top: 20, left: -15, bottom: 20 }}>
            <XAxis dataKey="month" tickLine={false} reversed={rtl === 'rtl'} tickFormatter={formatMonthName} />
            <YAxis tickLine={false} orientation={rtl === 'rtl' ? 'right' : 'left'} />
            <Tooltip  {...getTooltipStyles(themeName, 'defaultItems')} />
            <Legend />
            <CartesianGrid />
            <Area
              name=" Store Fees"
              type="monotone"
              dataKey="store_fee"
              fill="#4ce1b6"
              stroke="#4ce1b6"
              fillOpacity={0.2}
            />
           
          </AreaChart>
        </DashboardAreaChartContainer>
      </div>
    </Panel>
  );
};

StoreFee.propTypes = {
  rtl: PropTypes.string.isRequired,
};

export default StoreFee;

import React, { useState, useEffect } from "react";
import { Modal, Button, Table } from "@mui/material";
import { Modal as BootstrapModal } from "react-bootstrap";
import "./OrderModal.css";
import moment from "moment";

const OrderModal = ({ selectedOrder, isModalOpen, closeModal }) => {
  console.log("🚀 ~ OrderModal ~ selectedOrder:", selectedOrder)


  const warrantyStartDate = new Date(
    selectedOrder?.product_details[0]?.warranty_start_date
  );

  const day = warrantyStartDate.getDate();
  console.log("🚀 ~ OrderModal ~ day:", day)
  const month = warrantyStartDate.getMonth() + 1;
  console.log("🚀 ~ OrderModal ~ month:", month)
  const year = warrantyStartDate.getFullYear();
  console.log("🚀 ~ OrderModal ~ year:", year)
  console.log("🚀 ~ OrderModal ~ warrantyStartDate:", warrantyStartDate)

  const formattedDate = `${day < 10 ? "0" : ""}${day} ${month < 10 ? "0" : ""
    }${month} ${year}`;
  console.log("🚀 ~ OrderModal ~ formattedDate:", formattedDate)

  const warrantyEndDate = new Date(
    selectedOrder.product_details.length > 0
      ? selectedOrder.product_details[0].warranty_end_date
      : ""
  );

  const days = warrantyEndDate.getDate();
  const months = warrantyEndDate.getMonth() + 1;
  const years = warrantyEndDate.getFullYear();

  const formattedEndDate = `${days < 10 ? "0" : ""}${days} ${months < 10 ? "0" : ""
    }${months} ${years}`;


  const generateFeeHashUrl = () => {
    const REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL =
      "https://testnet.bscscan.com/tx/";
    const REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL =
      "https://sepolia.etherscan.io/tx/";
    const REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL =
      "https://mumbai.polygonscan.com/tx/";
    const {
      REACT_APP_POLYGON_MATIC,
      REACT_APP_SMART_CHAIN,
      REACT_APP_ETHERIUM_CHAIN,
    } = process.env;

    const chainId = selectedOrder.chain_id;
    const transactionId = selectedOrder.payment_transaction_hash;


    if (chainId === REACT_APP_SMART_CHAIN) {
      return REACT_APP_BSC_TRANSACTION_EXPLORER_BASE_URL + transactionId;
    } else if (chainId === REACT_APP_POLYGON_MATIC) {
      return REACT_APP_MATIC_TRANSACTION_EXPLORER_BASE_URL + transactionId;
    } else {
      return REACT_APP_ETH_TRANSACTION_EXPLORER_BASE_URL + transactionId;
    }
  };



  return (
    <>
      <BootstrapModal show={isModalOpen} onHide={closeModal} centered size="lg"
      >
        <BootstrapModal.Header
          closeButton
          className="dark:border-gray-700 bg-dark text-white"
        >
          <BootstrapModal.Title>Order Details</BootstrapModal.Title>
        </BootstrapModal.Header>


        <BootstrapModal.Body className="dark:text-white bg-dark text-white">
          <div className="table-responsive">
            <table className="w-full border-collapse">
              <tr className="border  ">
                <th className="text-left px-2 py-1">OwnerName</th>
                <th className="text-left px-2 py-1">SellerName</th>
                <th className="text-left px-2 py-1">Title</th>
                <th className="text-left px-2 py-1">ProductObjectId</th>
              </tr>

              <tr className=" ">
                <td className="text-left px-4 py-2">
                  {selectedOrder.buyer_detail.length > 0
                    ? selectedOrder.buyer_detail[0].name
                    : ""}
                </td>

                <td className="text-left px-4 py-2">
                  {selectedOrder.seller_detail.length > 0
                    ? selectedOrder.seller_detail[0].name
                    : ""}
                </td>

                <td className="text-left px-4 py-2">
                  {selectedOrder.product_details.length > 0
                    ? selectedOrder.product_details[0].title
                    : ""}
                </td>

                <td className="text-left px-4 py-2">
                  {selectedOrder.product_details.length > 0
                    ? selectedOrder.product_details[0].detail_objectId
                    : ""}
                </td>
              </tr>

              <tr className="border  ">
                <th className="text-left px-2 py-1">ProductId</th>
                <th className="text-left px-2 py-1">Quantity</th>
                <th className="text-left px-2 py-1">Warranty In Months</th>
                <th className="text-left px-2 py-1">Warrnty Start</th>
              </tr>
              <tr className=" ">
                <td className="text-left px-4 py-2">
                  {selectedOrder.product_details.length > 0
                    ? selectedOrder.product_details[0].product_id
                    : ""}
                </td>

                <td className="text-left px-4 py-2">
                  {selectedOrder.product_details.length > 0
                    ? selectedOrder.product_details[0].quantity
                    : ""}
                </td>

                <td className="text-left px-4 py-2">
                  {selectedOrder.product_details.length > 0
                    ? selectedOrder.product_details[0].warranty_in_months
                    : ""}
                </td>

                <td className="text-left px-4 py-2">

                  {
                    selectedOrder?.product_details[0]?.warranty_start_date ?
                      moment(selectedOrder?.product_details[0]?.warranty_start_date).format('ll') : "- -"
                  }

                </td>
              </tr>

              <tr className=" border ">
                <th className="text-left px-2 py-1">Warrnty End</th>
                <th className="text-left px-2 py-1">Size</th>
                <th className="text-left px-2 py-1">DeliveryCountry</th>
                <th className="text-leftpx-2 py-1">DeliveryAddress</th>
              </tr>

              <tr className=" ">
                <td className="text-left px-4 py-2">


                  {
                    selectedOrder?.product_details[0]?.warranty_end_date ?
                      moment(selectedOrder?.product_details[0]?.warranty_end_date).format('ll') : "- -"
                  }

                </td>

                <td className="text-left px-4 py-2">
                  {selectedOrder.product_details.length > 0
                    ? selectedOrder.product_details[0].size
                    : ""}
                </td>

                <td className="text-left px-4 py-2">
                  {selectedOrder.delivery_country}
                </td>

                <td className="text-left px-4 py-2">
                  {selectedOrder.delivery_address}
                </td>
              </tr>

              <tr className="border  ">
                <th className="text-left px-2 py-1">Discount</th>
                <th className="text-left px-2 py-1">WalletType</th>
                <th className="text-left px-2 py-1">ChainId</th>
                <th className="text-left px-2 py-1">PaymentTransactionHash</th>
              </tr>

              <tr className=" ">
                <td className="text-left px-4 py-2">
                  {selectedOrder.product_details.length > 0
                    ? selectedOrder.product_details[0].discount
                    : ""}
                  %
                </td>

                <td className="text-left px-4 py-2">
                  {selectedOrder.wallet_type}
                </td>

                <td className="text-left px-4 py-2">
                  {selectedOrder.chain_id}
                </td>
                <td className="text-left px-4 py-2">
                  {" "}
                  {selectedOrder.payment_transaction_hash ? (
                    <a
                      href={generateFeeHashUrl()}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View
                    </a>
                  ) : (
                    "Not available"
                  )}
                </td>
              </tr>

              <tr className="border ">
                <th className="text-left px-2 py-1 ">Rejection Reason</th>
                <th className="text-left px-2 py-1">Approval Reason</th>
                <th className="text-left px-2 py-1">Approved</th>
              </tr>

              <tr className=" ">
                <td className="text-left ">
                  {selectedOrder.rejection_reason ||
                    selectedOrder.rejection_documents
                    ? selectedOrder.rejection_reason
                    : "No Documents Available"}
                </td>
                <td className="text-left px-2 ">
                  {selectedOrder.approve_reason ||
                    selectedOrder.approve_documents
                    ? selectedOrder.approve_reason
                    : "No Documents Available"}
                </td>

                <td className="text-left px-2 ">
                  {selectedOrder.is_approved_status ? "Yes" : "No"}
                </td>
              </tr>
            </table>
          </div>

        </BootstrapModal.Body>

        <BootstrapModal.Footer className="dark:bg-gray-800 bg-dark dark:border-t dark:border-gray-700">
          <div className="w-full mt-1 mb-2 flex justify-end ">
            <button
              variant="outlined"
              className="w-16 h-8 text-sm border border-red-500 hover:bg-red-500 hover:text-white text-red-500 rounded-md"
              onClick={closeModal}
            >
              Close
            </button>
          </div>
        </BootstrapModal.Footer>

      </BootstrapModal>
    </>
  );
};

export default OrderModal;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import styled from "styled-components";
import Card from "./components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faUndo } from "@fortawesome/free-solid-svg-icons";
import { getAllProductadmin } from "../../../utils/services/user.service";
import { toast } from "react-toastify";
import ReactPaginate from "react-paginate";
import { Ring } from "@uiball/loaders";
import "./customStyle.css";


const AllProducts = () => {
    const { t } = useTranslation("common");
    const [reFetch, setReFetch] = useState(false);
    const [selectedoptionorder, setoptionSelectorder] = useState("all");
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 10; // Change this value as needed
    const [storeNameQuery, setStoreNameQuery] = useState(null);
    const [allProducts, setAllProducts] = useState([]);
    const [storeIdQuery, setStoreIdQuery] = useState("");
    const [productIdQuery, setProductIdQuery] = useState("");
    const [count, setCount] = useState(null);
    const [statusQuery, setStatusQuery] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const user = JSON.parse(sessionStorage.getItem("user"));
    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await fetchAllProducts(itemsPerPage, currentPage + 1);
                setAllProducts(data.products);
                setCount(data.count);
            } catch (error) {
                toast.error("Error fetching products");
            }
        };
        fetchData();
    }, [reFetch, currentPage]);

    // Inside handlePageChange function
    const handlePageChange = async (selectedPage) => {
        setCurrentPage(selectedPage);
        try {
            const data = await fetchAllProducts(itemsPerPage, selectedPage + 1);
            setAllProducts(data.products);
        } catch (error) {
            toast.error("Error fetching products");
        }
    };

    const fetchAllProducts = async (limit, pageNumber) => {
        try {
            setIsLoading(true);
            const payload = {
                limit: limit,
                page_number: pageNumber,
                product_id: "",
                status: "all",
                store_id: "",
                user_id: "",
            };
            const res = await getAllProductadmin(payload);
            setIsLoading(false);
            return res.data;
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching products:", error);
            toast.error("Error fetching products");
            throw error;
        }
    };

    // Function to handle search
    const handleSearch = async () => {
        try {
            setIsLoading(true);
            const payload = {
                limit: itemsPerPage,
                page_number: currentPage + 1,
                product_id: productIdQuery || "",
                status: "all",
                store_id: storeIdQuery || "",
                user_id: storeNameQuery || "",
            };
            const res = await getAllProductadmin(payload);
            setIsLoading(false);
            if (res.data && res.data.products.length > 0) {
                setAllProducts(res.data.products);
            } else {
                // Display a message indicating no products were found
                toast.error("No Data Found");
            }
        } catch (error) {
            setIsLoading(false);
            console.error("Error fetching products:", error);
            toast.error("Error fetching products");
        }
    };

    // Function to reset search fields
    const handleReset = () => {
        setoptionSelectorder("all");
        setStoreNameQuery("");
        setStoreIdQuery("");
        setProductIdQuery("");
        setStatusQuery("");
        handlePageChange(0);
    };

    const handleApiChange2 = (event) => {
        const selectedOption = event.target.value;
        setoptionSelectorder(selectedOption);
    };
    const filteredStoreData =
        selectedoptionorder === "all"
            ? allProducts
            : allProducts.filter((store) => store.status === selectedoptionorder);
    return (
        <Container>
            <div
                className="loader-overlay"
                style={{
                    display: isLoading ? "flex" : "none", // Use flex display when loading
                    justifyContent: "center", // Center horizontally
                    alignItems: "center", // Center vertically
                    position: "fixed",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    background: "rgba(255, 255, 255, 0.8)",
                    zIndex: 999,
                }}
            >
                <Ring size={65} color="#00897B" />
            </div>
            <Row>
                <Col md={12}>
                    <h3 className="page-title">{t("all-products.page_title")}</h3>
                </Col>
            </Row>
            <Row className="flex flex-wrap gap-3 gap-lg-0">
                <Col md={4} lg={3}>
                    {/* Search input for store name */}
                    <Form.Group className="flex gap-3" controlId="searchStoreName">
                        <Form.Control
                            type="text"
                            placeholder="Search by User Id"
                            value={storeNameQuery}
                            onChange={(e) => setStoreNameQuery(e.target.value)}
                            className="placeholder:text-sm"
                        />
                    </Form.Group>
                </Col>
                <Col md={4} lg={3}>
                    {/* Search input for store ID */}
                    <Form.Group className="flex gap-3" controlId="searchStoreId">
                        <Form.Control
                            type="text"
                            placeholder="Search by store ID"
                            value={storeIdQuery}
                            onChange={(e) => setStoreIdQuery(e.target.value)}
                            className="placeholder:text-sm"
                        />
                    </Form.Group>
                </Col>
                <Col md={4} lg={3}>
                    {/* Search input for product ID */}
                    <Form.Group className="flex gap-3" controlId="searchProductId">
                        <Form.Control
                            type="text"
                            placeholder="Search by product ID"
                            value={productIdQuery}
                            onChange={(e) => setProductIdQuery(e.target.value)}
                            className="placeholder:text-sm"
                        />
                    </Form.Group>
                </Col>
                <Col md={4} lg={3}>
                    <select
                        onChange={handleApiChange2}
                        className=" rounded-md py-2 w-full"
                        id="filters"
                    >
                        <option selected={selectedoptionorder === "all"} value="all">
                            All
                        </option>
                        <option selected={selectedoptionorder === "active"} value="active">
                            Active
                        </option>
                        <option
                            selected={selectedoptionorder === "inactive"}
                            value="inactive"
                        >
                            inactive
                        </option>
                        <option
                            selected={selectedoptionorder === "deleted"}
                            value="deleted"
                        >
                            Deleted
                        </option>
                    </select>
                </Col>

                <Col
                    md={3}
                    className="flex w-full justify-center md:justify-start mt-4  gap-3"
                >
                    <Button variant="success" onClick={handleSearch}>
                        <FontAwesomeIcon icon={faSearch} className="mr-2" /> Search
                    </Button>
                    <Button variant="danger" onClick={handleReset}>
                        <FontAwesomeIcon icon={faUndo} className="mr-2" /> Reset
                    </Button>
                </Col>

                <Row
                    xs={1}
                    md={2}
                    lg={4}
                    className="mt-5 mb-2 justify-content-center justify-content-lg-start"
                >
                    {filteredStoreData.map((item) => (
                        <Card
                            key={item.id}
                            product={item}
                            reFetch={() => setReFetch(!reFetch)}
                        />
                    ))}
                </Row>
                <div className="pagination">
                    <ReactPaginate
                        previousLabel={"Previous"}
                        nextLabel={"Next"}
                        breakLabel={"..."}
                        pageCount={Math.ceil(count / itemsPerPage)}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        onPageChange={({ selected }) => handlePageChange(selected)}
                        containerClassName={"pagination"}
                        activeClassName={"active"}
                        className="flex list-none items-center justify-center py-5 w-full gap-9 text-white text-lg"
                        disabledClassName="opacity-50 cursor-not-allowed" // Add Tailwind classes to style disabled buttons
                        previousClassName={currentPage === 0 ? "disabled" : ""} // Check if on the first page
                        nextClassName={currentPage === Math.ceil(count / itemsPerPage) - 1 ? "disabled" : ""} // Check if on the last page
                        previousLinkClassName="flex list-none align-middle justify-center py-5 w-full gap-9 text-white text-lg" // Tailwind classes for styling previous button
                        nextLinkClassName="flex list-none align-middle justify-center py-5 w-full gap-9 text-white text-lg" // Tailwind classes for styling next button
                    />
                </div>

            </Row>
        </Container>
    );
};

export default AllProducts;

export const NoResult = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;
  margin-top: 1rem;
  margin-left: 0rem;
  color: white;
  font-weight: 700;
`;

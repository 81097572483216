// DateRangeFilter.js

import React, { useEffect, useRef } from 'react';
import Pikaday from 'pikaday';
import 'pikaday/css/pikaday.css';

const DateRangeFilter = ({ onDateChange }) => {
  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);

  useEffect(() => {
    const fromPicker = new Pikaday({
      field: fromDateRef.current,
      format: 'YYYY-MM-DD',
      onSelect: (date) => {
        onDateChange({ from: date, to: toDateRef.current.value });
      },
    });

    const toPicker = new Pikaday({
      field: toDateRef.current,
      format: 'YYYY-MM-DD',
      onSelect: (date) => {
        onDateChange({ from: fromDateRef.current.value, to: date });
      },
    });

    return () => {
      fromPicker.destroy();
      toPicker.destroy();
    };
  }, [onDateChange]);

  return (
    <div className="flex gap-2">
      <input
        ref={fromDateRef}
        type="text"
        placeholder="From Date"
        className="border p-2 rounded bg-transparent"
      />
      <input
        ref={toDateRef}
        type="text"
        placeholder="To Date"
        className="border p-2 rounded bg-transparent"
      />
    </div>
  );
};

export default DateRangeFilter;

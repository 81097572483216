import Modal from "react-bootstrap/Modal";
import { useState, useRef } from "react";
import { Ring } from "@uiball/loaders";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import { toast } from "react-toastify";
import { addNews } from "../../../utils/services/user.service";

const AddNews = ({ show, hide, reFetch }) => {
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef1 = useRef(null);
  const [image1, setImage1] = useState([]);

  const [show1, setShow1] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const handleImageUpload = (e) => {
    const selectedImagesArray = Array.from(e.target.files);
    setImage1(selectedImagesArray);
    setShow1(true);
  };

  const handleButtonClickClick1 = () => {
    fileInputRef1.current.click();
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    try {
      if (!image1 || image1.length === 0) {
        toast.error("Images are required");
        return;
      }

      const formData = new FormData();
      formData.append(`File`, image1[0]); // Append the first file (if necessary)
      formData.append("title", title);
      formData.append("description", description);
      const res = await addNews(formData);
      setIsLoading(false);
      reFetch();
      hide();
    } catch (error) {
      setIsLoading(false);
      toast.error(
        "An error occurred while adding news. Please try again later."
      );
      console.error("Error occurred:", error);
    }
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            <b>Add News </b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container align-items-center justify-center w-full flex-col padding-bottom-3x p-0">
            <div className=" row my-2">
              <div className="col-md-12 col-lg-12 col-sm-12">
                {show1 && (
                  <div className="col-md-12 col-lg-4 col-sm-12 mt-3">
                    <StyledImage
                      // key={index}
                      src={URL.createObjectURL(image1[0])}
                      alt={`Selected `}
                    />
                  </div>
                )}
              </div>
              <div className="col-md-12 col-lg-4 col-sm-12">
                <label
                  htmlFor="File"
                  style={{ fontSize: "small" }}
                  className="form-label text-black"
                >
                  Product Images *
                </label>
                <input
                  hidden
                  type="file"
                  ref={fileInputRef1}
                  name="File[]"
                  id="File"
                  onChange={handleImageUpload}
                  accept="image/*"
                />
                <br />
                <Button
                  size="md"
                  onClick={handleButtonClickClick1}
                  className="py-2 btn-block mb-0"
                  variant="outline-success"
                >
                  Upload Images
                </Button>
              </div>
              <div className="col-md-12 col-lg-4 col-sm-12">
                <InputDiv>
                  <label
                    htmlFor="description"
                    style={{ fontSize: "small" }}
                    className="form-label text-black"
                  >
                    Title *
                  </label>
                  <div>
                    <ChatSearchInput
                      type="text"
                      value={title}
                      name="description"
                      id="description"
                      placeholder="Enter Title"
                      onChange={(e) => setTitle(e.target.value)}
                      className={`form-control mt-2`}
                    />
                  </div>
                </InputDiv>
              </div>
              <div className="col-md-12 col-lg-4 col-sm-12">
                <InputDiv>
                  <label
                    htmlFor="description"
                    style={{ fontSize: "small" }}
                    className="form-label text-black"
                  >
                    Description *
                  </label>
                  <div>
                    <ChatSearchInput
                      type="text"
                      value={description}
                      name="description"
                      id="description"
                      placeholder="Enter Description"
                      onChange={(e) => setDescription(e.target.value)}
                      className={`form-control mt-2`}
                    />
                  </div>
                </InputDiv>
              </div>
              {/* Display selected image */}
            </div>
            <div className=" self-center">
              <Button
                className="py-2 btn-block mb-0"
                variant="contain-success"
                size="md"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AddNews;

const StyledImage = styled.img`
  max-width: 100%;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

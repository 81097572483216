import React from 'react';
import PropTypes from 'prop-types';
import { Field } from 'react-final-form';
import renderCheckBoxField from '@/shared/components/form/CheckBox';
import { FormGroupField } from '@/shared/components/form/FormElements';

const CheckBoxGroup = ({ name, styleType, allTokens, tokenData,onChange }) => {
  const handleChange = () => {
    console.log("HIT")
  }
  return (
    <div className='col-12 w-full justify-between'>
      {allTokens.map((item, index) => {
        const token = tokenData.find(data => data.symbol === item.label);
        const defaultChecked = token !== undefined;

        return (
          // <FormGroupField key={`index_${item.label}`}>
            <div className='flex w-full gap-4 col-6 justify-between' key={`index_${item.label}`}>
              <label className='flex w-[50%] text-nowrap'>
                <Field
                  name={`${item.name}_${name}_${index}`}
                  type="checkbox"
                  component={renderCheckBoxField}
                  label={item.label}
                  initialValue={defaultChecked}
                  // styleType={styleType}
                  
                />
              </label>
              <input
                type="text"
                className='w-[50%] border'
                placeholder="Enter value"
                name={`input_${item.name}_${name}_${index}`}
                
              />
            </div>
          // </FormGroupField>
        );
      })}
    </div>
  );
};

CheckBoxGroup.propTypes = {
  name: PropTypes.string,
  styleType: PropTypes.string,
  allTokens: PropTypes.array.isRequired, // Ensure allTokens is provided as a prop
  tokenData: PropTypes.array.isRequired, // Ensure tokenData is provided as a prop
};

CheckBoxGroup.defaultProps = {
  name: '',
  styleType: '',
};

export default CheckBoxGroup;

import moment from "moment";
import { useEffect, useState } from "react";
import TitleCard from "./TitleCard";
import Switch from "@mui/material/Switch";
import {
  getApproverss,
  rejectUser,
  approveUser,
  resetPasswordAdmin,
  adminSwitchProfile,
} from "../../../utils/services/user.service";
import "./table.css";
import { Modal } from "@mui/material";
import ProfileModal from "./ProfileModal";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import Tooltip from "@/shared/components/Tooltip";
import { Link } from "react-router-dom";
import { Button, ButtonToolbar } from "@/shared/components/Button";
import "react-toastify/dist/ReactToastify.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck,faTimes  } from "@fortawesome/free-solid-svg-icons";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import Approvertable from './component/Approvertable'
function ApproversList() {
  const itemsPerPages = 20;
  const [selectedoption, setoptionSelect] = useState("all");
  const [BooleanState, setBooleanState] = useState("true");
  const [usersData, setUsersData] = useState([]);
  const [idQuery, setIdQuery] = useState("");
  const [emailQuery, setEmailQuery] = useState("");
  const [nameQuery, setNameQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenFactor, setIsModalOpenFactor] = useState(false);
  const [UserMail, setUserMail] = useState('');

  const [is2FAEnabled, setIs2FAEnabled] = useState(false);
  const [isactiveEnabled, setisactiveEnabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const [pageCount, setPageCount] = useState(1); // Initialize with 1 page.
  const [ModalOpen, setModalOpen] = useState(false);
  const [resetOpen, setResetOpen] = useState(false);
  const [UnverifiedEmail, SetUnverifiedEmail] = useState("");
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState();
  const [emailReset, setEmailReset] = useState("");
  const [passwordReset, setPasswordReset] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [approveOpen, setapproveOpen] = useState(false);
  const [rejectOpen, setrejectOpen] = useState(false);
  const [EyeOpen, setEyeOpen] = useState(false);
  const [textInput, setTextInput] = useState("");
  const [UserMessage, setUserMessage] = useState("");
  const [UserDocument, setUserDocument] = useState("");
  const [UserStatus, setUserStatus] = useState("");
  const [fileInput, setFileInput] = useState(null);
  const [isCopied, setIsCopied] = useState(false);
// console.log('UserDocument UserDocument', UserDocument);
// console.log('UserMessage UserMessage', UserMessage);
  const handleCopyClick = (user) => {
    const truncatedAddress = user.address;
    const textArea = document.createElement('textarea');
    textArea.value = truncatedAddress;
    document.body.appendChild(textArea);
    textArea.select();
    document.execCommand('copy');
    document.body.removeChild(textArea);

    setIsCopied(true);
toast.success('Copied!')
    // Reset the "Copied" state after a short delay
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };
  // console.log("usersData", usersData);
  // console.log("UnverifiedEmail", UnverifiedEmail);
  // console.log("pageCount", pageCount);
  // console.log("BooleanState>>>>", BooleanState);
  // console.log("selectedoption", typeof selectedoption);
  // console.log("currentPage", currentPage);
  // console.log("emailQuery", emailQuery);
  // console.log("nameQuery", nameQuery);
  // console.log("idQuery", idQuery);
  // console.log("searchResults", searchResults);
  // console.log("is2FAEnabled", is2FAEnabled);
  // console.log("isactiveEnabled", isactiveEnabled);

  useEffect(() => {
    const getApproversss = async () => {
      try {
        setIsLoading(true);
        const payload = {
          limit : itemsPerPages,
          page_number : currentPage,
          approver_status : selectedoption,
      }
        const response = await getApproverss(payload);
        if (response.data.data) {
          setUsersData(response.data.data);

          const totalUsers = response.data.count;
          // console.log("totalUsers", totalUsers);

          const pages = Math.ceil(totalUsers / itemsPerPages);
          // console.log("pages", pages);
          handleCancel()
          setPageCount(pages);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          // console.log("data not found");
        }
      } catch (error) {
        setIsLoading(false);
        // console.log("error", error);
      }
    };
    getApproversss();
  }, [currentPage,selectedoption]);
  const getApproversss = async () => {
    try {
      setIsLoading(true);
      const payload = {
        limit : itemsPerPages,
        page_number : currentPage,
        approver_status : selectedoption,
    }
      const response = await getApproverss(payload);
      if (response.data.data) {
        setUsersData(response.data.data);

        const totalUsers = response.data.count;
        // console.log("totalUsers", totalUsers);

        const pages = Math.ceil(totalUsers / itemsPerPages);
        // console.log("pages", pages);
        handleCancel()
        setPageCount(pages);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // console.log("data not found");
      }
    } catch (error) {
      setIsLoading(false);
      // console.log("error", error);
    }
  };
  const downloadPdf = async (url, filename) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
  
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(new Blob([blob]));
      link.download = filename;
      link.click();
    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };
  const ResetUsers = async () => {
    try {
      setIsLoading(true);
      const payload = {
        user_id: "",
        email: "",
        user_name: "",
        two_factor: false,
        // user_status: isactiveEnabled,
        limit: itemsPerPages,
        page_number: currentPage,
        is_verified_status: BooleanState,
      };
      const response = await getApproverss(payload);
      if (response.data.user.usersResult) {
        setUsersData(response.data.user.usersResult);

        const totalUsers = response.data.user.count;
        // console.log("totalUsers", totalUsers);

        const pages = Math.ceil(totalUsers / itemsPerPages);
        // console.log("pages", pages);

        setPageCount(pages);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        // console.log("data not found");
      }
    } catch (error) {
      setIsLoading(false);
      // console.log("error", error);
    }
  };
  const handleToggle = (userId) => {
    const updatedUsersData = usersData.map((user) => {
      if (user._id === userId) {
        return { ...user, status_2fa: !user.status_2fa };
      }
      return user;
    });
    setUsersData(updatedUsersData);
  };

  const handleApplyClick = async () => {
    try {
      setIsLoading(true);
      const payload = {
        user_id: idQuery,
        email: emailQuery,
        user_name: nameQuery,
        two_factor: is2FAEnabled,
        limit: itemsPerPages,
        page_number: currentPage,
        is_verified_status: BooleanState,
      };

      const response = await getApproverss(payload);
      setUsersData(response.data.user.usersResult);

      const totalUsers = response.data.user.count;

      const pages = Math.ceil(totalUsers / itemsPerPages);
      // console.log("pages", pages);

      setPageCount(pages);
      // console.log("response", response);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      setUsersData([]);

      // console.error("Error fetching data:", error);
    }
  };

 



  const handleUnverifiedButtonClick = (user) => {
    const email = user.email;
    SetUnverifiedEmail(email);
    setModalOpen(true);
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const handleClose = () => {
    setModalOpen(false);
    setModalOpen(false);
    setapproveOpen(false);
    setrejectOpen(false);
    setEyeOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);
    setapproveOpen(false);
    setEyeOpen(false);
    setrejectOpen(false);
  };
  const handleToggle2Factor = (value) => {
    setIs2FAEnabled(value);
  };
  const handleToggleActive = (value) => {
    setisactiveEnabled(value);
  };

  const openFactor = () => {
    setIsModalOpenFactor(true);
  };
  const handleApiChange = (event) => {
    const selectedOption = event.target.value;

    // console.log("selectedOption>>>>>", typeof selectedOption);
    // console.log("selectedOption>>>>>gggggg", selectedOption);

    setoptionSelect(selectedOption);
    // console.log("setoptionSelect>>>>>", setoptionSelect);

 
  };
  const reset = () => {
    setIdQuery("");
    setEmailQuery("");
    setNameQuery("");
    setoptionSelect("all");
    setIs2FAEnabled(false);
    setisactiveEnabled(false);
    setBooleanState("all");
    ResetUsers();

    // console.log("BooleanState 45454545454", BooleanState);
  };

  const handleResetClick = async () => {
    setResetOpen(false);
    try {
      setIsLoading(true);
      const payload = {
        email: emailReset,
        password: passwordReset,
      };
      const response = await resetPasswordAdmin(payload);
      if (response.data.status === "fail") {
        toast.error(response.data.message);
      } else {
        toast.success(response.data.message);
        setIsLoading(false);
        // console.log("response reset", response);
      }
    } catch (error) {
      toast.error(
        "Password at least one uppercase letter, one lowercase letter and one number and one special character and minimum 8 character"
      );
      setIsLoading(false);
      console.error(error);
    }
  };

  async function handleSubmit() {
    if (textInput.trim() === '') {
      toast.error('Text input is required.');
      return;
    }
  
    if (!fileInput) {
      toast.error('File upload is required.');
      return;
    }
  
    try {
      setIsLoading(true);
      const formData = new FormData();
      formData.append('user_id', UserMail);
      formData.append('approver_comment', textInput);
      formData.append('File', fileInput);
  
      if (approveOpen) {
        const response = await approveUser(formData);
        // console.log('Response:', response);
  
        if (response && response.status === 200) {
          // Assuming approveUser is an asynchronous function
          // and getApproversss returns a promise
          await getApproversss();
  
          toast.success('User is Approved successfully');
        } else {
          toast.error('Something Went Wrong');
        }
      } else {
        const formData1 = new FormData();
      formData.append('user_id', UserMail);
      formData.append('reject_reason', textInput);
      formData.append('File', fileInput);
        const response = await rejectUser(formData1);
        // console.log('Response:', response);
  
        if (response && response.status === 200) {
          // Assuming rejectUser is an asynchronous function
          // and getApproversss returns a promise
          await getApproversss();
  
          toast.success('User is rejected successfully');
        } else {
          toast.error('Something Went Wrong');
        }
      }
    } catch (error) {
      console.error('Error processing user:', error);
      toast.error('Error processing user. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }
  
 
  
  const handleReject = async () => {
    try {
      setIsLoading(true);
     const payload = {
      email : UserMail,
     }
      const response = await  rejectUser(payload)
      // console.log('response response',response);
      if (response.status === 200) {
        getApproversss();
        toast.success("User is rejected successfully");
        setIsLoading(false);
      } else {
        toast.error('Something Went Wrong');
        setIsLoading(false);
      }
    } catch (error) {
      console.error('Error rejecting user:', error);
    }
  };

  const handleApprove = async () => {
    try {
      setIsLoading(true);
      const payload = {
       email : UserMail,
      }
       const response = await  approveUser(payload)
//  console.log('response response',response);
       if (response.status === 200) {
         getApproversss()
         toast.success("User is Approved successfully");
         setIsLoading(false);
       } else {
        toast.error('Something Went Wrong');
       }
     } catch (error) {
       console.error('Error rejecting user:', error);
     }
  };

  const customPaddingStyle = {
    padding: " 6px 4px", 
  };
  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none",
          justifyContent: "center",
          alignItems: "center",
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          // background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>

<Approvertable />
      {/* <div>
        Render the Modal component
        <ProfileModal isOpen={isModalOpen} onClose={closeModal} />
      </div> */}

      {/* <TitleCard title="">
        <h3 className='mb-3'>Apply Filters</h3>

        <div className="flex">
          <div className="flex flex-col items-center justify-center mr-2 mb-2">
          <select
  onChange={handleApiChange}
  className="rounded-lg"
  style={customPaddingStyle}
  id="filters"
>
  <option value="all">All</option>
  <option value="pending">Pending</option>
  <option value="approved">Approved</option>
  <option value="rejected">Rejected</option>
</select>
          </div>
         

        <div className="overflow-x-auto w-full">
          <table className=" tbody table w-full">
            <thead>
              <tr>
                <th>Id#</th>
                <th>created</th>
                <th>Name</th>
                <th>Email</th>
                <th>Address </th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {usersData.length > 0 ? (
                usersData.map((user) => (
                  <tr key={user._id}>
                    <td>
                      <div className="flex items-center space-x-3">
                        <div>
                          <div className="font-bold">{user._id}</div>
                        </div>
                      </div>
                    </td>
                    <td className="hover-date">
                      {user.createdAt
                        ? moment(user.createdAt).fromNow()
                        : "___________"}
                      <span className="date-on-hover">
                        {user.createdAt
                          ? moment(user.createdAt).format("DD MMMM YYYY")
                          : "___________"}
                      </span>
                    </td>
                    <td>
                      {user.isverified === false ? user.firstName : user.name}
                    </td>
                    <td>{user.email}</td>
                    <td className="font-bold">
                    <button onClick={()=>handleCopyClick(user)} 
  className="ml-2 p-1"
  >
        {isCopied ? '✅' : '  📋'}
      </button>
  Extract the first 8 characters
  {user.address.slice(0, 4)}
  {'...'}
  Extract the remaining characters starting from the 8th position
  {user.address.slice(36)}
 
</td>
                    <td>
                    {user.approver_status === 'pending' ? (
  <>
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'between',
      }}
    >
      <button
        onClick={() => {
          setUserMail(user._id);
          setapproveOpen(true);
        }}
        style={{
          marginRight: '8px',
          backgroundColor: 'green',
          borderRadius: '50%',
          height: '40px',
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'between',
        }}
      >
        <FontAwesomeIcon icon={faCheck} style={{ color: 'white' }} />
        Approve
      </button>
      <button
        onClick={() => {
          setUserMail(user._id);
          setrejectOpen(true);
        }}
        style={{
          backgroundColor: 'red',
          color: 'white',
          borderRadius: '50%',
          height: '40px',
          padding: '8px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'between',
        }}
      >
        <FontAwesomeIcon icon={faTimes} style={{ color: 'white' }} />
        Reject
      </button>
    </div>
  </>
) : (
  <button
    className="text-lime-500 hover:underline"
    onClick={() =>{
       handleEyeButtonClick(user);
      setUserDocument(user.document_detail);
      setUserMessage(user?.document_detail?.admin_comment);
      setUserStatus(user.approver_status);
       setEyeOpen(true);
      }
      }
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  </button>
)}

</td>
                  
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6">No users available</td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        Pagination
        {pageCount > 1 && (
          <div className="flex items-center justify-center mt-4">
            <button
              onClick={() => setCurrentPage(currentPage - 1)}
              disabled={currentPage === 1}
              className={`flex items-center justify-center px-1 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
            >
              <span className="sr-only">Previous</span>
              <svg
                className="w-2.5 h-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M5 1 1 5l4 4"
                />
              </svg>
            </button>
            Pagination links
            {pageCount <= 3 ? (
              Array.from({ length: pageCount }).map((_, index) => (
                <button
                  key={index}
                  onClick={() => setCurrentPage(index + 1)}
                  className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                    currentPage === index + 1
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  }`}
                >
                  {index + 1}
                </button>
              ))
            ) : (
              <>
                {currentPage > 1 && (
                  <>
                    <button
                      onClick={() => setCurrentPage(1)}
                      className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                        currentPage === 1
                          ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                          : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                    >
                      1
                    </button>
                    {currentPage > 3 && <span className=" dots ">....</span>}
                  </>
                )}
                {currentPage > 2 && (
                  <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                      currentPage === currentPage - 1
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                  >
                    {currentPage - 1}
                  </button>
                )}
                <button
                  className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                    currentPage === currentPage
                      ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                      : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                  }`}
                >
                  {currentPage}
                </button>
                {currentPage < pageCount - 1 && (
                  <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                      currentPage === currentPage + 1
                        ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                        : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                    }`}
                  >
                    {currentPage + 1}
                  </button>
                )}
                {currentPage < pageCount && (
                  <>
                    {currentPage < pageCount - 2 && (
                      <span className="dots">....</span>
                    )}
                    <button
                      onClick={() => setCurrentPage(pageCount)}
                      className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${
                        currentPage === pageCount
                          ? "text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100"
                          : "text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                      }`}
                    >
                      {pageCount}
                    </button>
                  </>
                )}
              </>
            )}
            <button
              onClick={() => setCurrentPage(currentPage + 1)}
              disabled={currentPage === pageCount}
              className={`flex items-center justify-center px-1 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white`}
            >
              <span className="sr-only">Next</span>
              <svg
                className="w-2.5 h-2.5"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 6 10"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m1 9 4-4-4-4"
                />
              </svg>
            </button>
          </div>
        )}
      </TitleCard> */}
      <Modal
        open={ModalOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        {/* <div className="bg-black bg-opacity-50 absolute inset-0"></div> */}
        <div className="custom-dialog bg-white bg-opacity-20 w-72 rounded-lg p-4">
          <p className="text-black font-semibold text-lg mb-4">
            Are you sure you want to change the status?
          </p>
          <div className="flex justify-end">
            <button
              onClick={handleCancel}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
            >
              No
            </button>
            <button
              // onClick={handleconfirm}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={isModalOpenFactor}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        <div className="custom-dialog bg-white bg-opacity-20 w-72 rounded-lg p-4">
          <p className="text-black font-semibold text-lg mb-4">
            Are you sure you want to change the Two-Factor Status?
          </p>
          <div className="flex justify-end">
            <button
              onClick={() => {
                setIsModalOpenFactor(false);
              }}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
            >
              No
            </button>
            <button
              onClick={() => {
                setIsModalOpenFactor(false);
                // handlleToggle2Factor(selectedStatus, selectedUserId);
              }}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={resetOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        <div className="custom-dialog bg-white bg-opacity-20 w-72 rounded-lg p-4">
          <p className="text-black font-semibold text-lg mb-4">
            Reset Password
          </p>
          <div className="mb-4">
            <label
              htmlFor="email"
              className="block text-sm font-medium text-gray-700"
            >
              Email
            </label>
            <input
              type="email"
              id="email"
              className="mt-1 p-2 border rounded w-full"
              placeholder="Enter your email"
              value={emailReset}
              onChange={(e) => setEmailReset(e.target.value)}
            />
          </div>
          <div className="mb-4 relative">
            <label
              htmlFor="password"
              className="block text-sm font-medium text-gray-700"
            >
              New Password
            </label>
            <input
              type={showPassword ? "text" : "password"}
              id="password"
              className="mt-1 p-2 pr-10 border rounded w-full"
              placeholder="Enter your new password"
              value={passwordReset}
              onChange={(e) => setPasswordReset(e.target.value)}
            />
            <button
              type="button"
              // onClick={togglePasswordVisibility}
              className="absolute inset-y-0 right-0 mr-2 flex items-center  focus:outline-none"
            >
              <FontAwesomeIcon
                className="mt-8"
                icon={showPassword ? faEye : faEyeSlash}
              />
            </button>
          </div>
          <div className="flex justify-between">
            <button
              // type="button"
              // onClick={handleCancelClick}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
            >
              Cancel
            </button>
            <button
              // type="button"
              onClick={handleResetClick}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              Reset
            </button>
          </div>
        </div>
      </Modal>



      
      <Modal
        open={approveOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        <div className="custom-dialog bg-white bg-opacity-20 w-72 rounded-lg p-4">
          <p className="text-black font-semibold text-lg mb-4"> Are you sure you want to Approve the User ?</p>
          {/* Text Input Field */}
          <div className="mb-4">
            <label htmlFor="textInput" className="text-gray-600 text-sm">
              Approver Reason
            </label>
            <input
              type="text"
              id="textInput"
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Enter text..."
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              required // Add the required attribute here
            />
          </div>

          {/* Document Upload Field */}
          <div className="mb-4">
            <label htmlFor="fileInput" className="text-gray-600 text-sm">
              Upload Document:
            </label>
            <input
              type="file"
              id="fileInput"
              accept=".pdf, .doc, .docx"
              onChange={(e) => setFileInput(e.target.files[0])}
              className="form-control-file"
              required // Add the required attribute here
            />
          </div>

          <div className="flex justify-end">
            <button
              onClick={handleCancel}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
            >
              No
            </button>
            <button
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={rejectOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
      >
        <div className="custom-dialog bg-white bg-opacity-20 w-72 rounded-lg p-4">
          <p className="text-black font-semibold text-lg mb-4"> Are you sure you want to Reject the Store ?</p>
          <div className="mb-4">
            <label htmlFor="textInput" className="text-gray-600 text-sm">
              Rejection Reason
            </label>
            <input
              type="text"
              id="textInput"
              className="w-full border border-gray-300 rounded p-2"
              placeholder="Enter text..."
              value={textInput}
              onChange={(e) => setTextInput(e.target.value)}
              required // Add the required attribute here
            />
          </div>

          {/* Document Upload Field */}
          <div className="mb-4">
            <label htmlFor="fileInput" className="text-gray-600 text-sm">
              Upload Document:
            </label>
            <input
              type="file"
              id="fileInput"
              accept=".pdf, .doc, .docx"
              onChange={(e) => setFileInput(e.target.files[0])}
              className="form-control-file"
              required // Add the required attribute here
            />
          </div>

          <div className="flex justify-end">
            <button
              onClick={handleCancel}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
            >
              No
            </button>
            <button
              onClick={handleSubmit}
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              Yes
            </button>
          </div>
        </div>
      </Modal>

      <Modal
        open={EyeOpen}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fixed inset-0 flex items-center justify-center"
        style={{
          width: '30%',
        }}
      >
        <div className="custom-dialog bg-dark bg-opacity-20 w-72 rounded-lg p-4">
          <p className="text-black font-semibold text-lg mb-4 text-white text-center"> Details</p>
          <div className="mb-4 d-flex justify-between">
            <label htmlFor="textInput" className="text-white text-sm">
              Status   
            </label>
           <p>
           {UserStatus}
           </p>
          </div>

          {/* Document Upload Field */}
          <div className="mb-4 d-flex justify-between">
  <label htmlFor="textInput" className="text-white text-sm">
    Admin Document
  </label>
  <div className="flex items-center">
    {/* <a
      href={UserDocument.admin_document}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-500 hover:underline cursor-pointer mr-2"
    >
      View
    </a> */}
    <button
      onClick={() => downloadPdf(UserDocument[0]?.admin_document, 'admin_document.pdf')}
      className="bg-blue-500 text-white px-2 py-1 rounded cursor-pointer"
    >
      Download
    </button>
  </div>
</div>

          <div className="mb-4 d-flex justify-between">
            <label htmlFor="textInput" className="text-white text-sm">
              Admin Comment   
            </label>
           <p>
           {UserDocument[0]?.admin_comment} 
           {/* {UserMessage} */}
           </p>
          </div>
          <div className="mb-4 d-flex justify-between">
  <label htmlFor="textInput" className="text-white text-sm">
    User Document
  </label>
  <div className="flex items-center">
    {/* <a
      href={UserDocument.user_document}
      target="_blank"
      rel="noopener noreferrer"
      className="text-blue-500 hover:underline cursor-pointer mr-2"
    >
      View
    </a> */}
    <button
      onClick={() => downloadPdf(UserDocument[0]?.user_document, 'document.pdf')}
      className="bg-blue-500 text-white px-2 py-1 rounded cursor-pointer"
    >
      Download
    </button>
  </div>
</div>


          <div className="mb-4 d-flex justify-between">
            <label htmlFor="textInput" className="text-white text-sm">
              User Comment   
            </label>
           <p>
          {UserDocument[0]?.user_comment }
           </p>
          </div>
          <div className="flex justify-end">
          
            <button
              onClick={handleSubmit}
              hidden
              className="bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded"
            >
              Yes
            </button>
            <button
              onClick={handleCancel}
              className="bg-gray-200 hover:bg-gray-300 text-gray-700 py-2 px-4 rounded mr-2"
            >
             Close
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default ApproversList;

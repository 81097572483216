import React, { useState, useEffect } from 'react';
import { Col } from 'react-bootstrap';
import {
  Card, CardBody,
} from '@/shared/components/Card';
import Button from 'react-bootstrap/Button';
import Chat from './Chat';
import myContacts from './contacts';
import mappedResponse from './tickets';
import { getTickets } from '../../../../utils/services/user.service';

const ChatCard = () => {
  const [tickets, setTickets] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize] = useState(10);
  const [totalTickets, setTotalTickets] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const payload = {
          status: 'all',
          limit: pageSize,
          page_number: pageNumber,
        };

        const response = await getTickets(payload);

        if (response.data.tickets) {
          setTickets(response.data.tickets);
          setTotalTickets(response.data.count);
        }
      } catch (error) {
        console.error('Error fetching tickets:', error);
      }
    };

    
    fetchData();
  }, [pageNumber, pageSize]);
  const fetchData = async () => {
    try {
      const payload = {
        status: 'all',
        limit: pageSize,
        page_number: pageNumber,
      };

      const response = await getTickets(payload);

      if (response.data.tickets) {
        setTickets(response.data.tickets);
        setTotalTickets(response.data.count);
      }
    } catch (error) {
      console.error('Error fetching tickets:', error);
    }
  };

  const loadMoreTickets = () => {
    setPageNumber(pageNumber + 1);
  };

  const loadLessTickets = () => {
    setPageNumber(pageNumber - 1);
  };

  return (
    <Col md={12} lg={12} className="mb-2">
      <Card>
        <CardBody className="pb-0">
          <Chat
            contacts={tickets}
            count={totalTickets}
            fetchData={fetchData}
            currentUser={{
              avatar: `${process.env.PUBLIC_URL}/img/ava.png`,
              name: 'Roman Johanson',
              userName: 'dragonKing',
            }}
          />
        <div className="d-flex justify-content-start">
  {pageNumber > 1 && (
    <Button
      variant="dark"
      style={{ fontSize: '14px' }}
      className="purple-button mt-2 py-2 text-capitalize"
      onClick={loadLessTickets}
    >
      <i className="fa fa-arrow-left" aria-hidden="true" /> Previous
    </Button>
  )}

  <Button
    variant="dark"
    style={{ fontSize: '14px', background: 'transparent' }}
    className="purple-button mt-2 py-2 text-capitalize mx-2"
  >
    Page {pageNumber} of {Math.ceil(totalTickets / pageSize)}
  </Button>

  {tickets.length < totalTickets && (
    <Button
      variant="dark"
      style={{ fontSize: '14px' }}
      className="purple-button mt-2 py-2 text-capitalize mx-2"
      onClick={loadMoreTickets}
    >
      Load More <i className="fa fa-refresh" aria-hidden="true" />
    </Button>
  )}
</div>

        </CardBody>
      </Card>
    </Col>
  );
};

export default ChatCard;

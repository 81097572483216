import { Modal as BootstrapModal, Button } from "react-bootstrap";

const LocationInfoModal = ({ hide, show, data }) => {

    return (
        <BootstrapModal
            show={show}
            onHide={hide}
            centered
            className="dark:bg-gray-800"
        >
            <BootstrapModal.Header
                closeButton
                className="text-black dark:border-gray-700 "
            >
                <BootstrapModal.Title>Location Details</BootstrapModal.Title>
            </BootstrapModal.Header>
            <BootstrapModal.Body className="dark:text-white ">
                {data.location?.length > 0 ? (
                    <div className="flex flex-col gap-2"> 
                        <p className="text-black">
                            <strong>Continent:</strong> {data.location[0]?.continent}
                        </p>
                        <p className="text-black">
                            <strong>Country:</strong> {data.location[0]?.country}
                        </p>
                        <p className="text-black">
                            <strong>Capital:</strong> {data.location[0]?.capital}
                        </p>

                        <p className="text-black">
                            <strong>City:</strong> {data.location[0]?.city}
                        </p>
                        <p className="text-black">
                            <strong>Ip Address:</strong> {data.location[0]?.ip}
                        </p>
                        <p className="text-black">
                            <strong>Longitude:</strong> {data.location[0]?.longitude}
                        </p>
                        <p className="text-black">
                            <strong>Latitude:</strong> {data.location[0]?.latitude}
                        </p>

                    </div>
                ) : (
                    <h3 className="text-black">No data found!</h3>
                )}
            </BootstrapModal.Body>
            <BootstrapModal.Footer className="dark:bg-gray-800 dark:border-t dark:border-gray-700">
                <Button variant="secondary" className="text-black" onClick={hide}>
                    Close
                </Button>
            </BootstrapModal.Footer>
        </BootstrapModal>
    );
};

export default LocationInfoModal;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import ProgressBar from '@/shared/components/ProgressBar';
import { Card } from '@/shared/components/Card';
import {
  MobileAppWidgetLine,
  MobileAppWidgetStat,
  MobileAppWidgetTitle,
  MobileWidgetIconUp,
} from '../MobileWidgetDashboardElements';
import {
  DashboardWidgetCard,
} from '../../BasicDashboardComponents';

const ActiveUsersShort = ({verfied_users}) => {
  const { t } = useTranslation('common');

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <DashboardWidgetCard className='bg-success'>
          <div>
            <MobileAppWidgetLine className=" mobile-app-widget__top-line--lime">
              <MobileAppWidgetStat className="text-white">{verfied_users}</MobileAppWidgetStat>
              <MobileWidgetIconUp />
            </MobileAppWidgetLine>
            <MobileAppWidgetTitle>
              <h5 className="text-white">{t('Verified Users')}</h5>
            </MobileAppWidgetTitle>
            {/* <ProgressBar now={32} label="32%" top gradient="lime" size="small" /> */}
          </div>
        </DashboardWidgetCard>
      </Card>
    </Col>
  );
};

export default ActiveUsersShort;

import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import TransactionsTable from './component/TransactionsTable';
import { getToken, getTransactionDetails } from '../../../utils/services/user.service';
import { Ring } from '@uiball/loaders';
import styled from "styled-components";
import { CustomSelect } from '../../../shared/components/CustomSelect';

const Transactions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [allSymbols, setAllSymbols] = useState([]);
  const [pageCount, setPageCount] = useState(1);
  const ITEMS_PER_PAGE = 20;
  const [filters, setFilters] = useState({
    status: 'all',
    type: 'all',
    symbol: 'all',
    Beneficiary: '',
    Transfer: ''
  });
  const modifiedResponse = allSymbols.map((item) => ({
    label: item.symbol,
    value: item.symbol,
  }));



  useEffect(() => {
    const fetchData = async () => {
      try {
        const fees = await getToken();
        const data = fees?.data?.user;
        setAllSymbols(data);
      } catch (error) {

        console.error("Error fetching fees:", error);
      }
    };

    fetchData();
    setCurrentPage(1);

  }, [filters]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const payload = {
          limit: ITEMS_PER_PAGE,
          page_number: currentPage,
          ...filters
        };

        const response = await getTransactionDetails(payload);
        if (response.data.result.transaction) {
          const storeData = response.data.result.transaction;
          const totalUsers = response.data.result.count;
          const pages = Math.ceil(totalUsers / ITEMS_PER_PAGE);
          setPageCount(pages);
          setData(storeData);
          setIsLoading(false);

        } else {
          setIsLoading(false);
          console.error('Error fetching store details:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [currentPage, filters]);

  const statusOptions = [
    { label: "All", value: "all" },
    { label: "Completed", value: "Completed" },
    { label: "Declined", value: "Declined" },
    { label: "Rejected", value: "Rejected" },
    { label: "Pending", value: "Pending" },
    { label: "Failed", value: "Failed" },
  ];
  const typeOptions = [
    { label: "All", value: "all" },
    { label: "Transaction", value: "transaction" },
    { label: "Contract", value: "contract" },
  ];


  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? 'flex' : 'none', // Use flex display when loading
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          // background: 'rgba(255, 255, 255, 0.8)',
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <Container>
        <Row className='h-full'>



          <Col xs={12} md={4} lg={3}>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-0 mt-2"
            >
              Status
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={filters.status}
                  onChange={(value) => {
                    setFilters(prevFilters => ({
                      ...prevFilters, // Retain the existing filters
                      status: value.value // Update only the status filter
                    }));
                  }}

                  options={statusOptions}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>

          <Col xs={12} md={4} lg={3}>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-0 mt-2"
            >
              Type:
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={filters.type}
                  onChange={(value) => {
                    setFilters(prevFilters => ({
                      ...prevFilters, // Retain the existing filters
                      type: value.value // Update only the status filter
                    }));
                  }}

                  options={typeOptions}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>

          <Col xs={12} md={4} lg={3}>
            <label
              style={{ fontSize: "small" }}
              htmlFor="features"
              className="form-label text-white mb-0 mt-2"
            >
              Symbol:
            </label>
            <InputDiv className="px-0">
              <DropdownDiv
                className="mb-0"
                style={{ textTransform: "Capitalize" }}
              >
                <CustomSelect
                  name="size"
                  id="size"
                  className="w-25 px-0"
                  value={filters.symbol}
                  onChange={(value) => {
                    setFilters(prevFilters => ({
                      ...prevFilters, // Retain the existing filters
                      symbol: value.value // Update only the status filter
                    }));
                  }}

                  options={modifiedResponse}
                />
              </DropdownDiv>
            </InputDiv>
          </Col>

          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-1"
              >
                Beneficiary:
              </label>
              <ChatSearchInput
                type="text"
                value={filters.Beneficiary}
                className="form-control mt-3"
                placeholder="Search by Benificiary"
                onChange={(value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters, // Retain the existing filters
                    Beneficiary: value.value // Update only the status filter
                  }));
                }}
              />
            </InputDiv>
          </Col>

          <Col xs={12} md={4} lg={3}>
            <InputDiv>
              <label
                style={{ fontSize: "small" }}
                htmlFor="features"
                className="form-label text-white mb-1"
              >
                Transfer:
              </label>
              <ChatSearchInput
                type="text"
                value={filters.Transfer}
                className="form-control mt-3"
                placeholder="Search by Transfer"
                onChange={(value) => {
                  setFilters(prevFilters => ({
                    ...prevFilters, // Retain the existing filters
                    Transfer: value.value // Update only the status filter
                  }));
                }}
              />
            </InputDiv>
          </Col>


          <div className='flex flex-col mt-3'>
            <TransactionsTable Data={data} />
            {pageCount > 1 && (
              <div className="flex items-center justify-center mt-1 mb-4">
                <button
                  onClick={() => setCurrentPage(currentPage - 1)}
                  disabled={currentPage === 1}
                  className="flex items-center justify-center px-1 h-8 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <span className="sr-only">Previous</span>
                  <svg
                    className="w-2.5 h-2.5"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 6 10"
                  >
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
                  </svg>
                </button>
                {/* Pagination links */}
                {pageCount <= 3 ? (
                  Array.from({ length: pageCount }).map((_, index) => (
                    <button
                      key={index}
                      onClick={() => setCurrentPage(index + 1)}
                      className={`mx-1 py-1 px-2  rounded-md cursor-pointer ${currentPage === index + 1
                        ? 'text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100'
                        : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                        }`}
                    >
                      {index + 1}
                    </button>
                  ))
                ) : (
                  <>
                    {currentPage > 1 && (
                      <>
                        <button
                          onClick={() => setCurrentPage(1)}
                          className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${currentPage === 1
                            ? 'text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100'
                            : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                            }`}
                        >
                          1
                        </button>
                        {currentPage > 3 && <span className="dots">....</span>}
                      </>
                    )}
                    {currentPage > 2 && (
                      <button
                        onClick={() => setCurrentPage(currentPage - 1)}
                        className={`mx-1 py-1 px-2  rounded-md cursor-pointer ${currentPage === currentPage - 1
                          ? 'text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100'
                          : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                          }`}
                      >
                        {currentPage - 1}
                      </button>
                    )}
                    <button
                      className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${currentPage === currentPage
                        ? 'text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100'
                        : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                        }`}
                    >
                      {currentPage}
                    </button>
                    {currentPage < pageCount - 1 && (
                      <button
                        onClick={() => setCurrentPage(currentPage + 1)}
                        className={`mx-1 py-1 px-2  rounded-md cursor-pointer ${currentPage === currentPage + 1
                          ? 'text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100'
                          : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                          }`}
                      >
                        {currentPage + 1}
                      </button>
                    )}
                    {currentPage < pageCount && (
                      <>
                        {currentPage < pageCount - 2 && <span className="dots">....</span>}
                        <button
                          onClick={() => setCurrentPage(pageCount)}
                          className={`mx-1 py-1 px-2 rounded-md cursor-pointer ${currentPage === pageCount
                            ? 'text-blue-600 border-blue-300 bg-blue-50 hover:bg-blue-100'
                            : 'text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white'
                            }`}
                        >
                          {pageCount}
                        </button>
                      </>
                    )}
                  </>
                )}
                <button
                  onClick={() => setCurrentPage(currentPage + 1)}
                  disabled={currentPage === pageCount}
                  className="flex items-center justify-center px-1 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                >
                  <span className="sr-only">Next</span>
                  <svg class="w-1.5 h-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                  </svg>
                </button>
              </div>
            )}
          </div>
        </Row>

      </Container>
    </>
  );
};

export default Transactions;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;

const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

import React, { useEffect, useState } from 'react';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { editCategory_admin } from '../../../utils/services/user.service';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Modal as BootstrapModal } from "react-bootstrap";

const EditCategories = ({ editingCategory, getCategorys, CloseModal, isEditModalOpen }) => {
  const [categoryName, setCategoryName] = useState('');
  const [subCategories, setSubCategories] = useState([]);
  const [newSubCategory, setNewSubCategory] = useState('');
  const [newCustomDetail, setNewCustomDetail] = useState('');
  const [customDetails, setCustomDetails] = useState([]);
  const [category_id, setcategory_id] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCategoryName(editingCategory.category_name || '');
    setcategory_id(editingCategory._id || '');
    setSubCategories([...editingCategory.sub_categories] || []);
    setCustomDetails([...editingCategory.custom_detail] || []);
  }, [editingCategory]);

  const handleCategoryNameChange = (e) => {
    const inputValue = e.target.value;
    // console.log('inputValue CategoryName', inputValue)

    const formattedValue = inputValue.trim().replace(/\s+/g, '_').toLowerCase();
    setCategoryName(formattedValue);
  };

  const handleNewSubCategoryChange = (e) => {
    const inputValue = e.target.value;
    // console.log('inputValue NewSubCategory', inputValue);

    const formattedValue = inputValue.trim().replace(/\s+/g, '_').toLowerCase();
    // console.log('formattedValue NewSubCategory', formattedValue);

    setNewSubCategory(formattedValue);
  };

  const handleAddSubCategory = () => {
    if (newSubCategory.trim() !== '') {
      if (!subCategories.includes(newSubCategory)) {
        setSubCategories([...subCategories, newSubCategory]);
        setNewSubCategory('');
        // console.log('Updated SubCategories:', subCategories);
      } else {
        toast.error('SubCategory already exists');
      }
    }
  };

  const handleRemoveSubCategory = (index) => {
    const updatedSubCategories = [...subCategories];
    updatedSubCategories.splice(index, 1);
    setSubCategories(updatedSubCategories);
  };

  const handleNewCustomDetailChange = (e) => {
    const inputValue = e.target.value;
    // console.log('inputValue NewCustomDetail', inputValue);

    const formattedValue = inputValue.trim().replace(/\s+/g, '_').toLowerCase();
    // console.log('formattedValue NewCustomDetail', formattedValue);

    setNewCustomDetail(formattedValue);
  };

  const handleAddCustomDetail = () => {
    if (newCustomDetail.trim() !== '') {
      if (!customDetails.includes(newCustomDetail)) {
        setCustomDetails([...customDetails, newCustomDetail]);
        setNewCustomDetail('');
        // console.log('Updated CustomDetails:', customDetails);
      } else {
        toast.error('CustomDetail already exists');
      }
    }
  };

  const handleRemoveCustomDetail = (index) => {
    const updatedCustomDetails = [...customDetails];
    updatedCustomDetails.splice(index, 1);
    setCustomDetails(updatedCustomDetails);
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    if (categoryName.trim() === '') {
      toast.error('Category name is empty')
      // console.error("Category name is empty");
    } else if (subCategories.length === 0) {
      toast.error('Sub-categories are empty')
      // console.error("Sub-categories are empty");
    } else if (customDetails.length === 0) {
      toast.error('Custom details are empty')
      // console.error("Custom details are empty");
    } else {
      const subCategoryValues = subCategories
        .filter(item => typeof item === 'object' && item !== null)
        .map(item => item.sub_category_name);
      // console.log('subCategories edit ',subCategories)
      const resultArraysubCategories = subCategories.map((element, index) => {
        // console.log('element',element)
        if (element.hasOwnProperty('sub_category_name')) {
          return element.sub_category_name;
        } else {
          return element;
        }
      });
      // console.log('resultArray edit ',resultArraysubCategories)
      const resultArraycustomDetails = customDetails.map((element) => {
        if (typeof element === 'object' && element !== null) {
          // If the element is an object, extract and return the first value from the object
          const valuesArray = Object.values(element);
          return valuesArray.length > 0 ? valuesArray[0] : element;
        } else if (typeof element === 'string') {
          // If the element is a string, return the string itself
          return element;
        } else {
          // If the element is neither an object nor a string, return it as is
          return element;
        }
      });
      // console.log('resultArraycustomDetails edit ',resultArraycustomDetails)

      const formData = new FormData();
      formData.append('category_name', categoryName);
      formData.append('category_id', category_id);
      formData.append('sub_categories', JSON.stringify(subCategoryValues));



      const payload = {
        category_name: categoryName,
        category_id: category_id,
        sub_categories: resultArraysubCategories,
        custom_detail: resultArraycustomDetails,
      };

      // console.log('paylaod edit ',payload)
      // Now, you can use JSON.stringify to convert the object to a JSON string
      // const jsonData = JSON.stringify(payload);


      try {
        setIsLoading(true)
        const response = await editCategory_admin(payload)
        // console.log("response",response)

        if (response.data.status === 200) {
          toast.success(response.data.Message)
          setCategoryName('')
          setSubCategories([])
          setCustomDetails([])


          getCategorys()
          setIsLoading(false)
        } else {
          toast.error(response.data.Message)
        }
      } catch (error) {
        toast.error("Category is already exists")

        setIsLoading(false)
      }
    }
  };

  return (
    <>


      <BootstrapModal show={isEditModalOpen} onHide={CloseModal} centered>


        <BootstrapModal.Header
          closeButton
          className="dark:border-gray-700 bg-dark text-white"
        >
          <BootstrapModal.Title>Edit Category</BootstrapModal.Title>
        </BootstrapModal.Header>

        <BootstrapModal.Body className="dark:text-white bg-dark text-white">
          <form className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4 px-6">
            <div className="mb-1 w-full">
              <label htmlFor="categoryName" className="block text-white">
                Category Name:
              </label>
              <input
                type="text"
                id="categoryName"
                value={categoryName}
                onChange={handleCategoryNameChange}
                className="w-full border border-gray-300 p-1 text-dark rounded-md"
                required
              />
            </div>


            <div className="col-span-2 mb-2">
              <label htmlFor="newSubCategory" className="block text-white">
                Sub Category:
              </label>
              <div className="flex">
                <input
                  type="text"
                  id="newSubCategory"
                  value={newSubCategory}
                  onChange={handleNewSubCategoryChange}
                  className="w-full border text-dark border-gray-300 p-1 rounded-l-md"
                />
                <div onClick={handleAddSubCategory} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r-md">
                  Add
                </div>
              </div>
            </div>



            <div className="col-span-2 mb-2">
              <label htmlFor="newCustomDetail" className="block text-white">
                Custom Detail:
              </label>
              <div className="flex">
                <input
                  type="text"
                  id="newCustomDetail"
                  value={newCustomDetail}
                  onChange={handleNewCustomDetailChange}
                  className="w-full text-dark border border-gray-300 p-1 rounded-l-md"
                />
                <div onClick={handleAddCustomDetail} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-r-md">
                  Add
                </div>
              </div>
            </div>


            <div className="col-span-2">
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">Sub Categories list:{subCategories.length}</h3>
                  <div className="max-h-10 border border-white overflow-y-auto rounded-md">
                    <ul>
                      {Array.isArray(subCategories) &&
                        subCategories.map((subCategory, index) => (
                          <li key={index} className="flex justify-between items-center  rounded-lg shadow-md text-white">
                            {subCategory?.sub_category_name ? (
                              <>{subCategory?.sub_category_name}</>
                            ) : (
                              <>{subCategory}</>
                            )}
                            <button type="button" onClick={() => handleRemoveSubCategory(index)} className="mr-1 rounded-full">
                              <FontAwesomeIcon icon={faTimes} size="sm" style={{ color: "white" }} />
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>

                <div>
                  <h3 className="text-lg font-semibold mb-2 text-white">Custom Details List:{customDetails.length}</h3>
                  <div className="max-h-10 border border-white overflow-y-auto rounded-md">
                    <ul>
                      {Array.isArray(customDetails) &&
                        customDetails.map((customDetail, index) => (
                          <li key={index} className="flex justify-between items-center  rounded-lg shadow-lg text-white">
                            {Object.values(customDetail) ? (
                              <>{Object.values(customDetail)}</>
                            ) : (
                              <>{customDetail}</>
                            )}
                            <button type="button" onClick={() => handleRemoveCustomDetail(index)} className="rounded-full mr-1">
                              <FontAwesomeIcon icon={faTimes} size="sm" style={{ color: "white" }} />
                            </button>
                          </li>
                        ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>



          </form>
        </BootstrapModal.Body>
        <BootstrapModal.Footer className="dark:bg-gray-800 bg-dark dark:border-t dark:border-gray-700">
          <div className="w-full mt-1 mb-2 flex justify-between">

            <button
              variant="outlined"
              className="w-16 h-8 text-sm border border-red-500 hover:bg-red-500 hover:text-white text-red-500 rounded-md"
              onClick={CloseModal}
            >
              Close
            </button>

            <button
              type="submit"
              onClick={handleSubmit}
              // className="bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600"
              className="w-32 h-10 text-sm border border-red-500 hover:bg-blue-500 hover:text-white text-blue-500 rounded-md"
            >
              Save
            </button>
          </div>
        </BootstrapModal.Footer>

      </BootstrapModal>


    </>
  )
}

export default EditCategories
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import CurrentUsers from './components/CurrentUsers';
import ActiveUsers from './components/ActiveUsers';
import SessionShort from './components/SessionShort';
import ActiveUsersShort from './components/ActiveUsersShort';
import NewUsersShort from './components/NewUsersShort';
import PageViewsShort from './components/PageViewsShort';
import AppTileClicks from './components/AppTileClicks';
import WeeklyStatMobile from './components/WeeklyStatMobile';
import SocialMarketing from './components/SocialMarketing';
import ProductSales from './components/ProductSales';
import RecentOrders from './components/RecentOrders';
import {userDetails,userGraph,recentUser} from '../../../utils/services/user.service'
import { Ring } from '@uiball/loaders';
const AppDashboard = () => {
  const { t } = useTranslation('common');
  const rtl = useSelector(state => state.rtl);
  const [isLoading, setIsLoading] = useState(false);
  const [Data, setData] = useState([]);

  const [GraphData, setGraphData] = useState([]);
  const [RecentUser, setRecentUser] = useState([]);


//console.log("Data",Data )
//console.log("GraphData",GraphData )
//console.log("RecentUser",RecentUser )

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true)
        const Response = await userDetails();
        if (Response?.data?.result) {
          const storeData = Response?.data?.result;
          //console.log('Store Data:', storeData);
          setData(storeData);
        } else {
          console.error('Error fetching store details:', Response.statusText);
        }
        const userGraphResponse = await userGraph();
        if (userGraphResponse?.data?.result?.users) {
          const userGraphData = userGraphResponse?.data?.result?.users;
          //console.log('Store Data:', userGraphData);
          setGraphData(userGraphData);
        } else {
          console.error('Error fetching store details:', userGraphResponse.statusText);
        }
        const recentUserResponse = await recentUser();
        if (recentUserResponse?.data?.result?.users) {
          const recentUserData = recentUserResponse?.data?.result?.users;
          // console.log('Store Data:', recentUserData);
          setRecentUser(recentUserData);
        } else {
          console.error('Error fetching store details:', userGraphResponse.statusText);
        }
        setIsLoading(false)
      } catch (error) {
        console.error('Error fetching data:', error);
        setIsLoading(false)
      }
    };
  
    fetchData();
  }, []);
const total_users = Data?.total_users;
const verfied_users = Data?.verfied_users;
const unverfied_users = Data?.unverfied_users;
const active_users = Data?.active_users;
const user_sub_Account = Data?.user_sub_Account;


  return (
    <><div
    className="loader-overlay"
    style={{
display: isLoading ? 'flex' : 'none', 
justifyContent: 'center', 
alignItems: 'center', 
position: 'fixed',
top: 0,
left: 0,
width: '100%',
height: '100%',
// background: 'rgba(255, 255, 255, 0.8)',
zIndex: 999,
}}
  >
    <Ring size={65} color="#00897B" />
  </div>
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('app_dashboard.page_title')}</h3>
        </Col>
      </Row>
      <Row>
        <NewUsersShort total_users={total_users}  />
        <PageViewsShort user_sub_Account={user_sub_Account} />
        <ActiveUsersShort verfied_users={verfied_users} />
        <SessionShort unverfied_users={unverfied_users}  />
      </Row>
      <Row>
        <ProductSales GraphData={GraphData} />
        {/* <CurrentUsers /> */}
        {/* <ActiveUsers dir={rtl.direction} /> */}
      </Row>
      <Row>
        {/* <AppTileClicks dir={rtl.direction} />
        <WeeklyStatMobile />
        <SocialMarketing /> */}
        <RecentOrders RecentUser={RecentUser} />
      </Row>
    </Container>
    </>
  );
};

export default AppDashboard;

import { useEffect, useState } from "react";
import { getNewsAdmin } from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import AddNews from "./AddNews";
import DeleteNews from "./DeleteNews";
import EditNews from "./EditNews";
import moment from "moment";

const AllNews = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [allNewsData, setAllNewsData] = useState([]);
  const [show, setShow] = useState(false);
  const [reFetch, setRefetch] = useState(false);
  const [newsData, setNewsData] = useState([]);
  const [editData, setEditData] = useState([]);
  const [showDelete, setShowDelete] = useState(false);
  const [showEdit, setShowEdit] = useState(false);

  const fetchAllNews = async () => {
    setIsLoading(true);
    try {
      const payload = { limit: 10000, page_number: 1 };
      const res = await getNewsAdmin(payload);
      setAllNewsData(res.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching news:", error);
      // Handle error here, such as displaying an error message to the user
    }
  };

  useEffect(() => {
    fetchAllNews();
  }, [reFetch]);

  const header = [
    { accessor: "news_id", Header: "News Id" },
    { accessor: "createdAt", Header: "Date Created" },
    { accessor: "title", Header: "Title" },
    { accessor: "description", Header: "Description" },
    { accessor: "image", Header: "Image" },
    { accessor: "action", Header: "Action" },
  ];
  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    isPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    withPagination: true,
  };

  const data = [];
  allNewsData?.data?.map((item) =>
    data.push({
      news_id: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item._id}
        </h6>
      ),

      title: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.title || "--"}
        </h6>
      ),
      createdAt: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          { moment(item.createdAt).format('ll') || "--"}
        </h6>
      ),
      image: <img src={item.images[0]} alt={item.images[0]} />,
      description: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.description || "--"}
        </h6>
      ),
      action: (
        <div className="flex flex-col md:flex-row gap-1 justify-center items-center">
          <FormButton
            variant={"outline-success"}
            className="mt-2"
            onClick={() => {
              setEditData(item);
              setShowEdit(true);
            }}
          >
            Edit
          </FormButton>
          <FormButton
            variant={"outline-fail"}
            className="mt-2"
            onClick={() => {
              setShowDelete(true);
              setNewsData(item._id);
            }}
          >
            Delete
          </FormButton>
        </div>
      ),
    })
  );
  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>

      <h3>News</h3>

      <FormButton
        variant={"outline-success"}
        className="mt-2"
        onClick={() => {
          setShow(true);
        }}
      >
        Add News
      </FormButton>

      <div className="overflow-x-auto w-full mt-2">
        <ReactTableBase
          columns={header}
          data={data}
          tableConfig={tableConfi2}
        />
      </div>

      {show && (
        <AddNews
          show={show}
          hide={() => setShow(false)}
          reFetch={() => setRefetch(!reFetch)}
        />
      )}
      {showDelete && (
        <DeleteNews
          show={showDelete}
          hide={() => setShowDelete(false)}
          reFetch={() => setRefetch(!reFetch)}
          data={newsData}
        />
      )}
      {showEdit && (
        <EditNews
          show={showEdit}
          hide={() => setShowEdit(false)}
          reFetch={() => setRefetch(!reFetch)}
          data={editData}
        />
      )}
    </>
  );
};
export default AllNews;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;

import Modal from "react-bootstrap/Modal";
import styled from "styled-components";
import { useState } from "react";

import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { Button } from "@/shared/components/Button";

const CreateSubAccount = ({ show, hide, reFetchPackages, otcId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [userEmail, setUserEmail] = useState(null);

  const CreateNewOtcMarketplacePackage = async () => {
 
  };

  return (
    <>
      <Modal
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <div
          className="loader-overlay"
          style={{
            display: isLoading ? "flex" : "none",
            justifyContent: "center",
            alignItems: "center",
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(255, 255, 255, 0.8)",
            zIndex: 999,
          }}
        >
          <Ring size={65} color="#00897B" />
        </div>
        <Modal.Header closeButton>
          <Modal.Title className="text-center" style={{ fontSize: "large" }}>
            <b> Create Sub Account</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container padding-bottom-3x p-0">
            <div
              className=" mt-0 p-0 flex flex-col gap-8 justify-center items-center"
              style={{ boxShadow: "none", fontFamily: "sans-serif" }}
            >
              <div className=" flex flex-wrap justify-start gap-4 items-start">
                <div className="">
                  <InputDiv>
                    <ChatSearchInput
                      type="text"
                      name="packageName"
                      value={userEmail}
                      className="form-control mt-2"
                      placeholder="User Email "
                      onChange={(e) => setUserEmail(e.target.value)}
                    />
                  </InputDiv>
                </div>
              </div>
              <div className=" flex justify-center items-center">
                <FormButton
                  type="submit"
                  variant="contain-success"
                  className="py-2 mt-2 w-100"
                  onClick={CreateNewOtcMarketplacePackage}
                >
                  Create
                </FormButton>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
export default CreateSubAccount;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const DropdownDiv = styled.div`
  display: block;
  @media screen and (max-width: 876px) {
    width: 100%;
  }
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ContactProps } from '@/shared/prop-types/ChatProps';
import {
  colorAccent,
  colorAdditional,
  colorFieldsBorder,
  colorHover,
} from '@/utils/palette';
import {
  marginRight,
  marginLeft,
} from '@/utils/directions';

const ChatUser = ({ contact, active }) => {
  const UserId = JSON.parse(sessionStorage.getItem('user'))?.data?.user?.user_id;
  let formattedDate = null;
  if (contact?.updatedAt) {
    const timestamp = contact?.updatedAt;
    const parsedDate = new Date(timestamp);
    const currentDate = new Date();
    const timeDifference = currentDate - parsedDate;
    function formatTimeDifference(Difference) {
      const minutes = Math.floor(Difference / 60000);
      const hours = Math.floor(minutes / 60);
      const days = Math.floor(hours / 24);
      if (minutes < 60) {
        return `${minutes} min ago`;
      } if (hours < 24) {
        const formattedHours = parsedDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
        return `${formattedHours}`;
      } if (hours < 48) {
        return 'Yesterday';
      } if (hours > 48) {
        formattedDate = parsedDate.toLocaleDateString('en-US', {
          weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
        });
        return `On ${formattedDate}`;
      }
      formattedDate = parsedDate.toLocaleDateString('en-US', {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric',
      });
      return `On ${formattedDate}`;
    }
    formattedDate = formatTimeDifference(timeDifference);
    // console.log('formattedDate>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>', formattedDate);
  }
  // console.log('Single', contact);
  // let lastMessage = '';
  // if (contact.messages.length) {
  //   lastMessage = contact.messages.slice(-1)[0].message;
  // } else {
  //   lastMessage = 'new contact';
  // }
  // const unreadCount = contact.messages.filter(message => !message.read).length;
  let contactName;

  switch (true) {
    case contact?.type === 'private':
      contactName = <ChatContactName>{contact?.user_detail[0]?.name}</ChatContactName>;
      break;
    case contact?.type === 'room' || contact?.type === 'otc-room':
      contactName = <ChatContactName>Room Chat</ChatContactName>;
      break;
    case contact?.support_user_id !== UserId:
      contactName = <ChatContactName>Private Chat</ChatContactName>;
      break;
    default:
      contactName = <ChatContactName>{contact?.subject}</ChatContactName>;
      break;
  }
  return (
    <ChatContactWrap active={active}>
      <ChatContactAvatar>
        <img
          src={
            contact?.type === 'private'
              ? `${process.env.PUBLIC_URL}/img/ava.png`
              : 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcScfbqcRCqJvLMI04FkUqyjzqm1gSBujwy2W89nmyNH2A&s'
          }
          alt={contact?.type === 'private' ? 'Private Chat' : 'Group Chat'}
        />
        {contact?.type === 'private' && contact?.online_status[0]?.online_status === 'true' && <OnlineIndicator />}
      </ChatContactAvatar>

      <ChatContactPreview>

        {contactName}
        {formattedDate !== null && (
          <ChatContactData>
            {formattedDate}
          </ChatContactData>
        )}
      </ChatContactPreview>



    </ChatContactWrap>

  );
};

ChatUser.propTypes = {
  contact: ContactProps.isRequired,
  active: PropTypes.bool,
};

ChatUser.defaultProps = {
  active: false,
};

export default ChatUser;

// region STYLES
const UnreadBadge = styled.div`
  position: absolute;
  // top: 0;
  right: 6px;
  background-color: rgb(23,204,95); // You can use any color you prefer for the badge
  color: white;
  border-radius: 50%;
  padding: 5px 8px;
  font-size: 10px;
`;
const ChatContactData = styled.p`
  font-size: 12px;
  font-weight: 500;
  margin-left: auto;
  margin-bottom: -10px;
  color: ${colorAccent};
`;
const ChatContactWrap = styled.div`
  position: relative; /* Added relative positioning */
  height: 72px;
  display: flex;
  cursor: pointer;
  transition: all 0.3s;
  border-bottom: 1px solid ${colorFieldsBorder};

  &:hover {
    background-color: ${colorHover};
  }
  
  ${props => props.active && `
    background-color: ${colorHover(props)};

    &:hover {
      background-color: ${colorHover(props)};
    }
  `}
`;

const OnlineIndicator = styled.div`
  position: absolute;
  // bottom: 0;
  // right: 0;
  width: 12px;
  height: 12px;
  background-color: green; // You can use any color you prefer
  border-radius: 50%;
  border: 2px solid #fff;
`;
// const ChatContactWrap = styled.div`
//   height: 72px;
//   display: flex;
//   cursor: pointer;
//   transition: all 0.3s;
//   border-bottom: 1px solid ${colorFieldsBorder};

//   &:hover {
//     background-color: ${colorHover};
//   }

//   ${props => props.active && `
//     background-color: ${colorHover(props)};

//     &:hover {
//       background-color: ${colorHover(props)};
//     }
//   `}
// `;

const ChatContactAvatar = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  overflow: hidden;
  min-width: 40px;
  ${marginRight}: 10px;
  ${marginLeft}: 15px;

  img {
    height: 100%;
  }
`;

const ChatContactPreview = styled.div`
  display: flex;
  flex-flow: column;
  align-self: center;
  width: calc(100% - 80px);
  ${marginRight}: 20px;
  ${marginLeft}: 0;
`;

const ChatContactName = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-transform: capitalize;
  margin: 0;
  color: ${colorAccent};
`;

const ChatContactPost = styled.p`
  margin-top: 0;
  margin-bottom: 3px;
  font-size: 10px;
  line-height: 1.2;
  color: ${colorAdditional};
`;

const ChatContactLastMessage = styled.p`
  margin: 4px 0 0 0;
  font-size: 12px;
  line-height: 1.33;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

// endregion

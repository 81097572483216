import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import ReactTableCustomizer from "@/shared/components/table/components/ReactTableCustomizer";
import {
  Card,
  CardBody,
  CardTitleWrap,
  CardTitle,
  CardSubhead,
} from "@/shared/components/Card";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import numeral from "numeral";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { getToken } from "../../../../../../utils/services/user.service";
import {
  CustomMultiSelect,
  CustomSelect,
} from "../../../../../../shared/components/CustomSelect";
import styled from "styled-components";

const EditableReactTable = ({
  emailSearch,
  Symbol,
  walletSearch,
  Email,
  Wallet,
  reactTableData,
  fetchData,
}) => {

  const [rows, setData] = useState(reactTableData);
  console.log("🚀 ~ rows:", rows)
  const [withPagination, setWithPaginationTable] = useState(true);
  const [isSortable, setIsSortable] = useState(false);
  const [withSearchEngine, setWithSearchEngine] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTerm1, setSearchTerm1] = useState("");
  const [sortingVal, setSortingVal] = useState("");

  const [ListData, setListData] = useState([]);

  const [selectedSymbol, setSelectedSymbol] = useState("");
  const modifiedResponse = ListData.map((item) => ({
    label: item.symbol,
    value: item.symbol,
  }));

  useEffect(() => {
    if (selectedSymbol) {
      const filteredData = reactTableData
        ?.map((item) => ({
          ...item,
          balances: item.balances.filter((balance) => {
            if (sortingVal === "greater_than_0") {
              return balance.symbol === selectedSymbol && balance.quantity > 0;
            } else {
              // Default condition: sortingVal === "equal_to_0" or any other value
              return (
                balance.symbol === selectedSymbol && balance.quantity === 0
              );
            }
          }),
        }))
        .filter((item) => item.balances.length > 0);

      console.log(filteredData, "---");
      setData(filteredData);
    } else {
      const filteredData = reactTableData
        ?.map((item) => ({
          ...item,
          balances: item.balances.filter((balance) => {
            if (sortingVal === "greater_than_0") {
              return balance.quantity > 0;
            } else {

              return (
                balance.quantity === 0
              );
            }
          }),
        }))
        .filter((item) => item.balances.length > 0);

      console.log(filteredData, "---");
      setData(filteredData);
    }
  }, [selectedSymbol, sortingVal,reactTableData]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const fees = await getToken();
        ////console.log('Fees:', fees);
        const data = fees?.data?.user;
        setListData(data);
      } catch (error) {
        setListData([]);
        console.error("Error fetching fees:", error);
      }
    };

    fetchData();
  }, []);

  const handleSearch = (searchValue) => {
    setSelectedSymbol("");
    setSearchTerm(searchValue);
    Email(searchValue);
  };
  const handleSearcSymbol = (searchValue) => {
    console.log("🚀 ~ handleSearcSymbol ~ searchValue:", searchValue);
    setSearchTerm1(searchValue.symbol);
    Symbol(searchValue.symbol);
    setSelectedSymbol(searchValue.symbol);
  };

  const handleSearchAddres = (searchValue) => {
    setSelectedSymbol("");

    setSearchTerm1(searchValue);
    Wallet(searchValue);
  };

  // Custom function to get the balance for a specific symbol
  const getBalance = (row, symbol) => {
    const balances = row?.balances;
    if (balances) {
      const balance = balances.find((bal) => bal.symbol === symbol);
      return balance ? balance.quantity : "0"; // Return the balance quantity if found
    }
    return "";
  };

  const balanceColumns = [
    "BNB",
    "BTC",
    "BUSD",
    "CTY",
    "ETH",
    "EUROC",
    "GBPT",
    "JPYC",
    "MATIC",
    "PAX",
    "USDC",
    "USDT (ERC20)",
    "USDT (BEP20)",
    "OPY",
    "FTY",
    "MET$",
    "LTX",
    "WEN",
    "SoLpy",
    "SOLANA",
  ];

  const customColumns = [
    {
      Header: "Email",
      accessor: "email",
      Cell: (row) => {
        const user = Array.isArray(reactTableData)
          ? reactTableData.find((user) => user.email === row.value)
          : null;
        return (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id={`tooltip-email-${row.original?.email}`}>
                {user ? user.name : "Name not available"}
              </Tooltip>
            }
          >
            <span>{String(row.value)}</span>
          </OverlayTrigger>
        );
      },
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: (row) => String(row.value),
    },
    {
      Header: "Address",
      accessor: "address",
      Cell: (row) => (
        <>
          <span className="d-flex">
            <span>
              {`${String(row.value).substring(0, 6)}...${String(
                row.value
              ).slice(-4)}`}
            </span>
            <button
              onClick={() => {
                navigator.clipboard.writeText(row.value);
                toast.success("Wallet Address copied to clipboard!");
              }}
              className="ml-2"
              style={{ color: "lightblue" }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </span>
        </>
      ),
    },
    {
      Header: "BTC Address",
      accessor: "btcAddress",
      Cell: (row) => (
        <>
          <span className="d-flex">
            <span>
              {`${String(row.value).substring(0, 6)}...${String(
                row.value
              ).slice(-4)}`}
            </span>
            <button
              onClick={() => {
                navigator.clipboard.writeText(row.value);
                toast.success("Wallet Address copied to clipboard!");
              }}
              className="ml-2"
              style={{ color: "lightblue" }}
            >
              {/* {' '}
                Copy */}
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </span>
        </>
      ),
    },
    {
      Header: "SOL Address",
      accessor: "sol_address",
      Cell: (row) => (
        <>
          <span className="d-flex">
            <span>
              {`${String(row.value).substring(0, 6)}...${String(
                row.value
              ).slice(-4)}`}
            </span>
            <button
              onClick={() => {
                navigator.clipboard.writeText(row.value);
                toast.success("Wallet Address copied to clipboard!");
              }}
              className="ml-2"
              style={{ color: "lightblue" }}
            >
              <FontAwesomeIcon icon={faCopy} />
            </button>
          </span>
        </>
      ),
    },
    // Add balance columns dynamically
    ...balanceColumns.map((symbol) => ({
      Header: symbol,
      accessor: (row) => getBalance(row, symbol),
      Cell: (row) => numeral(row.value).format("0,0.00000"),
    })),
  ];
  const updateEditableData = (rowIndex, columnId, value) => {
    setData((old) =>
      old.map((item, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnId]: value,
          };
        }
        return item;
      })
    );
  };

  const handleClickIsSortable = () => {
    setIsSortable(!isSortable);
  };

  const handleClickWithPagination = () => {
    setWithPaginationTable(!withPagination);
  };

  const handleClickWithSearchEngine = () => {
    setWithSearchEngine(!withSearchEngine);
  };
  const sortingOption = [
    { value: "greater_than_0", label: "Greater than  0" },
    { value: "equal_to_0", label: "Equal to 0" },
  ];

  const tableConfig = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    isPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    withPagination: true,
  };

  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="flex flex-col md:flex-row  items-center">
            <div className="flex flex-col  md:flex-row gap-2">
              <div>
                <label
                  htmlFor="email"
                  className="text-white"
                  style={{ fontSize: "small" }}
                >
                  Eamil
                </label>
                <input
                  type="text"
                  placeholder="Email..."
                  className="py-2 px-2 rounded-sm text-white  bg-transparent border border-white  "
                  value={emailSearch}
                  onChange={(e) => handleSearch(e.target.value)}
                />
              </div>
              <div>
                <label
                  htmlFor="email"
                  className="text-white"
                  style={{ fontSize: "small" }}
                >
                  Wallet
                </label>
                <input
                  type="text"
                  placeholder="Wallet..."
                  className="py-2 px-2 rounded-sm text-white  bg-transparent border border-b-white   "
                  value={walletSearch}
                  onChange={(e) => handleSearchAddres(e.target.value)}
                />
              </div>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12">
              <InputDiv>
                <label
                  htmlFor="email"
                  className="text-white bg-transparent"
                  style={{ fontSize: "small" }}
                >
                  Symbol
                </label>
                <CustomSelect
                  value={selectedSymbol}
                  onChange={(value) => {
                    console.log("🚀 ~ SendForm ~ value:", value);
                    setSelectedSymbol(value.value);
                    setSearchTerm1(value.value);
                    Symbol(value.value);
                    Email("");
                  }}
                  options={modifiedResponse}
                />
              </InputDiv>
            </div>
            <div className="col-md-4 col-lg-4 col-sm-12">
              <InputDiv>
                <label
                  htmlFor="email"
                  className="text-white"
                  style={{ fontSize: "small" }}
                >
                  Sort by
                </label>
                <CustomSelect
                  value={sortingVal}
                  onChange={(value) => {
                    console.log("🚀 ~ SendForm ~ value:", value);
                    setSortingVal(value.value);
                  }}
                  options={sortingOption}
                />
              </InputDiv>
            </div>
          </div>
          {rows?.length == 0 ? <p className="mt-4">No data found </p> : <ReactTableBase
            key={withSearchEngine ? "searchable" : "common"}
            columns={customColumns}
            data={rows}
            // updateEditableData={updateEditableData}
            tableConfig={tableConfig}
          />}
        </CardBody>
      </Card>
    </Col>
  );
};

EditableReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        name: PropTypes.string,
      })
    ),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default EditableReactTable;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col } from 'react-bootstrap';
import ProgressBar from '@/shared/components/ProgressBar';
import { Card } from '@/shared/components/Card';
import {
  MobileAppWidgetLine,
  MobileAppWidgetStat,
  MobileAppWidgetTitle,
  MobileWidgetIconDown,
} from '../MobileWidgetDashboardElements';
import {
  DashboardWidgetCard,
} from '../../BasicDashboardComponents';

const NewUsersShort = ({total_users}) => {
  const { t } = useTranslation('common');

  return (
    <Col md={12} xl={3} lg={6} xs={12}>
      <Card>
        <DashboardWidgetCard className='bg-primary'>
          <div>
            <MobileAppWidgetLine>
              <MobileAppWidgetStat  className='text-white'>
                {total_users}
              </MobileAppWidgetStat>
              {/* <MobileWidgetIconDown /> */}
            </MobileAppWidgetLine>
            <MobileAppWidgetTitle className='text-white'>
              <h5 className='text-white'>{t('Total Users')}</h5>
            </MobileAppWidgetTitle>
            {/* <ProgressBar now={79} label="79%" size="small" top gradient="blue" /> */}
          </div>
        </DashboardWidgetCard>
      </Card>
    </Col>
  );
};

export default NewUsersShort;

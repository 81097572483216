import React, { useState } from 'react'; // Import useState for managing filter dropdown state
import styled from 'styled-components';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import FilterVariantIcon from 'mdi-react/FilterVariantIcon'; // Import the filter icon
import {
 Col, Container, Row, Form, Button, Dropdown, DropdownButton, 
} from 'react-bootstrap';
import {
  colorAdditional,
  colorBackgroundBody,
  colorBlue,
  colorFieldsBorder,
  colorText,
} from '@/utils/palette';
import {
  paddingLeft,
  paddingRight,
  left,
} from '@/utils/directions';
import { searchTicket } from '../../../../utils/services/user.service';

const filterOptions = ['All', 'Option 1', 'Option 2', 'Option 3'];
const ChatSearch = ({ onUpdateTickets }) => {
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [showFilterDropdown, setShowFilterDropdown] = useState(false);
  //console.log('showFilterDropdown', showFilterDropdown);
  //console.log('onUpdateTickets', onUpdateTickets);
  const toggleFilterDropdown = () => {
    setShowFilterDropdown(!showFilterDropdown);
    //console.log('showFilterDropdown1221', showFilterDropdown);
  };
  const handleSearch = async (search) => {
    setSearchQuery(search);
  //console.log('searchQuery', searchQuery);
    try {
      const payload = {
        subject: searchQuery,
      };
      const response = await searchTicket(payload);
      onUpdateTickets(response.data.tickets); // Update tickets with the search results
    } catch (error) {
      onUpdateTickets([]);
      console.error('Error searching for tickets:', error);
    }
  };
  return (
    <ChatSearchWrap>
      {/* <Col md={10}> */}
      <ChatSearchInput
        placeholder="Search"
        value={searchQuery}
        onChange={e => handleSearch(e.target.value)}
      />
      <MagnifyIcon />
      {/* </Col> */}
      {/* <Col md={2}>
      <Button style={FilterButton}  onClick={toggleFilterDropdown}>
        <FilterVariantIcon  />
      </Button>
      {showFilterDropdown && (
        <FilterDropdown>
          {filterOptions.map((option) => (
      <option key={option} value={option}>
         {option}
      </option>
    ))}
        </FilterDropdown>
      )}
      </Col> */}
    </ChatSearchWrap>
  );
};
export default ChatSearch;
// region STYLES
const FilterDropdown = styled.select`
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  border: 1px solid ${colorFieldsBorder};
  min-width: 150px;
  cursor: pointer; // Add this to make it clickable
`;
const FilterButton = styled.button`
  background: red;
  border: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  color: ${colorAdditional};
  font-size: 18px;
  margin-right: 10px;
`;
// const FilterDropdown = styled.div`
//   position: absolute;
//   top: 100%;
//   right: 0;
//   background-color: #fff;
//   border: 1px solid ${colorFieldsBorder};
//   min-width: 150px;
//   /* Add more styles for the filter dropdown as needed */
// `;
const ChatSearchWrap = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  position: relative;
  border-bottom: 1px solid ${colorFieldsBorder};
  svg {
    fill: ${colorAdditional};
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 18px;
    width: 18px;
    pointer-events: none;
    ${left}: 15px;
  }
`;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  ${paddingLeft}: 38px;
  ${paddingRight}: 10px;
  background-color: ${colorBackgroundBody};
  border: 1px solid ${colorBackgroundBody};
  color: ${colorText};
  &:focus, &:active {
    outline: none;
    border-color: ${colorBlue};
  }
  &::-webkit-input-placeholder {
    color: ${colorAdditional};
  }
  &::-moz-placeholder {
    color: ${colorAdditional};
  }
  &:-moz-placeholder {
    color: ${colorAdditional};
  }
  &:-ms-input-placeholder {
    color: ${colorAdditional};
  }
`;
// endregion

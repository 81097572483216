import ReactTableBase from "@/shared/components/table/ReactTableBase";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { Pie, Tooltip } from "recharts";
import getTooltipStyles from "@/shared/helpers";
import { colorDarkText, scrollbarStyles } from "@/utils/palette";

import {
  DashboardPieChartContainer,
  DashboardPieChartFlex,
  DashboardChartLegendTable,
} from "../../../containers/Dashboards/BasicDashboardComponents";

import { faEye } from "@fortawesome/free-regular-svg-icons";
import AllTransectionDetailModal from "./AllTransectionDetailsModal";
import moment from "moment";
import { useSelector } from "react-redux";
import { getOtcPackageTrx } from "../../../utils/services/user.service";

const AllTransectionTable = ({ marketplaceID }) => {
  const [transectionData, setTransectionData] = useState([]);
  const [show, setShow] = useState(false);
  const [detailData, setDetailData] = useState({});
  const data2 = [{ value: 1 }];
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const themeName = useSelector((state) => state.theme.className);
  const [defaultData, setDefaultData] = useState(false);
  const tooltipFormatter = (value, name, entry) => {
    return [
      <span key="custom-label">{`${entry?.payload?.symbol} : ${entry?.payload?.graphVal} `}</span>,
    ];
  };
  const onMouseMove = (e) => {
    if (e.tooltipPosition) {
      setCoordinates({ x: e.tooltipPosition.x, y: e.tooltipPosition.y });
    }
  };
  const optionColors = [
    { value: "#BDC3C7", label: "Light Gray" },
    { value: "#C0392B", label: "Red" },
    { value: "#E74C3C", label: "Light Red" },
    { value: "#9B59B6", label: "Light Purple" },
    { value: "#8E44AD", label: "Purple" },
    { value: "#2980B9", label: "Dark Blue" },
    { value: "#3498DB", label: "Light Blue" },
    { value: "#1ABC9C", label: "Sea Green" },
    { value: "#16A085", label: "Dark Sea Green" },
    { value: "#27AE60", label: "Dark Green" },
    { value: "#2ECC71", label: "Light Green" },
    { value: "#F1C40F", label: "Yellow" },
    { value: "#E67E22", label: "Dark Orange" },
    { value: "#D35400", label: "Dull Orange" },
    { value: "#ECF0F1", label: "White" },
    { value: "#F39C12", label: "Orange" },
    { value: "#95A5A6", label: "Dull Gray" },
    { value: "#7F8C8D", label: "Dark Gray" },
    { value: "#34495E", label: "Light Black" },
    { value: "#FFA07A", label: "Salmon Red" },
    { value: "#F08080", label: "Light Coral" },
    { value: "#DFFF00", label: "Basanti" },
    { value: "#DE3163", label: "Pink" },
    { value: "#40E0D0", label: "Ferozi" },
  ];
  let colorIndex = 0; // Track the current index of the color array
  const newArray = transectionData
    .filter((item) => item.transfer_status !== "success")
    .reduce((acc, item) => {
      const symbol = item.pool.split("-")[0].trim();
      const fill = optionColors[colorIndex].value;
      const newVal = Number(item?.receive_amount);
      const priceValue = newVal?.toFixed(2) || 0;
      const graphVal = parseFloat(priceValue);
      const userName = item?.user_details[0].name;
      const endDate = item?.unlock_time;

      colorIndex = (colorIndex + 1) % optionColors.length;

      const existingIndex = acc.findIndex((el) => el.symbol === symbol);
      if (existingIndex !== -1) {
        acc[existingIndex].graphVal += graphVal;
      } else {
        acc.push({
          fill,
          symbol,
          graphVal,
          userName,
          endDate,
        });
      }

      return acc;
    }, []);
  console.log("🚀 ~ newArray ~ newArray:", newArray);

  const fetchTransection = async () => {
    const payload = {
      limit: 1000,
      page_number: 1,
      marketplace_id: marketplaceID,
    };
    const res = await getOtcPackageTrx(payload);
    setTransectionData(res?.data?.data);
  };
  useEffect(() => {
    fetchTransection();
  }, []);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const getStatusColor = (status1) => {
    switch (status1.toLowerCase()) {
      case "completed":
        return "#4ce1b6";
      case "pending":
        return "#f6da6e";
      case "rejected":
        return "#ff4861";
      case "waiting":
        return "#f6da6e";
      case "declined":
        return "#ff4861";
      case "failed":
        return "#ff4861";
      case "success":
        return "green";
      default:
        return "#70bbfd";
    }
  };
  const chainIds = [
    { value: 1, label: "ETHEREUM" },
    { value: 56, label: "BSC" },
    { value: 137, label: "MATIC" },
  ];
  const getChainName = (chainId) => {
    const chain = chainIds.find((chain) => chain.value === chainId);
    return chain ? chain.label : "Unknown";
  };
  const header = [
    // { accessor: "transection_Id", Header: "Trx Id" },
    { accessor: "date", Header: "Buy Date" },
    { accessor: "endDate", Header: "End Date" },
    { accessor: "user_name", Header: " Name" },
    { accessor: "user_email", Header: " Email" },
    { accessor: "buy_token", Header: "Buy Token" },
    { accessor: "recieve_symbol", Header: "Recieve Token" },
    { accessor: "buy_amount", Header: "Buy Amount" },
    { accessor: "receive_amount", Header: "Receive Amount" },
    { accessor: "status", Header: "Status" },
    { accessor: "transfer_status", Header: "Transfer Status" },
    { accessor: "view_detail", Header: "View Detail" },
  ];
  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    withPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    isPagination: true,
  };
  const handleDetailModal = (items) => {
    setShow(true);
    setDetailData(items);
  };
  const data = [];
  transectionData.map((item) =>
    data.push({
      transection_Id: item._id,
      user_name: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.user_details[0]?.name}
        </h6>
      ),
      user_email: (
        <h6 className="" style={{ color: "#999999", fontSize: "inherit" }}>
          {item?.user_details[0]?.email}
        </h6>
      ),
      buy_token: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.pool.split("-")[0]}
        </h6>
      ),
      date: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {moment(item?.createdAt).format("L")}
        </h6>
      ),
      endDate: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {moment(item?.unlock_time).format("L")}
        </h6>
      ),
      recieve_symbol: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.pool.split("-")[1]}
        </h6>
      ),

      buy_tax_hash: (
        <HashLink
          role="link"
          onClick={() => {
            let baseUrl;
            switch (item?.chain_id) {
              case 1:
                baseUrl = "https://etherscan.io/tx/";
                break;
              case 137:
                baseUrl = "https://polygonscan.com/tx/";
                break;
              case 56:
              default:
                baseUrl = "https://bscscan.com/tx/";
                break;
            }
            openInNewTab(`${baseUrl}${detailData?.buy_tax_hash}`);
          }}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </HashLink>
      ),
      status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.status) }}
        >
          <h6 className="text-white">
            <b>{item.status}</b>
          </h6>
        </span>
      ),
      transfer_status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.transfer_status) }}
        >
          <h6 className="text-white">
            <b>{item.transfer_status}</b>
          </h6>
        </span>
      ),
      contract_hash: (
        <HashLink
          role="link"
          onClick={() => {
            let baseUrl;
            switch (detailData?.chain_id) {
              case 1:
                baseUrl = "https://etherscan.io/tx/";
                break;
              case 137:
                baseUrl = "https://polygonscan.com/tx/";
                break;
              case 56:
              default:
                baseUrl = "https://bscscan.com/tx/";
                break;
            }
            openInNewTab(`${baseUrl}${detailData?.contract_hash}`);
          }}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </HashLink>
      ),
      receive_amount: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.receive_amount?.toFixed(2)}
        </h6>
      ),
      buy_amount: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item?.buy_amount}
        </h6>
      ),
      view_detail: (
        <HashLink onClick={() => handleDetailModal(item)}>
          <FontAwesomeIcon icon={faEye} />
        </HashLink>
      ),
    })
  );
  return (
    <>
      {transectionData.length > 0 ? (
        <>
    
          <ReactTableBase
            columns={header}
            data={data}
            tableConfig={tableConfi2}
          />
        </>
      ) : (
        <h3> No data found</h3>
      )}

      {show && (
        <AllTransectionDetailModal
          show={show}
          hide={() => setShow(false)}
          detailData={detailData}
        />
      )}
    </>
  );
};
export default AllTransectionTable;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;
const DashboardCoinsChartContainer = styled(DashboardPieChartContainer)`
  min-height: 360px !important;
  height: 100% !important;

  svg {
    min-height: 360px;
    position: absolute;
    left: 0;
    top: 0;

    @media (max-width: 1640px) {
      max-width: 90%;
    }

    @media (max-width: 1199px) {
      max-width: 100%;
    }

    @media (max-width: 768px) {
      height: 80% !important;
    }

    @media (max-width: 400px) {
      height: 60% !important;
    }

    .recharts-pie {
      transform: translateX(-60px);

      @media (max-width: 1919px) {
        transform: translateX(0);
      }
    }
  }

  .recharts-legend-wrapper {
    position: relative !important;
    max-width: 55%;
    width: 100% !important;
    z-index: 5;

    @media (max-width: 1919px) {
      max-width: 50%;
    }
    @media (max-width: 1199px) {
      max-width: 100%;
    }
    @media (max-width: 768px) {
      overflow-x: auto;

      ${scrollbarStyles};
    }
  }
`;

const LegendCol = styled("div")`
  flex-shrink: 2;
  flex-grow: 0;

  :first-child {
    flex-shrink: 0;
    flex-basis: 100px;
  }

  :last-child {
    flex-grow: 1;
  }
`;

const LegendColTitle = styled("div")`
  margin-bottom: 20px;
  font-size: 12px;
  font-weight: 700;
  line-height: 1.1;
  color: ${colorDarkText};
`;

const LegendColContent = styled("ul")`
  padding: 0;
  list-style: none;
  color: ${colorDarkText};

  li {
    margin-top: 15px;
    font-size: 14px;
  }
`;

// src/DisplayPolicy.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ReactQuill from 'react-quill';
import { getPrivacyOrCookies } from '../../../utils/services/user.service';
import parse from 'html-react-parser';
import { Ring } from '@uiball/loaders';
import styled from "styled-components";

const DisplayPolicy = ({ reFetch }) => {
    const [content, setContent] = useState(' ');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchContent = async () => {
            setIsLoading(true)
            try {
                const payload = {
                    type: 'policy'
                }
                const response = await getPrivacyOrCookies(payload)
                setIsLoading(false)
                setContent(response?.data?.result[0]?.content)
            } catch (error) {
                setIsLoading(false)
                console.error('Error fetching content:', error);
            }
        };

        fetchContent();
    }, [reFetch]);

    return (
        <>
            {isLoading && (
                <LoaderOverlay>
                    <Ring size={65} color="#00897B" />
                </LoaderOverlay>
            )}
            <div >
                <ReactQuill value={parse(content || " ")} readOnly={true} theme="bubble" />
            </div>
        </>
    );
};

export default DisplayPolicy;
const LoaderOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
`;
import React, { useState, useEffect, useRef } from 'react';

import { Col } from 'react-bootstrap';
import {
  Card,
  CardBody,
} from '@/shared/components/Card';
import Chat from './Chat';
import myContacts from './contacts';
import { getChat } from '../../../../utils/services/user.service';

const ChatCard = () => {
  // const [isConnected, setIsConnected] = useState(socket.connected);
  const [fooEvents, setFooEvents] = useState([]);


  //////console.log('fooEvents',fooEvents)


  
  
  return (
    <>
      <Col md={12} lg={12}>
        <Card>
  
          <CardBody>
            <Chat
              contacts={myContacts}
        
              currentUser={{
                avatar: `${process.env.PUBLIC_URL}/img/ava.png`,
                name: 'Roman Johanson',
                userName: 'dragonKing',
              }}
            />
          </CardBody>
        </Card>
      </Col>
    </>
  );
};

export default ChatCard;


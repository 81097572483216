/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';
import PaperclipIcon from 'mdi-react/PaperclipIcon';
import EmoticonIcon from 'mdi-react/EmoticonIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'; // Import the FontAwesome icon
import {
 colorAccent, colorAdditional, colorBackgroundBody, colorFieldsBorder, colorText, 
} from '@/utils/palette';
import {
 right, marginLeft, paddingRight, paddingLeft, 
} from '@/utils/directions';
import { ticketReply } from '../../../../utils/services/user.service';

const ChatField = ({ chatId, disabled, getIsChatVal1 , status}) => {
  const [message, setMessage] = useState('');
  const [files, setFiles] = useState([]);
 //console.log("selected files", files)


  const handleSendMessage = async () => {
    try {
      if (!message && files.length === 0) {
        toast.error('Either message or file must have data.');
        return; 
      }

      const formData = new FormData();
      formData.append('ticket_id', chatId);
      formData.append('message', message);
      for (const file of files) {
        formData.append('File', file);
      }
     
  

      const payload = {
        Files: files,
        message: message,
        ticket_id: chatId,
      };
      //console.log("payload",formData)
      //console.log("payload",formData.Files)
      const response = await ticketReply(formData);
      //console.log('response reply', response);
      if (response.data.success === true) {
        localStorage.setItem('setIsChatVal', true);
            const data = [{
        Files: files,
        message: message,
      }]
        getIsChatVal1(data);
        //console.log('getIsChatVal', getIsChatVal1);
  
        setMessage('');
        setFiles([]); 
        
      } else {
      toast.error('Status is completed; you can not send a message.');
        
      }
    } catch (error) {
      toast.error('Status is completed; you can not send a message.');
      console.error('API Error:', error);
    }
  };

  const handleFileInputChange = (e) => {
    //console.log("e.target.files", e.target.files);
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
    // setFiles(prevImages => [...prevImages, ...selectedFiles]);
    //console.log("setFiles", setFiles);
  };
  const handleClearFile = (index) => {
    const updatedFiles = [...files];
    updatedFiles.splice(index, 1); 
    setFiles(updatedFiles); 
  };
  const ButtonStyle = {
    // backgroundColor: 'white',
    color: 'white', 
  };
  return (
    <ChatTextField >
       {status === 'open' && (
      <ChatForm>
        {files.length > 0 ? (
          <SelectedFilesList>
            {files.map((file, index) => (
              <SelectedFileName key={index}>
                {file.name}
                <ClearFileButton type="button" onClick={() => handleClearFile(index)}>
                  Clear
                </ClearFileButton>
              </SelectedFileName>
            ))}
          </SelectedFilesList>
        ) : (
          <ChatFieldInput
            type="text"
            name="chatField"
            placeholder="Type here…"
            value={message}
            onChange={e => setMessage(e.target.value)}
          />
        )}

        <ChatFieldControls>
          <ChatIconButton
            type="button"
            onClick={(e) => {
              e.preventDefault();
              document.getElementById('fileInput').click();
            }}
          >
            <PaperclipIcon />
          </ChatIconButton>

          <input
            id="fileInput"
            type="file"
            accept="image/*, .pdf, .doc, .docx"
            onChange={handleFileInputChange}
            multiple // Allow multiple file selection
            style={{ display: 'none' }}
          />
          <ChatSendButton
            onClick={(e) => {
              e.preventDefault();
              handleSendMessage();
            }}
          >
            <FontAwesomeIcon icon={faPaperPlane} style={files.length > 0 ? { color: 'white' } : { color: 'black' }} />
          </ChatSendButton>
        </ChatFieldControls>
      </ChatForm>
         )}
       {(status === 'completed' || status === 'close') && (
  <div className="text-center py-2">
    <h5 style={{ color: '#999999' }}>
      Ticket is Completed or Closed.
    </h5>
  </div>
)}
    </ChatTextField>
  );
};

export default ChatField;
// region STYLES
const ClearFileButton = styled.button`
  type: button; // Add this line
  background: transparent;
  border: none;
  font-size: 12px;
  margin-left: 10px;
  color: ${colorAccent};
  cursor: pointer;
`;
const ChatSendButton = styled.button`
  background: transparent;
  border: none;
  height: 36px;
  cursor: pointer;
  padding: 0 5px;
  ${marginLeft}: 5px;

  svg {
    height: 16px;
    width: 16px;
    fill: ${colorAdditional};
    transition: 0.3s;
  }

  &:hover {
    svg {
      fill: ${colorAccent};
    }
  }
`;

const SelectedFileName = styled.div`
  // margin-top: 5px;
  font-size: 11px;
  color: ${colorText};
`;

const SelectedFilesList = styled.div`
  height: 64px;
  width: 100%;
  border-top: 1px solid ${colorFieldsBorder};
  max-height: 64px; 
  overflow-y: auto; 
  overflow-y: hidden; 

`;

const ChatTextField = styled.div`
  height: 64px;
  width: 100%;
  border-top: 1px solid ${colorFieldsBorder};
`;

const ChatForm = styled.form`
  width: 100%;
  position: relative;
  padding: 12px 20px;
`;

const ChatFieldInput = styled(Form.Control)`
  width: 100%;
  height: 36px;
  border-radius: 18px;
  font-size: 12px;
  line-height: 1.33;
  transition: 0.3s;
  ${paddingLeft}: 15px;
  ${paddingRight}: 65px;
  background-color: ${colorBackgroundBody};
  border: 1px solid ${colorBackgroundBody};
  color: ${colorText};

  &:focus, &:hover, &:active {
    border-color: ${colorAccent};
    outline: none;
    box-shadow: none;
  }
`;

const ChatFieldControls = styled.div`
  position: absolute;
  top: 12px;
  height: 36px;
  display: flex;
  ${right}: 35px;
`;

const ChatIconButton = styled.button`
  background: transparent;
  border: none;
  height: 36px;
  cursor: pointer;
  padding: 0 5px;
  ${marginLeft}: 5px;

  svg {
    height: 16px;
    width: 16px;
    fill: ${colorAdditional};
    transition: 0.3s;
  }

  &:hover {

    svg {
      fill: ${colorAccent};
    }
  }
`;

// endregion

import SimpleModal from "../../shared/components/Modal/SimpleModal";

const ShiftModal = ({ isActive, onClose, onConfirm, setNewWalletAddress }) => {
  return (
    <>
      <SimpleModal isActive={isActive} onClose={onClose}>
        <div className=" flex  flex-col gap-6">
          <p>{`Enter New Wallet Address`}</p>

          <input
            placeholder="Please Enter New Wallet Address"
            type="text"
            className="flex p-2 rounded-md text-black"
            onChange={(e) => setNewWalletAddress(e.target.value)} // Step 2: Bind input value to state
          />

          <div className="flex gap-6 justify-center items-center">
            <button
              className="flex text-md hover:text-[red] border py-2 px-4 rounded-md"
              onClick={onClose}
            >
              Close
            </button>
            <button
              className="flex text-md hover:text-[green] border py-2 px-4 rounded-md"
              onClick={onConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </SimpleModal>
    </>
  );
};

export default ShiftModal;

/* eslint-disable import/named */
import { createAction } from 'redux-actions';
import {
  getUsdFee, getUsdtFee, getEthFee, getEurFee, getGbpFee, getJypFee, getMatFee, getCtyFee, getPaxFee,
  getBusdFee,
  getBnbFee, 
  getBTCFee,
} from '@/utils/services/user.service';
import { firstLetterToUpperCase } from '@/shared/helpers';
import { COIN_LOAD, SET_COIN_LOAD } from './authActions';

export const fetchTopUsdPriceRequest = createAction('FETCH_USDC_PRICE_REQUEST');
export const fetchTopUsdPriceSuccess = createAction('FETCH_USDC_PRICE_SUCCESS');
export const fetchTopUsdPriceError = createAction('FETCH_USDC_PRICE_ERROR');
export const fetchLoad = createAction('FETCH_LOAD');
export const fetchUnLoad = createAction('FETCH_UN_LOAD');

export const fetchAllCurrencies = () => async (dispatch) => {
  try {
    const coins = [];
    dispatch({
      type: COIN_LOAD,
    });


    // GET CTY Currency
    const cty = await getCtyFee();
    cty.data.coin_type = 'CTY';
    cty.data.currency = '(CTY Custodiy)';
    coins.push(cty.data);

    // GET USDC Currency
    const usdc = await getUsdFee();
    usdc.data.coin_type = 'USDC';
    usdc.data.currency = '(USD Coin)';
    coins.push(usdc.data);

    // GET USDT Currency
    const usdt = await getUsdtFee();
    usdt.data.coin_type = 'USDT';
    usdt.data.currency = '(Tether)';
    coins.push(usdt.data);

    // GET ETH Currency
    const eth = await getEthFee();
    eth.data.coin_type = 'ETH';
    eth.data.currency = '(Ethereum)';
    coins.push(eth.data);

    // GET ETH Currency
    const eur = await getEurFee();
    eur.data.coin_type = 'EUROC';
    eur.data.currency = '(Ethereum)';
    coins.push(eur.data);

    // GET GBP Currency
    const gbp = await getGbpFee();
    gbp.data.coin_type = 'GBPT';
    gbp.data.currency = '(Ethereum)';
    coins.push(gbp.data);

    // GET Jyp Currency
    const jyp = await getJypFee();
    jyp.data.coin_type = 'JYPC';
    jyp.data.currency = '(JYPC Coin)';
    coins.push(jyp.data);

    // GET MAT Currency
    const mat = await getMatFee();
    mat.data.coin_type = 'MATIC';
    mat.data.currency = '(Polygon)';
    coins.push(mat.data);

    // GET PAX Currency
    const pax = await getPaxFee();
    pax.data.coin_type = 'PAX';
    pax.data.currency = '(Paxos)';
    coins.push(pax.data);

    // GET BSUD Currency
    const bsud = await getBusdFee();
    bsud.data.coin_type = 'BSUD';
    bsud.data.currency = '(Binanace)';
    coins.push(bsud.data);

    // GET BNB Currency
    const bnb = await getBnbFee();
    bnb.data.coin_type = 'BNB';
    bnb.data.currency = '(Binanace Coin)';
    coins.push(bnb.data);

    // GET BTC Currency
    const btc = await getBTCFee();
    btc.data.coin_type = 'BTC';
    btc.data.currency = '(BTC)';
    coins.push(btc.data);

    dispatch({
      type: SET_COIN_LOAD,
    });
    dispatch(fetchTopUsdPriceSuccess(coins));
  } catch (e) {
    dispatch({
      type: SET_COIN_LOAD,
    });
    dispatch(fetchTopUsdPriceError(firstLetterToUpperCase(e.response?.data?.error || e.response?.statusText)));
  }
};

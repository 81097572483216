import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import TopbarProfile from '../components/topbar/TopbarProfile';
import TopbarSidebarButton from '../components/topbar/TopbarSidebarButton';
import TopbarNotification from '../components/topbar/TopbarNotification';
import {
  TopbarContainer,
  TopbarLeft,
  TopbarLogo,
  TopbarRight,
  TopbarRightOver,
} from '../components/topbar/BasicTopbarComponents';
import TopbarWallet from '../components/topbar/TopbarWallet';
import Logo from '../../../utils/img/custodiy.png';

const Topbar = ({
  changeMobileSidebarVisibility,
  changeSidebarVisibility,
}) => (
  <TopbarContainer>
    <TopbarLeft>
      <TopbarSidebarButton
        onClickMobile={changeMobileSidebarVisibility}
        onClickDesktop={changeSidebarVisibility}
      />
      <Link to='/'>
      <TopbarLogo src={Logo} />
      </Link>
    </TopbarLeft>
    <TopbarRight>
      
      <TopbarRightOver>
      <TopbarNotification />
        <TopbarProfile />
        <TopbarWallet />
      </TopbarRightOver>
    </TopbarRight>
  </TopbarContainer>
  );

Topbar.propTypes = {
  changeMobileSidebarVisibility: PropTypes.func.isRequired,
  changeSidebarVisibility: PropTypes.func.isRequired,
};

export default Topbar;

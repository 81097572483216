import React from 'react';
import { useTranslation } from 'react-i18next';
import Badge from '@/shared/components/Badge';
import Panel from '@/shared/components/Panel';
import { Table } from '@/shared/components/TableElements';
import moment from 'moment';
const header = [
  { id: 2, title: ' Name' },
  { id: 1, title: 'User #' },
  { id: 3, title: 'Email' },
  { id: 4, title: 'Role' },
  // { id: 4, title: 'Quantity' },
  { id: 5, title: 'Joined On' },
  // { id: 6, title: 'Location' },
  // { id: 7, title: 'Status' },
];

const RecentOrders = ({ RecentUser }) => {
  const { t } = useTranslation('common');
  const Ava = `/img/avat.png`;

  // console.log('RecentUser',RecentUser)
  const OrderStatusColor = {

  }
  return (
    <Panel lg={12} title={t('New Users')}>
      <Table responsive bordered>
        <thead>
          <tr>
            {header.map(item => (
              <th key={item?._id}>{item?.title}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {RecentUser.map(item => (
            <tr key={item?._id}>
              <td>
                <div className="flex flex-row pr-10 items-center gap-3 ">
                  <img className="flex h-[44px]  w-[44px] rounded-3xl" src={item?.image || Ava} />
                  <span>
                    {item?.name}
                  </span>
                </div>
              </td>
              <td>{item?._id}</td>
              <td>{item?.email}
              </td>
              <td>{item?.role}</td>
              <td>  {moment(item?.createdAt).format('DD MM YYYY')}</td>
              <td>
                <Badge bg={
                  item?.order_status === 'pending' ? 'warning' :
                    item?.order_status === 'completed' || item?.order_status === 'shipped' ? 'success' :
                      item?.order_status === 'rejected' || item?.order_status === 'refund' ? 'danger' :
                        'primary'
                }>
                  {item?.order_status}
                </Badge></td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Panel>
  );
};

export default RecentOrders;

/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import MenuIcon from 'mdi-react/MenuIcon';
import MoreVertIcon from 'mdi-react/MoreVertIcon';
import VideoIcon from 'mdi-react/VideoIcon';
import PhoneIcon from 'mdi-react/PhoneIcon';
import StarIcon from 'mdi-react/StarIcon';
import Dropdown from 'react-bootstrap/Dropdown';
import { Ring } from '@uiball/loaders';
import { toast } from 'react-toastify';
import { ContactProps } from '@/shared/prop-types/ChatProps';
import 'react-toastify/dist/ReactToastify.css';
import {
  colorAccent,
  colorAdditional,
  colorBackground,
  colorFieldsBorder,
  colorIcon,
} from '@/utils/palette';
import {
  marginLeft,
  marginRight,
  left,
} from '@/utils/directions';
import { updateTicketStatus } from '../../../../utils/services/user.service';

const ChatTopbar = ({ onClick, contact ,fetchData }) => {
  const [chatData, setChatData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  //console.log('chatData', chatData);
  //console.log('contact TopBar', contact);
  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <span
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </span>
  ));
  const handleCloseClick = async (ticketId, e) => {
    //console.log('ticketId', ticketId);
    const status = e.currentTarget.getAttribute('data-status');
    
    //console.log('status', status);
    
    try {
      const payload = {
        ticket_id: ticketId,
        status, 
      };
    
    
    
      const response = await updateTicketStatus(payload);
      setIsLoading(true);
      //console.log('response updateTicketStatus', response);
    
      if (response) {
        fetchData()
        setIsLoading(false);
    
      toast.success('Ticket Status Updated Successfully');
        //console.log('API call successful');
      } else {
        // Handle failure
        //console.log('API call failed');
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      toast.error("Something went wrong")
      console.error('Error updating ticket status:', error);
    }
  };
  
  const handleReopenClick = async (ticketId, e) => {
    //console.log('ticketId', ticketId);
  
    const status = e.currentTarget.getAttribute('data-status');
    //console.log('status', status);
    
  
    setIsLoading(true);
    try {
      const payload = {
        ticket_id: ticketId,
        status, 
      };
    
  
      
      const response = await updateTicketStatus(payload);
  
      //console.log('response updateTicketStatus', response);
      if (response) {
        fetchData()
        setIsLoading(false);
      toast.success('Ticket Status Updated Successfully');
      } else {
        // Handle failure
      }
    } catch (error) {
      // Handle any errors that occurred during the API call
      console.error('Error updating ticket status:', error);
    }
  };
  
  return (
    <>
      <div
        className="loader-overlay"
        style={{
    display: isLoading ? 'flex' : 'none', 
    justifyContent: 'center', 
    alignItems: 'center', 
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    // background: 'rgba(255, 255, 255, 0.8)',
    zIndex: 999,
  }}
      >
        <Ring size={65} color="#00897B" />
      </div>
    
      <ChatTopbarWrap>
        <ChatTopbarButtonMenu type="button" onClick={onClick}>
          <MenuIcon />
        </ChatTopbarButtonMenu>
        {contact && contact.user_detail && contact.user_detail.length > 0 && (
        <>
          {contact.user_detail.map((user, index) => (
      // eslint-disable-next-line react/no-array-index-key
            <span className="d-flex">
              <span>
                <ChatTopbarContact key={index}>                  
                  <ChatTopBbarContactName style={{ textTransform: 'capitalize', fontSize: 'medium' }}>{user.name}</ChatTopBbarContactName>
                  <ChatTopBbarContactName className="mb-2" style={{ fontSize: 'small', fontWeight: '300' }}>{user.email}</ChatTopBbarContactName>
                  {/* <ChatTopbarContactPost>{user.email}</ChatTopbarContactPost> */}
                </ChatTopbarContact>
              </span>
              <span>
                <ChatTopbarContact key={index} style={{ textTransform: 'capitalize' }}>
                  {/* <ChatTopbarContactPost>{user.email}</ChatTopbarContactPost> */}
                  <span className="d-flex">
                    <ChatTopbarContactPost style={{ fontWeight: '500', color: 'white' }} className="mt-0 mb-0">Ticket No</ChatTopbarContactPost>
                    <ChatTopbarContactPost  style={{ marginLeft: 'auto' }}>
                      <span className={`badge ${contact.status === 'open' ? 'badge-info' : contact.status === 'close' ? 'badge-danger' : 'badge-success'} px-2 py-1`}>
                        {contact.status}
                      </span>
                    </ChatTopbarContactPost>
                  </span>
                  <ChatTopbarContactPost className="mb-2">{contact._id}</ChatTopbarContactPost>
                </ChatTopbarContact>
              </span>
            </span>
    ))}
        </>
)}
        <ChatTopbarRight>
          <Dropdown>
            <Dropdown.Toggle as={CustomToggle} id="dropdown-basic">
              <MoreVertIcon style={{ color: 'white' }} />
            </Dropdown.Toggle>

            {contact ? (
              <Dropdown.Menu className="py-1">
                {contact.status === 'open' ? (
                  <Dropdown.Item
                    className="hover:bg-gray-200 hover:text-gray-800"
                    data-status="completed"
                    onClick={e => handleCloseClick(contact._id, e)}
                  >
                    close
                  </Dropdown.Item>
        ) : (
          <Dropdown.Item
            className="hover:bg-gray-200 hover:text-gray-800"
            data-status="open"
            onClick={e => handleReopenClick(contact._id, e)} 
          >
            re-open
          </Dropdown.Item>
        )}
              </Dropdown.Menu>
    ) : (
      <Dropdown.Menu className="py-1">
        {/* If contact is null or has no data, you can render some default content */}
        <Dropdown.Item disabled>No options available</Dropdown.Item>
      </Dropdown.Menu>
    )}


          </Dropdown>
        </ChatTopbarRight>
      </ChatTopbarWrap>
    </>
  );
};

export default ChatTopbar;


ChatTopbar.propTypes = {
  onClick: PropTypes.func.isRequired,
  contact: ContactProps,
};

ChatTopbar.defaultProps = {
  contact: null,
};



// region STYLES

const ChatTopbarWrap = styled.div`
  display: flex;
  height: 65px;
  align-items: center;
  border-bottom: 1px solid ${colorFieldsBorder};
  background-color: ${colorBackground};
`;

const ChatTopbarButton = styled.button`
  background-color: transparent;
  border: none;
  transition: all 0.3s;
  cursor: pointer;
  width: 50px;
  height: 50px;
  
  svg {
    height: 18px;
    width: 18px;
    transition: 0.3s;
    fill: ${colorIcon};
  }

  &:hover svg {
    fill: ${colorAccent};
  }
`;

const ChatTopbarButtonMenu = styled(ChatTopbarButton)`
  ${marginRight}: -20px;

  @media screen and (min-width: 1024px) {
    display: none;
  }
`;

const ChatTopbarControls = styled.div`
  display: none;

  @media screen and (min-width: 698px) {
    display: flex;
  }
`;

const ChatTopbarRight = styled.div`
  ${marginLeft}: auto;
  display: flex;
`;

const ChatTopbarContact = styled.div`
  text-align: ${left};
  ${marginLeft}: 20px;
`;

const ChatTopBbarContactName = styled.p`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.33;
`;

const ChatTopbarContactPost = styled.p`
  margin: 0;
  paddig: 10px;
  color: ${colorAdditional};
  font-size: 14px;
  line-height: 1.45
`;

// endregion

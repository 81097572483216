import Modal from "react-bootstrap/Modal";
import { CustomSelect } from "../../../shared/components/CustomSelect";
import styled from "styled-components";
import { useState } from "react";
import {

    createRefferenceCodes,
    deleteOtcMarketPlacePackage,
} from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import { toast } from "react-toastify";
import { useChainContext } from "../../context/context";
import { Button } from "@/shared/components/Button";


const AddReferral = ({ show, hide, reFetch, marketPlace_id }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [referralCode, setReferralCode] = useState("");

    const AddNewReferral = async () => {
        try {
            setIsLoading(true);
            const payload = {
                "marketPlaceId": marketPlace_id,
                "referralCode": referralCode
            }
            const res = await createRefferenceCodes(payload);
            toast.success(res?.data?.message)
            console.log("🚀 ~ handleDeleteMarketplacePackage ~ res:", res)
            // toast.success(res.data.Message);
            setIsLoading(false);
            // reFetchPackages();
            reFetch()
            hide();
        } catch (error) {
            toast.error(error?.response?.data.message);
            setIsLoading(false);
        }
    };
    return (
        <>
            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={show}
                onHide={hide}
            >
                <div
                    className="loader-overlay"
                    style={{
                        display: isLoading ? "flex" : "none",
                        justifyContent: "center",
                        alignItems: "center",
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        background: "rgba(255, 255, 255, 0.8)",
                        zIndex: 999,
                    }}
                >
                    <Ring size={65} color="#00897B" />
                </div>
                <Modal.Header closeButton>
                    <Modal.Title className="text-center" style={{ fontSize: "large" }}>
                        <b>Add Referral </b>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex flex-col gap-4 ">


                        <div className="flex flex-col col-12 col-md-6 col-lg-6">
                            <label>Referral Code</label>
                            <InputDiv>
                                <ChatSearchInput
                                    type="text"
                                    value={referralCode}
                                    className="form-control "
                                    placeholder="Enter Referral Code"
                                    onChange={(e) => setReferralCode(e.target.value)}
                                />
                            </InputDiv>
                        </div>
                        <div className=" flex w-full gap-4 justify-center items-center">
                            <FormButton
                                type="submit"
                                variant="contain-primary"
                                // className="py-2 mt-2 col-12 "
                                onClick={AddNewReferral}
                            >
                                Add Referral
                            </FormButton>

                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};
export default AddReferral;
const ChatSearchInput = styled.input`
  width: 100%;
  height: 100%;
  font-size: 12px;
  transition: all 0.3s;
  background: transparent;
  border: none;
  padding-left: 2px;
  border-bottom: 1px solid #b1b1b1;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
  color: #b1b1b1;

  &:focus,
  &:active {
    outline: none;
    box-shadow: none;
    color: #b1b1b1;
    background: transparent;
    border-bottom: 2px solid #47c9a4;
  }

  &::-webkit-input-placeholder {
    color: #b1b1b1;
  }
  &::-moz-placeholder {
    color: #b1b1b1;
  }
  &:-moz-placeholder {
    color: #b1b1b1;
  }
  &:-ms-input-placeholder {
    color: #b1b1b1;
  }
`;
const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
`;
const FormButton = styled(Button)`
  color: "white";
  mergin-top: 1rem;
`;
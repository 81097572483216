import React, { useEffect, useState } from "react";
import TitleCard from "../../AddSafebox/TitleCard";
import { Ring } from "@uiball/loaders";
import {
  getUserInfo1,
  getRequest,
  createAccount,
  updateSubAccountRequest,
  stopSubAccountRequest,
} from "../../../../utils/services/user.service";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button } from "@/shared/components/Button";
import "../table.css";
import CreateSubAccount from "./CreateSubAccount";
import styled from "styled-components";
import DeleteSubAccount from "./DeleteSubAccount";
import UpdateSubAccountModal from "./UpdateSubAccountModal";
import ReactTableBase from "@/shared/components/table/ReactTableBase";
import { CustomSelect } from "../../../../shared/components/CustomSelect";

const UserAllAccounts = () => {
  const itemsPerPage = 20;
  const [myRequestData, setMyRequestData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);

  const [TFA, setTFA] = useState(false);
  const userData = JSON.parse(sessionStorage.getItem("user"));
  const usermail = userData?.data?.user?.email;
  const [isLoading, setIsLoading] = useState(false);
  const [selectedoptionorder, setoptionSelectorder] = useState("all");
  const [selectedOption, setSelectedOption] = useState("");
  const [show, setShow] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);

  const filteredRequestData =
    selectedoptionorder === "all"
      ? myRequestData
      : myRequestData.filter((store) => store.status == selectedoptionorder);

  useEffect(() => {
    const fetchData = async () => {
      const requestData = {
        email: usermail,
      };
      try {
        const response = await getUserInfo1(requestData);

        if (response.status === 200) {
          setTFA(response.data.User.TFA);
        } else {
          console.error("getUserInfo1 failed:", response);
        }
      } catch (error) {
        console.error("Error getUserInfo1:", error);
      }
    };
    fetchData();
  }, [usermail, TFA]);

  const fetchAllAccountsRequests = async (selectedOption) => {
    try {
      setIsLoading(true);
      const payload = {
        limit: 10000,
        page_number: 1,
        status: selectedOption || "all",
      };
      const response = await getRequest(payload);
      if (response) {
        setMyRequestData(response.data.data);
        const totalUsers = response.data.count;
        const pages = Math.ceil(totalUsers / itemsPerPage);
        setPageCount(pages);
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Error fetching store data:");
      console.error("Error fetching store data:", error);
    }
  };
  useEffect(() => {
    fetchAllAccountsRequests();
  }, [currentPage]);
  const handleApiChange2 = async (selectedOption) => {
    setoptionSelectorder(selectedOption);
    await fetchAllAccountsRequests(selectedOption);
  };

  const customPaddingStyle = {
    padding: " 6px 4px", // Top, Right, Bottom, Left
    background: "transparent",
    border: "1px solid",
  };

  const handleCreateAccount = async (item) => {

    try {
      setIsLoading(true);
      const payload = {
        email: item.user_details[0].email,
        name: item?.name,
        request_id: item?._id,
      };

      const res = await createAccount(payload);

      toast.success(res.data.message);
      await fetchAllAccountsRequests();
    } catch (error) {
      console.error("An error occurred:", error);
      toast.error(error?.response?.data.message);
    } finally {
      setIsLoading(false); // For example, stopping the loading indicator
    }
  };
  const getStatusColor = (status1) => {
    switch (status1.toLowerCase()) {
      case "completed":
        return "#4ce1b6";
      case "pending":
        return "#f6da6e";
      case "rejected":
        return "#ff4861";
      case "waiting":
        return "#f6da6e";
      case "declined":
        return "#ff4861";
      case "failed":
        return "#ff4861";
      case "success":
        return "green";
      default:
        return "#70bbfd";
    }
  };
  const header = [
    { accessor: "request_id", Header: "Request Id" },
    { accessor: "account_time", Header: "Account Time" },
    // { accessor: "account_time_type", Header: "Time Type" },
    { accessor: "number_of_accounts", Header: " Number of Account" },
    { accessor: "number_of_tokens", Header: " Number of Tokens" },
    { accessor: "symbol", Header: "Symbol" },
    { accessor: "type", Header: "Type" },
    { accessor: "status", Header: "Request Status" },
    { accessor: "action", Header: "Action" },
  ];
  const tableConfi2 = {
    isEditable: false,
    isSortable: false,
    isResizable: false,
    isPagination: true,
    withSearchEngine: false,
    manualPageSize: [10, 20, 30, 40],
    placeholder: "Search...",
    withPagination: true,
  };
  const handleAction = async (item, val) => {
    setIsLoading(true);
    setSelectedOption(val?.toLowerCase());

    try {
      const payload = { request_id: item._id, status: val };
      const res = await updateSubAccountRequest(payload);
      await fetchAllAccountsRequests();
      setIsLoading(false);
      toast.success(res.data.Message);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const handleStop = async (item) => {
    setIsLoading(true);
    try {
      const payload = { request_id: item._id };
      const res = await stopSubAccountRequest(payload);
      await fetchAllAccountsRequests();
      setIsLoading(false);
      toast.success(res.data.Message);
    } catch (error) {
      setIsLoading(false);
    }
  };
  const data = [];
  filteredRequestData.map((item) =>
    data.push({
      request_id: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item._id}
        </h6>
      ),

      type: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.type || "--"}
        </h6>
      ),
      account_time: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.account_time || "--"} {item.account_time_type || "--"}
        </h6>
      ),
      // account_time_type: (
      //   <h6
      //     className="text-capitalize"
      //     style={{ color: "#999999", fontSize: "inherit" }}
      //   >
      //     {item.account_time_type || "--"}
      //   </h6>
      // ),
      number_of_accounts: (
        <h6
          className="text-capitalize text-nowrap flex flex-nowrap"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.number_of_accounts || "--"}
        </h6>
      ),
      number_of_tokens: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.number_of_tokens || "--"}
        </h6>
      ),
      symbol: (
        <h6
          className="text-capitalize"
          style={{ color: "#999999", fontSize: "inherit" }}
        >
          {item.symbol || "--"}
        </h6>
      ),

      status: (
        <span
          className="badge px-2 py-1 text-capitalize"
          style={{ backgroundColor: getStatusColor(item.status) }}
        >
          <h6 className="text-white">
            <b>{item.status}</b>
          </h6>
        </span>
      ),
      action: (
        <div className="flex flex-row items-center justify-center">
          <select
            onChange={(e) => handleAction(item, e.target.value)}
            className="rounded-sm mr-2"
            style={customPaddingStyle}
            id="filters"
            value={item.status} // Set the value of the select element to item.status
          >
            {[
              "approved",
              "rejected",
              "pending",
              "completed",
              "waiting_distribution",
            ].map((status) => (
              <option
                key={status}
                value={status}
                disabled={
                  status === "pending" ||
                  status === "completed" ||
                  status === "waiting_distribution"
                }
              >
                {status}
              </option>
            ))}
          </select>
          <FormButton
            variant="outline-primary"
            disabled={item.status != "approved"}
            className="flex py-2 mt-3"
            onClick={() => handleStop(item)}
          >
            Stop
          </FormButton>
        </div>
      ),
    })
  );

  return (
    <>
      <div
        className="loader-overlay"
        style={{
          display: isLoading ? "flex" : "none", // Use flex display when loading
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          background: "rgba(255, 255, 255, 0.8)",
          zIndex: 999,
        }}
      >
        <Ring size={65} color="#00897B" />
      </div>
      <TitleCard title="" topMargin="mt-2">
        <h3 className="mb-4"> Sub account requests</h3>
        <div className="lg:flex lg:space-x-4  ">
          <div className="flex flex-col lg:w-1/4 mb-4 lg:mb-0">
            <CustomSelect
              className="flex w-full text-white rounded-sm mr-2"
              // value={selectedoptionorder}
              placeholder={selectedoptionorder || "Select Status"}
              onChange={(value) => handleApiChange2(value.value)}
              options={[
                { label: "All", value: "all" },
                { label: "Approved", value: "approved" },
                { label: "Pending", value: "pending" },
                { label: "Reject", value: "reject" },
                { label: "Completed", value: "completed" },
                {
                  label: "Waiting Distribution",
                  value: "waiting_distribution",
                },
              ]}
            />
          </div>
        </div>
        <div className="overflow-x-auto w-full">
          <ReactTableBase
            columns={header}
            data={data}
            tableConfig={tableConfi2}
          />
        </div>
        {show && <CreateSubAccount show={show} hide={() => setShow(false)} />}
        {showDeleteModal && (
          <DeleteSubAccount
            show={showDeleteModal}
            hide={() => setShowDeleteModal(false)}
          />
        )}
        {showUpdateModal && (
          <UpdateSubAccountModal
            show={showUpdateModal}
            hide={() => setShowUpdateModal(false)}
          />
        )}
      </TitleCard>
    </>
  );
};

export default UserAllAccounts;
const FormButton = styled(Button)`
  color: "white";
`;

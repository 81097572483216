import { Modal } from "react-bootstrap";
import {
  faEye,
  faEnvelope,
  faUser,
  faMap,
  faFile,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

import styled from "styled-components";
import moment from "moment";

const AllTransectionDetailModal = ({ show, hide, detailData }) => {
  console.log("🚀 ~ AllTransectionDetailModal ~ detailData:", detailData);
  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  const getStatusColor = (status1) => {
    switch (status1.toLowerCase()) {
      case "completed":
        return "#4ce1b6";
      case "pending":
        return "#f6da6e";
      case "rejected":
        return "#ff4861";
      case "waiting":
        return "#f6da6e";
      case "declined":
        return "#ff4861";
      case "failed":
        return "#ff4861";
      default:
        return "#70bbfd";
    }
  };

  return (
    <>
      <Modal
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={show}
        onHide={hide}
      >
        <Modal.Header closeButton>
          {detailData && (
            <Modal.Title className="text-center" style={{ fontSize: "large" }}>
              Transection Detail:{" "}
              <span style={{ fontWeight: 400 }}> {detailData._id}</span>
            </Modal.Title>
          )}
        </Modal.Header>
        <Modal.Body>
          {detailData && (
            <>
              {/* admin detail section  */}
              <div
                className="container mt-0 containerDiv pt-0"
                style={{ overflowX: "hidden" }}
              >
                <div className="row mt-2 mb-1">
                  <p
                    style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                    className="py-0"
                  >
                    <b>Fee Detail</b>
                  </p>
                </div>
                <div
                  className="row mt-0 mb-2 mx-1"
                  style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                >
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b>Admin Transfer Fee</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      {detailData?.admin_transfer_fee}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b> Buy Fee</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      {detailData?.buy_fee}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b> Buy Fee Type</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      {detailData?.buy_fee_type}
                    </p>
                  </div>
                  {detailData.admin_transfer_hash && (
                    <>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <b>Admin Transfer Hash</b>
                        </p>
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          {detailData.admin_transfer_hash}{" "}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <b> View Admin Transfer Hash</b>
                        </p>
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <HashLink
                            role="link"
                            onClick={() => {
                              let baseUrl;
                              switch (detailData?.chain_id) {
                                case 1:
                                  baseUrl = "https://etherscan.io/tx/";
                                  break;
                                case 137:
                                  baseUrl = "https://polygonscan.com/tx/";
                                  break;
                                case 56:
                                default:
                                  baseUrl = "https://bscscan.com/tx/";
                                  break;
                              }
                              openInNewTab(
                                `${baseUrl}${detailData?.admin_transfer_hash}`
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </HashLink>
                        </p>
                      </div>
                    </>
                  )}
                </div>

                <hr
                  hidden
                  style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                />
              </div>
              {/* Buy Detail section  */}
              <div
                className="container mt-0 containerDiv pt-0"
                style={{ overflowX: "hidden" }}
              >
                <div className="row mt-2 mb-1">
                  <p
                    style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                    className="py-0"
                  >
                    <b>Buy Detail</b>
                  </p>
                </div>
                <div
                  className="row mt-0 mb-2 mx-1"
                  style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                >
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b> Buy Amount</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      {detailData?.buy_amount}
                    </p>
                  </div>
                 
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b> Buy Status</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      {detailData?.buy_status}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b> Buy Hash</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      {detailData?.buy_hash
                        ? `${detailData?.buy_hash.slice(
                            0,
                            8
                          )}...${detailData?.buy_hash.slice(-4)}`
                        : ""}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b> View Buy Hash</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <HashLink
                        role="link"
                      
                        onClick={() => {
                            let baseUrl;
                            switch (detailData?.chain_id) {
                              case 1:
                                baseUrl = "https://etherscan.io/tx/";
                                break;
                              case 137:
                                baseUrl = "https://polygonscan.com/tx/";
                                break;
                              case 56:
                              default:
                                baseUrl = "https://bscscan.com/tx/";
                                break;
                            }
                            openInNewTab(
                              `${baseUrl}${detailData?.buy_hash}`
                            );
                          }}
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      </HashLink>
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b> Buy Tax</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      {detailData?.buy_tax}
                    </p>
                  </div>
                </div>

                <hr
                  hidden
                  style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                />
              </div>
              {/* Transfer Detail Section  */}
              <div
                className="container mt-0 containerDiv pt-0"
                style={{ overflowX: "hidden" }}
              >
                <div className="row mt-2 mb-1">
                  <p
                    style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                    className="py-0"
                  >
                    <b>Transfer Detail</b>
                  </p>
                </div>
                <div
                  className="row mt-0 mb-2 mx-1"
                  style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                >
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b> Token Hash</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      {detailData?.token_hash
                        ? `${detailData?.token_hash.slice(
                            0,
                            8
                          )}...${detailData?.token_hash.slice(-4)}`
                        : ""}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b> View Token Hash</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <HashLink
                        role="link"
                        onClick={() =>
                          openInNewTab(
                            `https://bscscan.com/tx/${detailData?.token_hash}`
                          )
                        }
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      </HashLink>
                    </p>
                  </div>
                  {detailData.transfer_hash_external_wallet && (
                    <>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <b> Token Hash External Wallet</b>
                        </p>
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          {detailData?.transfer_hash_external_wallet
                            ? `${detailData?.transfer_hash_external_wallet.slice(
                                0,
                                8
                              )}...${detailData?.transfer_hash_external_wallet.slice(
                                -4
                              )}`
                            : ""}
                        </p>
                      </div>
                      <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <b> View Transfer Hash External</b>
                        </p>
                        <p
                          style={{
                            textTransform: "Capitalize",
                            color: "#7b7b7b",
                          }}
                          className="py-1"
                        >
                          <HashLink
                            role="link"
                           
                            onClick={() => {
                                let baseUrl;
                                switch (detailData?.chain_id) {
                                  case 1:
                                    baseUrl = "https://etherscan.io/tx/";
                                    break;
                                  case 137:
                                    baseUrl = "https://polygonscan.com/tx/";
                                    break;
                                  case 56:
                                  default:
                                    baseUrl = "https://bscscan.com/tx/";
                                    break;
                                }
                                openInNewTab(
                                  `${baseUrl}${detailData?.transfer_hash_external_wallet}`
                                );
                              }}
                          >
                            <FontAwesomeIcon icon={faExternalLinkAlt} />
                          </HashLink>
                        </p>
                      </div>
                    </>
                  )}
                </div>

                <hr
                  hidden
                  style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                />
              </div>
              {/* Other details section  */}
              <div
                className="container mt-0 containerDiv pt-0"
                style={{ overflowX: "hidden" }}
              >
                <div className="row mt-2 mb-1">
                  <p
                    style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                    className="py-0"
                  >
                    <b>Other All Details</b>
                  </p>
                </div>
                <div
                  className="row mt-0 mb-2 mx-1"
                  style={{ border: "2px solid #7b7b7b", borderRadius: "5px" }}
                >
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b> Marketplace Id</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      {detailData?.marketplace_id}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b> Package Id</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      {detailData?.package_id}
                    </p>
                  </div>
                  <div className="col-lg-3 col-md-3 col-sm-6 text-center">
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      <b>Trx ID</b>
                    </p>
                    <p
                      style={{ textTransform: "Capitalize", color: "#7b7b7b" }}
                      className="py-1"
                    >
                      {detailData._id}
                    </p>
                  </div>
                </div>

                <hr
                  hidden
                  style={{ border: "1px solid #b3b3b3", opacity: "1.1" }}
                />
              </div>
            </>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};
export default AllTransectionDetailModal;
const HashLink = styled.button`
  border: none;
  background: transparent;
  color: #4ce1b6;
`;

import { Col } from "react-bootstrap";
import { Card } from "@/shared/components/Card";
import {
    DashboardBookingCard,
    DashboardBookingDescription,
    DashboardBookingTitle,
    DashboardBookingTotalWrap,
} from "./BookingCardDashboardElements";
import { toast } from "react-toastify";
import { Button } from "@/shared/components/Button";
import styled from "styled-components";
import { useEffect, useState } from "react";
import ConfirmDeleteReferral from "./ConfirmDeleteReferral";
import { getRefferenceCodes, updateRefferenceCode } from "../../../utils/services/user.service";
import { Ring } from "@uiball/loaders";
import AddReferral from "./AddReferral";

const AllReferralCodes = ({ marketPlace_id }) => {
    const [show, setShow] = useState(false);
    const [showAddReferralCode, setShowAddReferralCode] = useState(false);
    const [referralCodes, setReferralCodes] = useState([]);
    const [reFetch, setRefetch] = useState(false);
    const [editIndex, setEditIndex] = useState(null); // Track the index of the card being edited
    const [isLoading, setIsLoading] = useState(false);
    const [code, setCode] = useState("");
    const [newReferralCode, setNewReferralCode] = useState("");

    const onCopyWalletAddress = (item) => {
        navigator.clipboard.writeText(item);
        toast.info('Referral code Copied');
    };

    const getAllReferrals = async () => {
        setIsLoading(true);
        try {
            const payload = { marketPlaceId: marketPlace_id };
            const res = await getRefferenceCodes(payload);
            setReferralCodes(res?.data?.response || []);
        } catch (error) {
            console.error("Error fetching referrals:", error);
        } finally {
            setIsLoading(false);
        }
    };

    const editReferralCode = async () => {
        setIsLoading(true);
        try {
            const payload = {
                marketPlaceId: marketPlace_id,
                oldReferralCode: code,
                referralCode: newReferralCode,
            };
            const res = await updateRefferenceCode(payload);
            setEditIndex(null); // Reset edit index after updating
            setRefetch(!reFetch); // Trigger re-fetch to get the updated referral codes
            setCode("")
            toast.success(res?.data?.message)
        } catch (error) {
            toast.error(error?.response?.data.message);
            console.error("Error updating referral code:", error);
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        getAllReferrals();
    }, [marketPlace_id, reFetch]);

    return (
        <>
            {isLoading && (
                <LoaderOverlay>
                    <Ring size={65} color="#00897B" />
                </LoaderOverlay>
            )}
            <div className="d-flex flex-column flex-md-row gap-1">
                {referralCodes.length > 0 && <FormButton
                    variant="contain-danger"
                    className="py-2 mt-2 col-12 ml-3 col-md-3"
                    onClick={() => {
                        setShow(true);
                        setCode('all');
                    }}                >
                    Delete All Referrals
                </FormButton>}
                <FormButton
                    variant="contain-primary"
                    className="py-2 mt-2 col-12 ml-3 col-md-3"
                    onClick={() => setShowAddReferralCode(true)}
                >
                    Add Referrals
                </FormButton>
            </div>
            <div className="d-flex self-center gap-2 flex-wrap">
                {referralCodes.map((item, index) => (
                    <Col key={index} md={3}>
                        <DashboardBookingCard>
                            <DashboardBookingTotalWrap>
                                <div className="d-flex justify-between w-100 text-white">
                                    {item}
                                    <div className="d-flex gap-3">
                                        <i
                                            className="fa fa-trash text-danger text-lg cursor-pointer"
                                            onClick={() => {
                                                setShow(true);
                                                setCode(item);
                                            }}
                                            aria-hidden="true"
                                        ></i>
                                        {editIndex !== index ? (
                                            <i
                                                className="fa fa-pencil text-info text-lg cursor-pointer"
                                                onClick={() => {
                                                    setEditIndex(index);
                                                    setCode(item);
                                                }}
                                                aria-hidden="true"
                                            ></i>
                                        ) : (
                                            <div className="d-flex gap-3">
                                                <i
                                                    className="fa fa-check text-lg text-success cursor-pointer"
                                                    onClick={editReferralCode}
                                                    aria-hidden="true"
                                                ></i>
                                                <i
                                                    className="fa fa-times text-danger text-lg cursor-pointer"
                                                    onClick={() => setEditIndex(null)}
                                                    aria-hidden="true"
                                                ></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </DashboardBookingTotalWrap>
                            <DashboardBookingDescription>
                                {editIndex === index ? (
                                    <InputDiv>
                                        <ChatSearchInput
                                            type="text"
                                            value={newReferralCode}
                                            className="form-control"
                                            placeholder="Enter new referral code"
                                            onChange={(e) => setNewReferralCode(e.target.value)}
                                        />
                                    </InputDiv>
                                ) : (
                                    <>
                                        {item}
                                        <i
                                            className="fa fa-clone ml-2 cursor-pointer"
                                            aria-hidden="true"
                                            onClick={() => onCopyWalletAddress(item)}
                                        ></i>
                                    </>
                                )}
                            </DashboardBookingDescription>
                        </DashboardBookingCard>
                    </Col>
                ))}
            </div>
            {show && (
                <ConfirmDeleteReferral
                    show={show}
                    hide={() => setShow(false)}
                    reFetch={() => setRefetch(!reFetch)}
                    marketPlace_id={marketPlace_id}
                    code={code}
                />
            )}
            {showAddReferralCode && (
                <AddReferral
                    show={showAddReferralCode}
                    hide={() => setShowAddReferralCode(false)}
                    marketPlace_id={marketPlace_id}
                    reFetch={() => setRefetch(!reFetch)}
                />
            )}
        </>
    );
};

export default AllReferralCodes;

const LoaderOverlay = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.8);
    z-index: 999;
`;

const FormButton = styled(Button)`
    color: white;
    margin-top: 1rem;
`;

const ChatSearchInput = styled.input`
    width: 100%;
    height: 100%;
    font-size: 12px;
    transition: all 0.3s;
    background: transparent;
    border: none;
    padding-left: 2px;
    border-bottom: 1px solid #b1b1b1;
    color: #b1b1b1;

    &:focus,
    &:active {
        outline: none;
        box-shadow: none;
        color: #b1b1b1;
        background: transparent;
        border-bottom: 2px solid #47c9a4;
    }

    &::placeholder {
        color: #b1b1b1;
    }
`;

const InputDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    color: white;
    padding: 5px 0;
    font-size: 14px;
`;

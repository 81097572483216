import React, { useState } from 'react';
import { Formik } from 'formik';
import { Button } from '../../../../shared/components/Button';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { Alert } from 'react-bootstrap';
import SimpleModal from '../../../../shared/components/Modal/SimpleModal';
import { changePassword, clearMsg } from '../../../../redux/actions/authActions';


const NewPasswordForm = ({ setModal }) => {
  const [showPassword, setShowPassword] = useState(true);
  const [showPassword1, setShowPassword1] = useState(true);
  const [isOpen, setisOpen] = useState(false);
  const { message } = useSelector(state => state.message);
  const { statusCode } = useSelector(state => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const ChangePassword = (user) => {
    setIsLoading(true);
    dispatch(changePassword(user.oldPass, user.newPass)).then(() => {
      setisOpen(true);
      setTimeout(() => {
        dispatch(clearMsg());
        setModal(false);
      }, 3000);
    })
      .catch(() => {
        setIsLoading(false);
        setTimeout(() => {
          dispatch(clearMsg());
        }, 3000);
      });
  };
  return (
    <>
      <Formik
        initialValues={{
          oldPass: '',
          newPass: '',
          email: '',
        }}
        onSubmit={(values) => {
          ChangePassword(values);
        }}
        validate={(values) => {
          const errors = {};
          const passwordRegex = /(?=.*[0-9])/;
          if (!values.oldPass) {
            errors.oldPass = 'Required';
          } else if (values.oldPass.length < 8) {
            errors.oldPass = 'Password must be 8 characters long';
          } else if (!passwordRegex.test(values.oldPass)) {
            errors.oldPass = 'Invalid Password must contain one number';
          }
          if (!values.newPass) {
            errors.newPass = 'Required';
          } else if (values.newPass.length < 8) {
            errors.newPass = 'Password must be 8 characters long';
          } else if (!passwordRegex.test(values.newPass)) {
            errors.newPass = 'Invalid Password must contain one number';
          } else if (values.newPass === values.oldPass) {
            errors.newPass = 'New Password should be different';
          }
          return errors;
        }}
      >
        {(props) => {
          const {
            values,
            touched,
            errors,
            isSubmitting,
            handleChange,
            handleBlur,
            handleSubmit,
          } = props;
          return (
            <form onSubmit={handleSubmit} style={{ marginTop: '1rem' }}>
              <StyledAlert
                variant={statusCode === 200 ? 'success' : 'danger'}
                show={message !== undefined}
                style={{
                  marginTop: '1rem', width: '100%', textAlign: 'center',
                }}
                onClose={() => dispatch(clearMsg())}
                dismissible
                bordered
                icon
              >
                {message}
              </StyledAlert>
            <div className='flex flex-col flex-md-row items-center'>
            <InputDiv>
                {/* <Grid> */}
                  <label htmlFor="email">Old Password</label>
                  <div className='text-black'>
                    <FieldDiv>
                      <IconDiv><span className="lnr lnr-lock text-white" /></IconDiv>
                      <input
                        name="oldPass"
                        type={showPassword ? 'password' : 'text'}
                        placeholder="Enter your old Password"
                        value={values.oldPass}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.oldPass && touched.oldPass && 'error '}
                      />
                    </FieldDiv>
                    <button
                      style={{ fontSize: '11px' }}
                      type="button"
                      className="btn btn-link"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? 'Show' : 'Hide'} Password
                    </button>
                  </div>
                {/* </Grid> */}
                {errors.oldPass && touched.oldPass && (
                  <Error className="input feedback">{errors.oldPass}</Error>
                )}

              </InputDiv>
              <InputDiv>
                {/* <Grid> */}
                  <label htmlFor="email">New Password</label>
                  <div className='text-black' >
                    <FieldDiv>
                      <IconDiv><span className="lnr lnr-lock text-white" /></IconDiv>
                      <input
                        name="newPass"
                        type={showPassword1 ? 'password' : 'text'}
                        placeholder="Enter your New Password"
                        value={values.newPass}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className={errors.newPass && touched.newPass && 'error' }
                      />
                    </FieldDiv>

                    <button
                      style={{ fontSize: '11px' }}
                      type="button"
                      className="btn btn-link"
                      onClick={() => setShowPassword1(!showPassword1)}
                    >
                      {showPassword1 ? 'Show' : 'Hide'} Password

                    </button>
                  </div>

                {/* </Grid> */}
                {errors.newPass && touched.newPass && (
                  <Error className="input feedback">{errors.newPass}</Error>
                )}

              </InputDiv>
            </div>

              <FormButton variant="outline-primary" type="submit">
                {isLoading ? 'Loading....' : 'Update'}
              </FormButton>

            </form>
          );
        }}
      </Formik>
      <SimpleModal isActive={isOpen} onClose={() => setisOpen(false)}>
        <ModalDiv>

          <h4
            className="subhead"
            style={{
              padding: '5px', fontSize: '18px', fontWeight: '700', textAlign: 'center', marginBottom: '1rem', color: 'white',
            }}
          >{message}
          </h4>

        </ModalDiv>
      </SimpleModal>
    </>
  );
};

export default NewPasswordForm;

const InputDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  color: white;
  padding: 5px 0px;
  font-size: 14px;
  align-items: center;

 
`;

const Error = styled.div`
  color: #e66e6e;
  padding: 2px 0px;
  font-size: 12px;
`;

const FormButton = styled(Button)`
 color:"white";
 margin-top: 1rem;
 `;
const Grid = styled.div`
 display: grid;
 grid-template-columns: 6rem 12rem;
 grid-gap: 1rem;
 width:100%;
 margin-bottom: 1rem;
 align-items: baseline;

 @media screen and (max-width: 876px) {
 grid-template: none;
 }
 `;
const IconDiv = styled.div`
padding: 5px;
background: #3b3b4f;
border: 1px solid #3b3b4f;
`;
const FieldDiv = styled.div`
display:flex;
`;
const ModalDiv = styled.div`
height: 9rem;
justify-content: space-between;
display: flex;
align-items: center;
flex-direction: column;
`;
const getMainColor = (color) => {
  switch (color) {
    case 'success':
      return '#c1f9c1';
    case 'danger':
      return '#f1beb0';
    default:
      return '#f1beb0';
  }
};

const getLightColor = (color) => {
  switch (color) {
    case 'success':
      return '#22bb33';
    case 'danger':
      return '#ff4861';
    default:
      return '#f88e7d';
  }
};

const StyledAlert = styled(Alert).withConfig({
  shouldForwardProp: prop => !['bordered', 'neutral', 'colored'].includes(prop),
})`
  
  background-color: ${props => (getMainColor(props.variant))};
  border-color: ${props => (getMainColor(props.variant))};
  color: ${props => (getLightColor(props.variant))};
 
`;
